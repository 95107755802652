$ns: "c-top-promo-banner";

.#{$ns} {
    color: $body-color;
    font-size: 15px;
    height: 36px;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;

    a {
        @include bb-link($body-color);
        &, &:hover {
            color: inherit;
        }
    }

    strong, b {
        font-weight: 600;
    }

    p {
        margin: 0;
    }


    &__spacer {
        background-color: $body-color;
        display: inline-block;
        height: .85em;
        margin: 0 .35em;
        vertical-align: middle;
        width: 1px;
    }

    &__theme {
        &--mint {
            background-color: $mint;
        }

        &--peach {
            background-color: $peach;
        }

        &--plum {
            background-color: $plum;
            color: #fff;

            .#{$ns}__spacer {
                background-color: #fff;
            }

            a {
                @include bb-link(#fff);
            }

        }

    }

    &__wrapper {
        @include make-container-max-widths();
        margin: 0 auto;
        padding: 0.375rem map-get($grid-gutter-widths, xs);
    }

    .icon-svg {
        @include size(24px);

        &--shipping-truck {
            @include size(32px);
            margin-top: -10px;
            margin-right: 0.2em;
            margin-bottom: -10px;
        }

    }
    > :last-child {
        margin-bottom: 0;
    }

}
.full-width__top-promo-wrap {
    margin: 0 auto;

    @include media-breakpoint-up(lg) {
        padding: 0 map-get($grid-gutter-widths, lg);
    }
}
