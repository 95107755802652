// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

// Override to allow custom border colors
@mixin button-outline-variant($color, $color-hover: color-yiq($color), $active-background: $color, $active-border: $color, $border-color: $color) {
    color: $color;
    background-color: transparent;
    background-image: none;
    border-color: $border-color;

    &:hover {
      color: $color-hover;
      background-color: $active-background;
      border-color: $active-border;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
    }

    &.disabled,
    &:disabled {
      color: $color;
      background-color: transparent;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      color: color-yiq($active-background);
      background-color: $active-background;
      border-color: $active-border;

      &:focus {
        // Avoid using mixin so we can pass custom focus shadow properly
        @if $enable-shadows and $btn-active-box-shadow != none {
          box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($color, .5);
        } @else {
          box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
        }
      }
    }
  }
