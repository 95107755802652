/* ============================================================================
   UGC Gallery
   ============================================================================ */

$ns: c-ugc-gallery;

.#{$ns} {
    margin-top: 105px;

    &__block {
        position: relative;

        &::before,
        &::after {
            background-color: rgba(255, 255, 255, .8);
            content: "";
            display: block;
            height: 100%;
            position: absolute;
                z-index: 10;
                top: 0;
            width: 76px;

            @include media-breakpoint-up(sm) {
                width: 98px;
            }

            @include media-breakpoint-up(md) {
                width: 120px;
            }
        }

        &::before {
            left: 0;
        }

        &::after {
            right: 0;
        }
    }

    &__header {
        margin-bottom: 33px;
        text-align: center;

        &-link {
            color: black;
            font-size: 27px;
            font-weight: 600;

            &:hover {
                color: black;
                text-decoration: none;
            }
        }

    }

    &__icon {
        height: 32px;
        margin-right: 4px;
        position: relative;
            top: -3px;
        width: 32px;
    }

    &__slider-item {
        position: relative;

        &:hover {
            .#{$ns}__button-wrapper {
                display: block;
            }
        }
    }

    &__button-wrapper {
        display: none;
        position: absolute;
            z-index: 1000;
            top: 0;
            left: 0;
        height: 100%;
        width: 100%;
    }

    &__button {
        display: flex;
            align-items: center;
            justify-content: center;
        font-size: 14px;
        font-weight: 600;
        height: 48px;
        letter-spacing: 1.5px;
        position: absolute;
            left: 50%;
            bottom: 15px;
        pointer-events: none;
        text-transform: uppercase;
        transform: translateX(-50%);
        width: calc(100% - 30px);
    }

    &__image {
        display: block;
    }

}
