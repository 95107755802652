$mini-cart-padding: 17px 40px 23px 40px;
$mini-cart-padding--top:    nth($mini-cart-padding, 1);
$mini-cart-padding--right:  nth($mini-cart-padding, 2);
$mini-cart-padding--bottom: nth($mini-cart-padding, 3);
$mini-cart-padding--left:   nth($mini-cart-padding, 4);

#mini-cart {
    position: relative;
    z-index: $zindex-minicart;


    @include media-breakpoint-up(lg) {
        margin-left: 17px;
    }

    .mini-cart-link-cart, .checkout-btn  {
        display: block;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 2px;
        line-height: 1.3;
        padding-bottom: 10px;
        padding-top: 10px;
        text-align: center;
        text-decoration: none;
        width: 100%;
        min-width:214px;

        &:hover {
            text-decoration: none;

            .mini-cart-total {
                border-color: $primary;
            }

        }

    }
    .checkout-btn.close-btn {
        display: none;
    }
    .checkout-btn {
        font-weight: normal;
        margin-bottom: 10px;
    }
}

.mini-cart-total {
    border-bottom: 3px solid transparent;
    position: relative;
    transition: border-color .2s;

    @include media-breakpoint-up(lg) {
        display: block;
        height: 100%;
    }

    .mini-cart-link {
        align-items: center;
        color: $body-color;
        display: flex;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: .3px;
        line-height: 1.5;
        padding: 4px 0 0;

        @include media-breakpoint-up(lg) {
            height: 100%;
            padding: 3px 14px 0 0;
        }

        &__label {

            @include media-breakpoint-down(md) {
                @include sr-only;
            }

            @include media-breakpoint-up(lg) {
                padding-right: 8px;
            }

        }

        &__icon {
            @include size(24px);
        }

        .minicart-quantity {
            @include size(18px);

            background-color: $secondary;
            border-radius: 50%;
            color: $body-bg;
            display: block;
            font-size: 12px;
            font-weight: 500;
            line-height: 20px;
            margin-top: -16px;
            margin-right: -10px;
            position: absolute;
                top: 50%;
                right: 0;
            text-align: center;

            @include media-breakpoint-up(lg) {
                @include size(22px);

                font-size: 13px;
                line-height: 24px;
                margin-top: -16px;
                margin-right: -2px;
            }

        }

    }

}

html[lang="fr"] {
    .mini-cart-link__label {
        @include media-breakpoint-down(lg) {
            @include sr-only();
        }
    }
}
html:not(.is-minicart-active) .mini-cart-content {
    display: none;
}
.mini-cart-content {
    background-color: $body-bg;
    border-bottom: 3px solid $primary;
    box-shadow: 0 20px 30px -5px rgba(116, 116, 116, 0.4);
    font-size: 12px;
    line-height: normal;

    max-width: 100vw;
    position: absolute;
    top: 100%;
    right:0;
    width: 294px;
    &.minicart-desktop-action {
        width: 700px;
        position: fixed;
        overflow-x: auto;
        max-height: calc(100vh - 56px);
        top: 16%;
        right: 32%;
        max-width:unset;
        border: 2px solid;

        @media screen and (max-width: 1023px) and (max-width: 768px) {
            right: 55%;
        }
        @media screen and (min-width:1023px) and (max-width:1025px) {
            top: 5%;
            right: 47%;
        }

        .product-tile {
            .product-image {
                .quickview {
                    width: 77%;
                    font-size: 20px;
                    right: 19px;
                }
            }
        }

        .mini-cart-products {
            margin-left:3%;
            align-items: center;
            .mini-cart-product {
                border: none;
                margin-top:0;
                display:none;

                &:first-child {
                    display: flex;
                }
            }

        }
        .mini-cart-totals {
            float: right;
            margin-right:3%;
            padding-left:8%;
            padding-right:8.25%;
            flex-wrap:wrap;

            .mini-cart-subtotals {
                width: 100%;
                text-align: center;
                border-top: 1px solid;
                border-bottom: 1px solid;
                padding-top:3px;
                margin:0;
                height:max-content;
            }
            .mini-cart-link-cart {
                margin-top:-25%;

                &:not(:root:root){
                    margin-top:0;
                }
            }
            .mini-cart-link-cart,.checkout-btn {
                white-space: nowrap;
                height:max-content;
                padding: 12px 0;
                width:187px !important;
            }
            .checkout-btn {
                font-weight: normal;
                margin-top:-30%;

                &:not(:root:root){
                    margin-top:-5% !important;
                    margin-bottom:0 !important;
                }
            }
            .dw-apple-pay-mini-cart {
                &:not(:root:root){
                    margin-top:0;
                }
            }
            div.reserveinventory-timer {
                width: 100%;
            }
        }

        /* GTN-1229 temporarily removed styling to show multiple items in minicart.  To be restored in a future release.
        &.multi-items-added {
            .mini-cart-products {
                flex-flow: column;

                .mini-cart-product {
                    display: flex;
                    min-width: 230px;
                    justify-content: space-around;
                }
            }
        }*/

        .mini-cart-products,.mini-cart-totals {
            border-bottom: 1px solid;
            width:47%;
            display:inline-flex;
            height:300px;
            min-height:300px;
            @media screen and (max-width: 1024px) {
                height: 15vh;
            }
            margin-bottom:3%;
            padding-top:50px;
        }
        .minicart-recommender {
            margin-bottom:5%;
            .shopnow-btn {
                margin-left:15%;
                .shop-now {
                    a {
                        color: $white;
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        right: 100%;
        margin-right: auto;
    }
    @media screen and (max-width: 350px) {
        margin-right:-35%;
        right:0;
    }
    @media screen and (min-width: 400px) and (max-width: 768px) {
        margin-right: -50%;
        width: 350px;
    }

    #mini-cart:hover & {
        display: block;
    }

    .mini-cart-close-btn {
        display:none;
        position: absolute;
        bottom: 90%;
        left: 94%;
        @include media-breakpoint-down(sm) {
            bottom: 97%;
            left: 94%;
        }
        background: white;
        border: 1px solid black;
        border-radius: 50%;
        button {
            display:block !important;
            @include background-svg($svg-close-red);
            padding: 15px;
            border-radius:50%;
            background-color: white;
        }
        button {
            display:none;
        }
    }

    .mini-cart-product-added,
    .mini-cart-bonus-product-added.has-message {
        background-color: $primary;
        color: white;
        text-align: center;
        height: auto;
        padding: 3px;

    }

    .minicart-recommendations {
        display:none;
        @include media-breakpoint-up(md) {
            .slick-prev {
                left: 5px;
            }
            .slick-next {
                right: 5px;
            }
            .c-product-tile-carousel__header {
                margin-top: 0;
                margin-bottom: 0.5rem;
            }
            .minicart-producttile {
                display: inline-block;
                width: 20%;
                height: auto;
                margin-right: 2%;
                margin-left: 1%;
                padding: 0%;
            }
            .product-tile {
                margin-bottom: 0;
                .product-tile-bottom {
                    display: none;
                }
                picture {
                    text-align: center;
                    img {
                        width: 50%;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
                .product-pricing,.name-link-wrap {
                    text-align: center;
                }

            }
         }
    }
}

.mini-cart-content.minicart-desktop-action.has-bonus-product-modal {
    display: none !important;
}

.has-sticky-header {
	.mini-cart-content {

	    &.minicart-desktop-action {
	        width: 700px;
	        position: fixed;
	        top: 100%;
	        left: 50%;
	        transform: translate(-50%, 0%);
	        float: left;
	        border: 2px solid;

	        @media screen and (max-width: 1023px) and (max-width: 768px) {
	            top: 100%;
	            left: 50%;
	            transform: translate(-50%, 0%);
	        }
	        @media only screen and (min-width:1023px) and (max-width:1025px) and (orientation: portrait){
	            top: 100%;
	            left: 50%;
	            transform: translate(-50%, 0%);
	        }
	        @media only screen and (min-device-width:1023px) and (max-device-width:1025px) and (orientation: landscape) {
	            top: 50%;
	            left: 50%;
	            transform: translate(-55%, 0%);
	        }
	        @media screen and (max-width: 768px) and (max-width: 1023px) {
	            top: 100%;
	            left: 50%;
	            transform: translate(-50%, 50%);
	        }
	        @media screen and (min-width:1365px) and (max-width:1367px) {
	            top: 100%;
	            left: 50%;
	            transform: translate(-50%, 0%);
	        }
	    }
	}
}

.mini-cart-header {
    padding: 1em 1em 0.5em;
    text-transform: uppercase;
}

.mini-cart-products {
    @include media-breakpoint-down(md) {
        max-height: 45vh;
        position: relative;
        z-index: 10;
    }
    overflow: auto;
    padding-top: 24px;

    a {
        &, &:hover {
            color: inherit;
        }
    }

}

.mini-cart-product {
    border-bottom: 1px solid $border-color;
    display: flex;
    margin: 22px $mini-cart-padding--right 27px;
    padding-bottom: 18px;

    &:last-of-type {
        margin-bottom: 0;
    }

    &__image {
        flex: 0 0 55px;
        min-width: 55px;
    }

    &__details {
        flex: 1;
        padding-left: 10px;
    }

    &__brand {
        font-size: 17px;
        font-weight: $font-weight-semibold;
        letter-spacing: 1px;
        line-height: 1.8;
    }
    &__name {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.29;
        margin-bottom: 6px;
    }

}

.mini-cart-totals {
    padding-right: $mini-cart-padding--right;
    padding-left: $mini-cart-padding--left;

    .proceedCheckout-form {
        margin-top: -5%;
    }
}

.mini-cart-subtotals,
.mini-cart-slot,
.mini-cart-link-cart {
    margin-top: $mini-cart-padding--top;
    margin-bottom: $mini-cart-padding--bottom;
}
.mini-cart-slot,
.mini-cart-link-cart {
    margin-top: 15px;
}
.mini-cart-subtotals,
.mini-cart-slot {
    margin-bottom: 13px;
}
.mini-cart-subtotals {
    font-size: 14px;
    text-align: right;

    @media screen and (max-height: 350px) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.mini-cart-slot {
    text-align: center;
}
.mini-cart-pricing {
    display: flex;
        justify-content: space-between;
    font-size: 14px;
}
.mini-cart-attributes {
    line-height: 1.6;
}

.reserveinventory-timer {
    display: none;
    line-height: 1;

    img,
    h2 {
        margin-bottom: 6px;
    }
    h2 {
        font-family: inherit;
        font-size: 15px;
        text-transform: uppercase;
    }

    .reserveinventory-expiry-countdown,
    .reserveinventory-expired {
        color: #fff;
        padding: 5px 12px;
        text-align: center;
    }
    .reserveinventory-expiry-countdown {
        background-color: #34871b;
    }
    .reserveinventory-expired {
        background-color: #cecece;
        display: none;
    }

    &--expired {
        .reserveinventory-expiry-countdown {
            display: none;
        }
        .reserveinventory-expired {
            display: block;
        }
    }

}

