.checkout-progress-indicator {
    overflow: hidden;
    div {
        display: inline;
        padding: 0;

        @include media-breakpoint-up(sm) {
            padding: 0 .83em 0 0;
        }

    }
    span {
        text-transform: uppercase;
    }
    .active span {
        font-weight: 700;
        color: $body-color;
    }
    a {
        color: $gray-800;
    }
    i {
        padding-left: .83em;
        color: $gray-400;
    }
    .step-3 {
        padding-right: 0;
    }

    .svg-symbol {
        display: inline-block;
        height: 1rem;
        width: 1rem;

        @include media-breakpoint-up(sm) {
            margin-left: 1rem;
        }

    }

}

.signin-main-div {
    display: flex;
    justify-content: space-between;
}
.signin-sub-body,.Guestcheckout-content {
    flex-basis: 60%;
    fieldset {
        margin-bottom: 1rem;
    }
}
.signin-sub-body {
    .login-box {
        h2 {
            font-size: 22px;
        }
        .login-rememberme {
            margin: 0
        }
    }
    #password-reset {
        margin: 0;
    }
    .button-fancy-large {
        min-width: 100%;
    }
    .passwd-rem {
        display: flex;
        justify-content: space-between;
        .c-form-row__label {
            margin: 0;
        }
    }
}
.Guestcheckout-content {
    .button-fancy-large {
        min-width: 100%;
    }
}
.registersignin {
    border: none;
    background: transparent;
    padding: 0;
    &:focus {
        outline: none;
    }
}
.signindiv, .register-content-div fieldset, .guestsignlink {
    margin-bottom: 0.5rem;
    padding: 10px 10px 10px 30px;
    display: block;
    color: #000;
    text-decoration: none;
}
.signinContainer {
    flex-basis: 40%;
    max-width: 20%;
    border-right: 1px solid #e4e4e4;
}
.signindiv,.guestsignlink {
    &.active {
        border-left: 2px solid #066c06;
        background-color: #f4f4f4;
    }
    &:hover {
        text-decoration: none;
        color: #000;
    }
}
.guest-btn-link,.guest-btn-link:hover {
    color: #fff;
}
@media (max-width:480px) {
    .signin-main-div {
        flex-flow: column;
    }
    .signinContainer {
        display: flex;
        justify-content: space-around;
        max-width: 100%;
        border-right: none;
    }
    .signindiv, .register-content-div fieldset, .guestsignlink {
        padding: 10px 30px;
        white-space: nowrap;
    }
    .signin-sub-body, .Guestcheckout-content {
        padding-top: 40px;
    }
    .signindiv, .guestsignlink {
        &.active {
        border-bottom: 2px solid #066c06;
        background-color: transparent;
        border-left: none;
    }
    }
    .signin-sub-body,.Guestcheckout-content {
        .col-6 {
            max-width: 100%;
        }
    }
}

.ship-to-multiple {
    align-items: normal;
    margin-bottom: 20px;
}
.shiptomultiplebutton {
    align-self: normal;
}

.gift-message-text {
    overflow: visible;
    opacity: 1;
    transition: all 1s ease;

    &.hidden {
        height: 0;
        margin: 0 !important;
        opacity: 0;
        overflow: hidden;
    }

    textarea {
        width: 100%;
    }

}

.redemption {
    .success {
        color: $success;
    }
    color: $promo;
    span.error {
        margin-left: 0;
    }
}

.payment-method-options {
    &.c-form-row {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
    }

    .form-row {
        clear: none;
        float: left;
        width: 50%;

        @include media-breakpoint-up(sm) {
            width: 22%;
        }
    }
}

.payment-method {
    clear: both;
    display: none;
    margin-bottom: 20px;
}

.payment-method-expanded {
    display: block;
}

.month,
.year,
.day {
    select {
        width: auto;
    }
}

.month .form-caption {
    margin-left: 0;
}

.cvn {
    clear: left;
    .field-wrapper {
        width: 50px;
    }
}

.ssn {
    clear: left;
}

.bml-terms-and-conditions {
    border: 1px solid;
    flex: 0 0 100%;
    font-size: 0.8em;
    height: 400px;
    overflow: auto;
    padding: 1em;

    @include media-breakpoint-up(sm) {
        flex: 0 0 66.6%;
    }
}

.bml-table-col-1 {
    width: 25%;
}

.pt_checkout {
    h3 {
        padding: 10px 10px 0;
    }
    .continue-button-row {
        align-items: center;
        justify-content: flex-end;
        padding-top: 20px;
    }

    .section-header {
        padding-right: 2rem;
        position: relative;

        .section-header-note {
            position: absolute;
                right: map-get($spacers, 2);
                bottom: 0;
            font-size: $font-size-sm;
            font-weight: 400;
            text-transform: none;
        }

    }

    #primary {
        width: 100%;
    }
    .cart-shipping-table {
        .cart-row {
            justify-content: flex-start;
        }
        .header-item-image-details {
            flex-basis: 50%;
        }
        .item-details {
            @include media-breakpoint-up(lg) {
                flex-basis: 41.5%;
            }
        }
        .item-quantity,
        .item-quantity-details,
        .item-total {
            @include media-breakpoint-down(md) {
                flex-basis: 33%;
            }
        }
        .item-quantity {
            text-align: right;

            @include media-breakpoint-up(lg) {
                text-align: center;
            }
        }
        .item-quantity-details {
            .product-availability-list {
                text-align: left;
            }
        }
        input,
        select {
            ~ .error {
                color: $danger;
                font-size: 13px;
            }
        }
    }
}
.checkout-mini-cart,
.checkout-order-totals {
    background-color: $gray-100;
}
.checkout-mini-cart {
    max-height: 400px;
    overflow: auto;
}

.checkout-order-totals {
    margin-bottom: 20px;
    padding: 0.3rem .5rem;
}

.place-order-totals {
    clear: both;
    .order-totals-table {
        float: right;

        @include media-breakpoint-up(sm) {
            width: 65%;
        }
        @include media-breakpoint-up(lg) {
            width: 50%;
        }
    }
}

.order-component-block {
    margin: 1.67em 0;
    .section-header a {
        float: right;
        font-weight: normal;
    }
    .details {
        background: none repeat scroll 0 0 $light;
        padding: 0.5em 1em;
    }
}

.order-summary-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    position: relative;

    h2 {
        font-size: 2em;
        font-style: italic;
        font-weight: normal;
    }
    .order-totals-submit-form {
        flex-basis: 100%;
        max-width: 100%;
    }

    .order-totals-submit-form {
        padding-right: map-get($grid-gutter-widths, xs);

        @include media-breakpoint-up(md) {
            @include make-col(7);
        }
        @include media-breakpoint-up(lg) {
            @include make-col(8);
        }
    }
}

.summary-help {
    float: right;
    font-size: 0.8em;
    margin: 1em 0;
}

.submit-order {
    clear: both;
    display: block;
    padding: 1.67em 0 0;
    text-align: right;

    button {
        margin-left: 1.5em;
    }
}

.order-discount {
    color: $promo;
}

.gift-cert-used {
    padding: .83em 0;
}

.summary .edit {
    position: absolute;
    text-transform: uppercase;
    right: 10px;
    font-size: 0.9em;
    line-height: 1.5em;
}

.pt_cart .breadcrumb {
    padding-left: 0;
}

.item-shipping-address {
    min-width: 120px;
}

.billing-error {
    color: red;
    margin: 0 0 .83em 24%;
}

.cvn-tooltip {
    font-weight: bold;
    margin: 5px;
    text-align: center;

    img {
        height: 150px;
        width: 110px;
    }
}

#shipping-method-list {
    margin-top: -15px;
    margin-bottom: -30px;
    position: relative;

    .standard-shipping {
        text-decoration: line-through;
    }

}

#secondary {
    .coupon-code {
        .input-text {
            width:70%;
        }
        .coupon-message {
            padding: 5px;
        }
        .success {
            color: $success;
        }
        .error {
            color: $error;
        }

        &.is-hidden {
            display: none;
        }
        &.is-active {
            display: block;
        }
        .coupon-line-items {
            padding: 10px 0;
            .name {
                font-weight: bold;
            }
            .cartcoupon {
                .label {
                    font-weight: bold;
                }
            }
        }
    }
}

.gift-card-div {
    padding-top: 2%;
    margin-bottom: 5%;
    border-top: 1px solid;
    border-bottom: 1px solid;

    .gift-card-container-btn {
        cursor: pointer;
    }
}