.shippingaddress {
    .select-address {
        width: 100%;
        margin-left: 0;
    }
    .edit-address {
        font-size: 14px;
        margin-top: 0.5em;

        span {
            color: inherit;
            cursor: pointer;
        }
    }
}

.formactions {
    margin-top: 20px;
    text-align: right;
}

.item-list .multi-shipment-row {
    border-bottom: none;
}

.minishipments-method {
    padding-top: 10px;
}

.multishipprogress {
    div, .step-3 {
        display: inline;
        padding: 1em;
    }
    .step-4 {
        padding-right: 0;
    }
}

.pt_checkout .cart-shipping-table .cart-row .shipment-label {
    font-weight: 700;
    margin-top: 35px;
    font-size: 1.4em;
}

.checkoutmultishipping {
    margin-bottom: 20px;
}
.cart-shipping-table {
    .select-shipping-method-label {
        display: flex;
        flex: 0 0 25%;
        justify-content: flex-end;
    }
    .select-shipping-method {
        flex: 0 0 50%;
        max-width: 50%;

        select {
            border: 1px solid $body-color;
            max-width: 100%
        }
    }
    .item-shipping-address {
        flex-basis: 25%;
        text-align: right;
    }
    .shipping-extra-options {
        flex-basis: 100%;
    }
}

