$ns: "c-graphical-asset";

.#{$ns} {
    $font-size: 16px;

    display: block;
    font-size: $font-size;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: normal;
    position: relative;
    text-align: center;

    &, &:hover {
        color: $body-color;
        text-decoration: none;
    }

    &__wrapperlink {
        color: inherit;
        text-decoration: none;

        &:hover, &:visited {
            text-decoration: none;
            color: inherit;
        }

        &:hover {
            a, .graphical-asset-pseudo-link {
                color: inherit;
                text-decoration: underline;
            }
        }

        > div {
            display: inline-block;
        }
    }

    &.#{$ns}--banner {
        text-align: left;

        @include media-breakpoint-up(lg) {
            margin-bottom: 22px;
        }

        &.#{$ns}--text-align-center {
            text-align: center;
        }

        &.#{$ns}--text-align-right {
            text-align: right;
        }

    }

    &.#{$ns}--fluid-text {
        font-size: vw($font-size, 375px);

        @include media-breakpoint-up(md) {
            font-size: vw($font-size, map-get($grid-breakpoints, xl));
        }

        @include media-breakpoint-up(xl) {
            font-size: $font-size;
        }

        &.#{$ns}--banner {
            font-size: vw(12px, map-get($grid-breakpoints, md));

            @include media-breakpoint-up(lg) {
                font-size: vw(12px, map-get($grid-breakpoints, xl));
            }

            @include media-breakpoint-up(xl) {
                font-size: 12px;
            }
        }

    }

    .graphical-asset-pseudo-link {
        color: $primary;
    }

    picture,
    img {
        display: block;
        width: 100%;
    }

    * {
        font-size: inherit;
    }

    &--text-dark {
        color: black;
    }
    &--text-light {
        &, &:hover {
            color: white;
        }

        &.#{$ns}--content-after {
            &, &:hover {
                color: inherit;
            }
        }

        &.#{$ns}--content-after-mobile {
            @include media-breakpoint-down(sm) {
                &, &:hover {
                    color: inherit;
                }
            }
        }

        &.#{$ns}--content-after-desktop {
            @include media-breakpoint-up(sm) {
                &, &:hover {
                    color: inherit;
                }
            }
        }
    }

    & {
        @include media-breakpoint-up(md) {
            text-align: left;
        }
    }

    &--text-align-center {
        @include media-breakpoint-up(md) {
            text-align: center;
        }
    }

    &--text-align-right {
        @include media-breakpoint-up(md) {
            text-align: right;
        }
    }

    &__wrapper {
        position: relative;
    }

    &__body {
        align-items: center;
        display: flex;
        font-size: inherit;
        flex-direction: column;
        justify-content: center;
        padding: 0 map-get($grid-gutter-widths, xs);
        position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

        @include media-breakpoint-up(lg) {
            .s-graphical-asset-1up & {
                padding: 0 77px;
            }
        }

        & {
            @include media-breakpoint-up(md) {
                align-items: flex-start;
            }
        }

        .#{$ns}--text-align-center & {
            @include media-breakpoint-up(md) {
                align-items: center;
            }
        }

        .#{$ns}--text-align-right & {
            @include media-breakpoint-up(md) {
                align-items: flex-end;
            }
        }

        .#{$ns}--content-after & {
            margin-top: map-get($spacers, 4);
            position: static;
        }

        .#{$ns}--content-after-mobile & {
            @include media-breakpoint-down(sm) {
                margin-top: map-get($spacers, 4);
                position: static;
            }
        }

        .#{$ns}--content-after-desktop & {
            @include media-breakpoint-up(sm) {
                margin-top: map-get($spacers, 4);
                position: static;
            }
        }

        .#{$ns}--banner & {
            align-items: flex-start;
            padding-right: map-get($grid-gutter-widths, xs);
            padding-left: map-get($grid-gutter-widths, xs);

            @include media-breakpoint-up(lg) {
                padding-right: map-get($grid-gutter-widths, lg);
                padding-left: map-get($grid-gutter-widths, lg);
            }

        }

        .#{$ns}--banner.#{$ns}--text-align-center & {
            align-items: center;
        }

        .#{$ns}--banner.#{$ns}--text-align-right & {
            align-items: flex-end;
        }
    }

    &--grid-tile {
        .#{$ns}__body * {
            font-size: revert;
        }
        p {
            margin-bottom: 0;
            &:last-of-type {
                margin-bottom: rem(16px);
            }
        }
    }

    &__line1,
    &__line2,
    &__line3 {
        color: inherit;
        font-family: $font-family-primary;
        font-weight: normal;
        letter-spacing: normal;
        line-height: 1.5;
        margin: 0;
        padding: 0;
        width: 100%;

        .#{$ns}__base & {
            max-width: 530px;
        }

        a, .graphical-asset-pseudo-link {
            &, &:hover {
                color: inherit;
            }
        }

    }

    &__line1 {
        text-transform: uppercase;

        .#{$ns}--fancy & {
            font-size: em(12px, $font-size);
            letter-spacing: em(1.5px, $font-size);
            margin-bottom: em(4px, $font-size);
        }

        .#{$ns}--simple & {
            font-size: em(15px, $font-size);
            font-weight: 600;
            letter-spacing: em(1.9px, $font-size);
            text-align: center;
        }

        .#{$ns}--banner & {
            color: inherit;
            font-size: em(12px, $font-size);
            font-weight: 600;
            line-height: normal;
            letter-spacing: 1px;
            margin-bottom: 10px;
            opacity: 0.5;
        }

    }

    &__line2 {

        .#{$ns}--fancy & {
            $line2-font-size: 47px;
            font-family: $font-family-secondary;
            font-size: em($line2-font-size, $font-size);
            font-weight: 600;
            letter-spacing: -#{em(.9px, $line2-font-size)};
            line-height: 1;
            margin-bottom: em(9px, $line2-font-size);

            @include media-breakpoint-up(sm) {
                .s-graphical-asset-1up & {
                    $line2-font-size: 60px;
                    font-size: em($line2-font-size, $font-size);
                    letter-spacing: -#{em(.3px, $line2-font-size)};
                }
            }

        }

        .#{$ns}--simple & {
            $line2-font-size: 22px;
            font-size: em($line2-font-size, $font-size);
            margin-top: map-get($spacers, 4);
            text-align: left;
        }

        .#{$ns}--banner & {
            color: inherit;
            font-size: em(33px, $font-size);
            font-family: $font-family-secondary;
            font-weight: bold;
            letter-spacing: normal;
            line-height: 1.09;
            text-transform: uppercase;
        }

    }

    &__line3 {

        .#{$ns}--fancy & {
            font-size: inherit;
            font-weight: 300;
            letter-spacing: normal;
        }

        .#{$ns}--simple & {
            font-size: em(12px, $font-size);
            font-weight: normal;
            letter-spacing: normal;
            text-align: left;
            text-decoration: underline;
        }

        .#{$ns}--banner & {
            font-size: em(14px, $font-size);
            font-weight: normal;
            letter-spacing: normal;
            text-decoration: underline;
        }

    }

    &__buttons {
        @include make-col(10);
        flex: 0; // just want the max-width from make-col()
        font-size: inherit;
        margin: 0 auto;

        @include media-breakpoint-up(sm) {
            @include make-col(8);
            flex: 0;
        }

        @include media-breakpoint-up(md) {
            margin: 0;
            max-width: none;
        }

        @include media-breakpoint-up(lg) {
            .s-graphical-asset-1up & {
                margin: em(20px, $font-size) 0;
            }

        }

        .#{$ns}--banner & {
            margin-top: em(26px, $font-size);
            margin-bottom: -#{em(20px, $font-size)};
        }

    }

    &__button {
        $button-font-size: 12px;
        display: flex;
        height: em(46px, $button-font-size);
        padding: 0 em(40px, $button-font-size);
        margin: em(28px, $button-font-size) 0;
        justify-content: center;

        &:last-child {
            margin-bottom: 0;
        }

        @include media-breakpoint-up(md) {

            .s-graphical-asset-1up & {
                display: inline-flex;
                margin: em(10px, $button-font-size);

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }

        }

        &, &:hover {
            align-items: center;
            background-color: $body-color;
            border: 1px solid $body-color;
            color: $body-bg;
            font-size: em($button-font-size, $font-size);
            font-weight: normal;
            text-decoration: none;
            text-transform: uppercase;
        }

        .#{$ns}--banner & {
            color: inherit;
            background-color: transparent;
            border: 2px solid $primary-alt;
            display: inline-block;
            font-size: em(14px, $font-size);
            font-weight: 600;
            height: auto;
            line-height: normal;
            letter-spacing: 1.5px;
            margin: 0 em(20px, $font-size) em(20px, $font-size) 0;
            padding: em(11px, $font-size) em(29px, $font-size) em(8px, $font-size);
            text-align: center;
            text-transform: uppercase;

            &:hover {
                border-color: $primary-alt;
            }

        }

        .#{$ns}--banner.#{$ns}--text-align-right & {
            margin-left: em(20px, $font-size);
            margin-right: 0;
        }

        .#{$ns}--banner.#{$ns}--text-align-center & {
            &:lest-child {
                margin-right: 0;
            }
        }

    }

    &-group-header {
        margin-top: map-get($spacers, 5);
        margin-bottom: map-get($spacers, 4);

        h2, h3 {
            display: block;
            font-family: $headings-font-family;
            font-size: 30px;
            font-weight: 600;
            letter-spacing: -.2px;
            margin: 0;
            text-align: center;
        }

        p, div {
            display: block;
            margin: 0;
            text-align: center;
        }

        a, .graphical-asset-pseudo-link {
            &, &:hover {
                color: inherit;
                font-size: 12px;
                font-weight: normal;
                text-decoration: underline;
            }
        }

    }

    &-group {
        @include make-row();
        align-items: flex-start;

        &--2up,
        &--3up,
        &--4up,
        &--5up {
            .#{$ns} {
                @include make-col-ready();
                margin-bottom: map-get($spacers, 5);

                &__body {
                    right: map-get($grid-gutter-widths, xs) / 2;
                    left: map-get($grid-gutter-widths, xs) / 2;

                    @include media-breakpoint-up(lg) {
                        right: map-get($grid-gutter-widths, lg) / 2;
                        left: map-get($grid-gutter-widths, lg) / 2;
                    }
                }

            }


        }

        &--2up {
            @include media-breakpoint-up(md) {
                .#{$ns} {
                    @include make-col(6);
                }
            }
        }

        &--3up {
            @include media-breakpoint-up(md) {
                .#{$ns} {
                    @include make-col(4);
                }
            }
        }

        &--4up, &--5up {
            @include media-breakpoint-up(sm) {
                .#{$ns} {
                    @include make-col(6);
                }
            }

            @include media-breakpoint-up(md) {
                .#{$ns} {
                    @include make-col(3);
                }
            }

        }

    }

}
