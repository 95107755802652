.js {
    .ui-dialog {
        max-width: 1200px;

        #pdpMain {
            .product-col-1 {
                flex: 0 0 40%;
            }
            .product-col-2 {
                flex: 0 0 60%;
                max-width: unset;
            }
            .product-variations {
                width:52%;
                display: inline-flex;
                float: left;
                ul {
                    width: 100%;
                    li.attribute {
                        display: inline-flex;
                        width:100%;
                        margin-right:4%;

                        @include media-breakpoint-up(md) {
                            width: 45%;
                        }
                        .value {
                            flex-basis: 100%;
                            max-width: unset;
                            & > * {
                                flex:0 0 100%;
                                max-width: unset;
                            }
                        }
                    }
                }
                ul {
                    li.variant-color {
                        width: 100%;
                    }
                }
            }

            .js-personalization {
                float: left;
            }

            .product-add-to-cart {
                float:right;
                width: 100%;
                border-bottom: none;

                .inventory-availability-wrap {
                    float: left;
                    display: inline-flex;
                    width:100%;
                    justify-content: center;

                    @include media-breakpoint-up(md) {
                        width: 40%;
                    }
                    .instore-how-it-works {
                        display: none;
                    }
                }
                .product-add-cart__buttons-wrap {
                    display: inline-flex;
                    flex-wrap: wrap;
                    width:60%;
                    .button-fancy-large {
                        display: block;
                        width:100%;
                        border-radius:10px;
                        max-width:unset;
                        flex-basis:100%;
                    }
                }
            }
        }
    }

    .instore-how-it-works {
        clear: both;
    }

    .ui-dialog-content {
        .product-col-1 {
            padding: 13px 1%;

            @include media-breakpoint-up(md) {
                width: 39%;
            }

            .product-image {
                max-width: 100%;
            }
        }
        .product-col-2 {
            padding: 20px 0;

            @include media-breakpoint-up(md) {
                width: 58%;
            }
            .ml-0 {
                display: none;
            }
        }
        .pdp-main {
            .product-set-list {
                height: 500px;
                overflow: auto;
            }
        }
    }
    .zoomPad {
        position: relative;
        float: left;
        z-index: 99;
        cursor: crosshair;
        width: 100%;
    }
    .zoomPreload {
        -moz-opacity: 0.8;
        opacity: 0.8;
        filter: alpha(opacity = 80);
        color: $gray-800;
        font-size: 12px;
        font-family: $sans-serif;
        text-decoration: none;
        border: 1px solid $gray-400;
        background-color: $body-bg;
        padding: 8px;
        text-align: center;
        background-image: url(../images/zoomloader.gif);
        background-repeat: no-repeat;
        background-position: 43px 30px;
        z-index: 110;
        width: 90px;
        height: 43px;
        position: absolute;
        top: 0px;
        left: 0px;
        *width: 100px;
        *height: 49px;
    }
    .zoomPup {
        overflow: hidden;
        background-color: $body-bg;
        -moz-opacity: 0.6;
        opacity: 0.6;
        filter: alpha(opacity = 60);
        z-index: 120;
        position: absolute;
        border: 1px solid $gray-400;
        z-index: 101;
        cursor: crosshair;
    }
    .zoomOverlay {
        position: absolute;
        left: 0px;
        top: 0px;
        background: $body-bg;
        z-index: 5000;
        width: 100%;
        height: 100%;
        display: none;
        z-index: 101;
    }
    .zoomWindow {
        position: absolute;
        left: 110%;
        top: 40px;
        background: $body-bg;
        z-index: 6000;
        height: auto;
        z-index: 10000;
        z-index: 110;
    }
    .zoomWrapper {
        position: relative;
        border: 1px solid $gray-500;
        z-index: 110;
    }
    .zoomWrapperTitle {
        display: block;
        background: $gray-500;
        color: $body-bg;
        height: 18px;
        line-height: 18px;
        width: 100%;
        overflow: hidden;
        text-align: center;
        font-size: 10px;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 120;
        -moz-opacity: 0.6;
        opacity: 0.6;
        filter: alpha(opacity = 60);
    }
    .zoomWrapperImage {
        display: block;
        position: relative;
        overflow: hidden;
        z-index: 110;
        img {
            border: 0px;
            display: block;
            position: absolute;
            z-index: 101;
        }
    }
    .zoomIframe {
        z-index: -1;
        filter: alpha(opacity = 0);
        -moz-opacity: 0.80;
        opacity: 0.80;
        position: absolute;
        display: block;
    }
}

.quickview-nav {
    .quickview-next {
        float: right;
    }
}
div[aria-describedby="QuickViewDialog"] {
    .ui-dialog-titlebar {
        padding-top: 40px;
    }
    .pdp-main {
        padding-top: 0;
    }
    .ui-dialog-content {
        @include media-breakpoint-up(lg) {
            padding-top: 0;
        }
    }
}

.pzballs-messaging {
    color: red;
}

.ui-dialog {
    .product-image-container {
        .product-thumbnails {
            order: 2;
            display: block;
            width: calc(100% - 56px);
            min-width: calc(100% - 56px);
            padding: 0 28px;
            ul.slick-slider {
                display: block;
                width: 100%;
                min-width: 100%;
            }
            .slick-prev.slick-arrow {
                top: 21%;
                left: -26px;
            }
            .slick-next.slick-arrow {
                top: 21%;
                right: -26px;
            }
            li.thumb {
                margin: 0;
            }
        }
        .product-thumbnails .slick-list {
            width: 100%;
            max-width: 100%;
            overflow: hidden;
        }
    }
    #pdpMain {
        .product-col-1 {
            align-content: flex-start;
        }
        .product-primary-image {
            padding-bottom: 45px;
        }
        .product-add-to-cart {
            &.add-sub-product {
                width: auto;
            }
            &.product-set__product-add-to-cart {
                padding-top: 2em;
                width: 100%;
            }
        }

        @include media-breakpoint-down(md) {
            .product-primary-image {
                padding-bottom: 0;
            }
            .pdp-main-row {
                display: block;
            }
            .product-variations, .inventory-availability-wrap, .product-add-to-cart, .product-add-cart__buttons-wrap {
                display: block;
                width: unset;
            }
            li.attribute {
                width: 100%;
            }
            .inventory-availability-wrap {
                margin-bottom: 20px;
            }
            #pr-reviewdisplay {
                clear: both;
            }
        }
    }
}
