/*!
 * Bootstrap v4.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

 @import "variables"; // defaults
 @import "mixins";
 @import "bootstrap-overrides/alert";
 @import "bootstrap-overrides/mixins/grid";
 @import "bootstrap-overrides/root";
 @import "reboot";
 @import "type";
 @import "bootstrap-overrides/type";
 @import "images";
 @import "code";
 @import "grid";
 @import "bootstrap-overrides/grid";
 @import "tables";
//  @import "forms";
 @import "buttons";
 @import "bootstrap-overrides/mixins/buttons";
 @import "transitions";
//  @import "dropdown";
//  @import "button-group";
//  @import "input-group";
//  @import "custom-forms";
//  @import "nav";
//  @import "navbar";
//  @import "card";
//  @import "breadcrumb";
//  @import "pagination";
//  @import "badge";
//  @import "jumbotron";
@import "alert";
//  @import "progress";
//  @import "media";
//  @import "list-group";
//  @import "close";
//  @import "modal";
//  @import "tooltip";
//  @import "popover";
//  @import "carousel";
 @import "utilities";
 @import "print";
