/* ============================================================================
   Simple Tile
   ============================================================================ */

$ns: 'c-simple-tile';

.#{$ns} {
    &__text {
        color: $black;

        &:hover {
            color: $black;
            text-decoration: none;
        }
    }

    &__carousel {
        margin-bottom: 30px;
        margin-top: 32px;

        @include media-breakpoint-up(md) {
            margin-bottom: 40px;
            margin-top: 35px;
        }

        &-wrap {
            &--center {
                .slick-track {
                    @extend .d-flex;
                    @extend .justify-content-center;

                    max-width: 100%;
                    transform: none !important;  // Important because slick puts the transform inline on the track
                }
            }
            &--center-md {
                .slick-track {
                    @extend .d-md-flex;
                    @extend .justify-content-md-center;

                    @include media-breakpoint-up(md) {
                        max-width: 100%;
                        transform: none !important;  // Important because slick puts the transform inline on the track
                    }
                }
            }
            &--center-lg {
                .slick-track {
                    @extend .d-lg-flex;
                    @extend .justify-content-lg-center;

                    @include media-breakpoint-up(lg) {
                        max-width: 100%;
                        transform: none !important;  // Important because slick puts the transform inline on the track
                    }
                }
            }
        }

        &__headline {
            font-size: 30px;
            letter-spacing: 1.1px;
            margin-bottom: 32px;
            text-align: center;
            text-transform: uppercase;
        }
    }
}
