.content-slot img {
    height: auto;
    max-width: 100%;
}

.slot-grid-header p {
    margin: 0;
    padding: 0;
}

.product-listing-1x4 {
    background: none repeat scroll 0 0 $body-bg;
    overflow: hidden;
    padding: .83em 0;
    .search-result-items .grid-tile {
        margin: 0 1% 0 2%;
        width: 18%;
    }
}

.content-asset {
    p {
        line-height: 1.6em;
        margin: 0 0 1em;
    }
    ul,
    ol {
        line-height: 1.6em;
        margin: 1em 0;
        padding: 0 1.5em;
    }
    address {
        margin: 1em 0;
    }
    .account-options {
        &--landing-box {
            margin: 0 0 map-get($spacers, 6);
            a {
                background-color: $gray-100;
                color: $body-color;
                display: block;
                min-height: 250px;
                padding: 0;
                text-align: center;
                svg {
                    height: 54px;
                    margin: 50px auto 20px;
                    width: 54px;
                    use {
                        fill: $body-color;
                        stroke: $body-color;
                    }
                    &.icon-svg-user use {
                        stroke: none;
                    }
                }
                h2 {
                    padding: 0rem 1.5rem 0;
                }
                p {
                    margin-top: 0.3rem;
                    padding: 0rem 1.5rem 0;
                }
             }
        }
    }
}

.browser-compatibility-alert {
    margin: map-get($spacers, xs) 0;
    padding: map-get($spacers, xs);
    text-align: center;
    width: 100%;

    @include media-breakpoint-up(lg) {
        margin: map-get($spacers, lg) 0;
        padding: map-get($spacers, lg);
    }

    p {
        margin: 0;
    }

}

.menu-slot {
    display: none;
    border-top: 1px solid #faeded;
    margin-top: 0.5em;
    padding-top: 1.5em;
    .social-links {
        padding-left: 0;
        li {
            display: inline-block;
            padding-left: 0;
        }
    }
    @media screen and (min-width: 768px) {
        width: 13.5rem;
        display: block;
    }
}

#homepage-slides {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;

    .slide {
        margin: 0 0 20px;
        overflow: hidden;
        padding: 0;
        position: relative;

        img {
            max-width: 100%;
        }
        .banner-message {
            position: absolute;
            top: 10%;
            @media screen and (min-width: 768px) {
                top: 2%;
            }
            .banner-text1 {
                font-family: $sans-serif-alt;
                font-size: 2rem;
                font-weight: 300;
                text-transform: uppercase;
                @media screen and (min-width: 768px) {
                    font-size: 5rem;
                    font-weight: 200;
                }
            }
            .banner-text2 {
                font-family: $serif;
                font-size: 1.5rem;
                font-style: italic;
                text-transform: lowercase;
                @media screen and (min-width: 768px) {
                    font-size: 4rem;
                }
            }
            .banner-text3 {
                font-family: $sans-serif-alt;
                font-size: 0.5rem;
                font-weight: 400;
                text-transform: uppercase;
                @media screen and (min-width: 768px) {
                    font-size: 1.3rem;
                    font-weight: 200;
                }
            }
        }
    }
    .slide1 {
        .banner-message {
            left: 3%;
            .banner-text1 {
                color: #00629b;
                display: inline;
            }
            .banner-text2 {
                color: $body-bg;
                display: inline;
            }
            .banner-text3 {
                color: $body-bg;
                text-align: right;
            }
        }
    }
    .slide2 {
        .banner-message {
            left: 3%;
            .banner-text1 {
                color: $body-color;
                display: inline;
                letter-spacing: -0.1rem;
            }
            .banner-text2 {
                color: $gray-800;
                display: inline;
            }
            .banner-text3 {
                color: $body-color;
            }
        }
    }
    .slide3 {
        .banner-message {
            left: 3%;
            .banner-text1 {
                color: $body-color;
                display: inline;
                margin-top: -2rem;
            }
            .banner-text2 {
                color: $body-bg;
                display: inline;
            }
            .banner-text3 {
                color: $body-color;
            }
        }
    }
    .slide4 {
        .banner-message {
            right: 3%;
            .banner-text1 {
                color: $body-color;
                margin-top: 0rem;
                text-indent: 3rem;
                @media screen and (min-width: 768px) {
                    margin-top: -1rem;
                }
            }
            .banner-text2 {
                color: $body-bg;
            }
            .banner-text3 {
                color: $body-bg;
            }
        }
    }
    .slide5 {
        .banner-message {
            left: 15%;
            .banner-text1 {
                color: #00629b;
                display: inline;
            }
            .banner-text2 {
                color: $promo;
                display: inline;
            }
            .banner-text3 {
                color: $body-bg;
            }
        }
    }
}

#home-bottom-left {
    margin: auto;
    max-width: 320px;
    position: relative;
    text-align: center;
    width: 100%;
    h2 {
        color: $body-color;
        font-family: $sans-serif-alt;
        font-size: 2rem;
        font-weight: 100;
        margin: 0;
        padding: 1rem 0;
        text-transform: uppercase;
        @media screen and (min-width: 768px) {
            font-size: 2.3rem;
        }
    }
    ul {
        margin: -.5em 0 .5em 0;
        li {
            border-right: 1px solid $gray-600;
            color: $gray-600;
            display: inline;
            font-family: $sans-serif-alt;
            font-size: .8rem;
            font-weight: 400;
            list-style: none outside none;
            margin: 0 5px 0 0;
            padding: 0 5px 0 0;
            text-transform: uppercase;
            &:last-child {
                border: 0 none;
            }
            span {
                margin: 0 10px 0 0;
            }
        }
    }
    img {
        display: block;
    }
}

#home-bottom-center {
    width: 100%;
    margin: auto;
    max-width: 320px;
    position: relative;
    .home-bottom-center-text {
        position: absolute;
        text-align: center;
        top: 18%;
        width: 100%;
        h1 {
            color: #00629b;
            font-family: $serif;
            font-size: 4rem;
            font-style: italic;
            font-weight: normal;
            margin: 0;
            padding: 0;
            text-transform: lowercase;
        }
        h2 {
            color: $gray-600;
            font-family: $sans-serif-alt;
            font-size: 1.3rem;
            font-weight: 100;
            letter-spacing: .2em;
            margin-top: -.05rem;
            text-transform: uppercase;
        }
        h3 {
            color: $gray-600;
            font-family: $serif;
            font-size: .8rem;
            font-weight: normal;
            margin-top: -1rem;
            text-transform: uppercase;
        }
    }
    img {
        display: block;
    }
}

.home-bottom-right {
    font-family: $sans-serif-alt;
    width: 100%;
    margin: auto;
    max-width: 320px;
    position: relative;
    text-align: center;
    .home-bottom-right-header {
        h2 {
            color: $gray-600;
            font-size: 0.7rem;
            font-weight: 400;
            padding: .5em 0 0 0;
            margin: 0;
            @media screen and (min-width: 768px) {
                font-size: 2.3rem;
                font-weight: 200;
            }
        }
        h3 {
            color: #64772d;
            font-size: 2.2rem;
            font-style: normal;
            font-weight: 200;
            margin: 0;
            padding: 0;
            text-transform: uppercase;
            @media screen and (min-width: 768px) {
                font-size: 2.3rem;
            }
        }
        img {
            display: block;
        }
    }
    .product-tile {
        border: 0 none;
        margin: 1em auto 0;
        min-height: 255px;
        width: 90%;
        img {
            box-shadow: 0px 0px 5px 3px $gray-400;
            max-width: 90%;
        }
        .product-name {
            height: auto;
        }
        .product-sales-price {
            color: $body-color;
        }
    }
}
.cart-recommendations {
    margin-top: 1rem;

    .last-visited {
        margin-bottom: map-get($spacers, 4);
    }
    .product-listing {
        @include clearfix();
        background-color: $gray-100;
        padding: 1rem 0;
    }
    .c-product-tile-carousel {
        width: calc(100% + 40px);
        margin-left: -20px;
        margin-right: -20px;
    }
}
.category-tiles {
    margin-top: 36px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 55px;
        margin-top: 40px;
    }

    .category-slot {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        > h2 {
            //Just prevent extra elements from interfering
            width: 100%;
        }
    }
    .category-tile {
        flex: 0 0 47%;

        @include media-breakpoint-up(lg) {
            flex-basis: 31%;
        }
    }
}
.category-tiles__header {
    font-size: 36px;
    margin-bottom: 28px;
    text-align: center;

    @include media-breakpoint-up(lg) {
        margin-bottom: 26px;
    }
}
.cart-recommendations,
.category-slot,
.product-slot {
    .tiles-container {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        .grid-tile {
            box-shadow: none;
            margin: 1rem;
            width: 25%;
        }
    }
    h1 {
        color: $body-bg;
        font-family: $sans-serif-alt;
        font-size: 3rem;
        font-weight: 300;
        position: absolute;
        text-align: right;
        bottom: 2%;
        left: 2%;
        @media screen and (min-width: 768px) {
            font-weight: 100;
        }
    }
    h2 {
        margin: 0 auto 30px;
        text-align: center;
    }
    h3 {
        color: $body-color;
        font-family: inherit;
    }
    h4,
    .h4 {
        background: transparent;
        color: $body-color;
        font-family: inherit;
        line-height: normal;
    }
    a {
        &:hover {
            h3 {
                text-decoration: underline;
            }
        }
    }
    .catlanding-banner {
        margin-bottom: map-get($grid-gutter-widths, xs);
        overflow: hidden;
        position: relative;
        width: 100%;

        img {
            height: auto;
            width: 100%;
        }
    }
    .category-tile {
        float: left;
        margin-bottom: 36px;
        overflow: hidden;
        padding: 0 .15%;
        position: relative;
        width: 100%;

        @include media-breakpoint-up(lg) {
            margin-bottom: 51px;
            width: 33%;
        }
        img {
            height: auto;
            margin-bottom: 13px;
            width: 100%;
        }
    }
    .cat-banner {
        overflow: hidden;
        position: relative;
        width: 100%;
        img {
            height: auto;
            width: 100%;
        }
        h1 {
            color: $gray-800;
            right: 2%;
        }
    }
    .search-result-items {
        .grid-tile {
            box-shadow: none;
            margin: 0;
            width: 100%;
            @media screen and (min-width: 768px) {
                width: 33%;
            }
            .product-tile {
                margin: 0 .15%;
                padding: 1rem 0;
                width: 100%;
                .product-name {
                    height: auto;
                    margin-top: .5em;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    a {
                        color: #00629b;
                    }
                }
            }
        }
    }
}
.cart-recommendations,
.product-slot {
    h3 {
        background-color: $body-bg;
        bottom: 0;
        font-family: $sans-serif-alt;
        font-size: 2rem;
        font-weight: 300;
        margin: 0;
        opacity: .6;
        padding: 2rem;
        position: absolute;
        text-transform: uppercase;
        width: 100%;
    }
}
.html-slot {
    background-color: $gray-100;
    border-color: $gray-200;
    border-style: solid;
    border-width: 1px 0;
    h1 {
        color: #00629b;
        font-family: $sans-serif-alt;
        font-size: 1.5rem;
        font-weight: 300;
        margin: 1rem;
        text-align: center;
        @media screen and (min-width: 768px) {
            font-weight: 100;
        }
        a {
            font-family: $serif;
        }
    }
}
