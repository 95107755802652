/* ============================================================================
   Datepicker
   ============================================================================ */

// jQueryUI datepicker doesn't have the option to add classes.  So target the elements directly

$ns: ui-datepicker;

.#{$ns} {
    background-color: $white;
    border: 1px solid $primary;
    padding-left: 8px;
    padding-right: 8px;

    &-prev,
    &-next {
        cursor: pointer;
    }
    &-prev {
        margin-right: 10px;
    }

    td {
        min-width: 24px;
    }
}
