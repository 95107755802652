/* ============================================================================
   UGC Helpers
   ============================================================================ */

/* Colors
   ============================================================================ */
$brand-call-to-action: #be1e24;

/* SVG
   ============================================================================ */
$svg-arrow-prev: '<svg width="17px" height="28px" viewBox="0 0 17 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <defs> <polygon id="path-1" points="11.227241 13.5 0.280287144 2.55304619 2.55304619 0.280287144 15.772759 13.5 2.55304619 26.7197129 0.280287144 24.4469538"></polygon> </defs> <g id="HP-&amp;-CLP" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="Homepage" transform="translate(-52.000000, -3589.000000)"> <g id="instagram" transform="translate(0.000000, 3406.000000)"> <g id="icon24-/-arrow-/-right" transform="translate(60.500000, 197.000000) scale(-1, 1) translate(-60.500000, -197.000000) translate(52.000000, 183.000000)"> <g id="outlined" transform="translate(0.333333, 0.500000)"> <mask id="mask-2" fill="white"> <use xlink:href="#path-1"></use> </mask> <use id="Mask" fill="#333333" fill-rule="nonzero" xlink:href="#path-1"></use> <g id="color-/-GT-grass-green-edited-(AA-complient)" mask="url(#mask-2)"> <g transform="translate(-20.333333, -15.500000)"> <polygon id="#FF1800" fill="#569842" points="0 0 57.9201102 0 57.9201102 57.9201102 0 57.9201102"></polygon> <polygon id="tweaked-for-accessibility" fill="#34871B" points="0 0 57.9201102 0 57.9201102 57.9201102 0 57.9201102"></polygon> </g> </g> </g> </g> </g> </g> </g> </svg>';
$svg-arrow-next: '<svg width="17px" height="28px" viewBox="0 0 17 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <defs> <polygon id="path-1" points="11.227241 13.5 0.280287144 2.55304619 2.55304619 0.280287144 15.772759 13.5 2.55304619 26.7197129 0.280287144 24.4469538"></polygon> </defs> <g id="HP-&amp;-CLP" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="Homepage" transform="translate(-1371.000000, -3595.000000)"> <g id="icon24-/-arrow-/-right" transform="translate(1371.000000, 3595.000000)"> <g id="outlined" transform="translate(0.333333, 0.500000)"> <mask id="mask-2" fill="white"> <use xlink:href="#path-1"></use> </mask> <use id="Mask" fill="#333333" fill-rule="nonzero" xlink:href="#path-1"></use> <g id="color-/-GT-grass-green-edited-(AA-complient)" mask="url(#mask-2)"> <g transform="translate(-20.333333, -15.500000)"> <polygon id="#FF1800" fill="#569842" points="0 0 57.9201102 0 57.9201102 57.9201102 0 57.9201102"></polygon> <polygon id="tweaked-for-accessibility" fill="#34871B" points="0 0 57.9201102 0 57.9201102 57.9201102 0 57.9201102"></polygon> </g> </g> </g> </g> </g> </g> </svg>';
$svg-modal-close: '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/" x="0px" y="0px" width="32px" height="32px" viewBox="-7.939 -7.939 32 32" overflow="visible" enable-background="new -7.939 -7.939 32 32" xml:space="preserve"> <path id="Line-5_1_" fill="none" stroke="#be1e24" stroke-width="1.5" stroke-linecap="square" d="M15.203,1.061L1.061,15.203"/> <path id="Line-5-Copy_1_" fill="none" stroke="#be1e24" stroke-width="1.5" stroke-linecap="square" d="M15.203,15.203L1.061,1.061" /> </svg>';

// Credit https://codepen.io/jakob-e/pen/doMoML

//
//  Function to create an optimized svg url
//
@function svg-url($svg){
    //
    //  Add missing namespace
    //
    @if not str-index($svg,xmlns) {
        $svg: str-replace($svg, '<svg','<svg xmlns="http://www.w3.org/2000/svg"');
    }
    //
    //  Chunk up string in order to avoid
    //  "stack level too deep" error
    //
    $encoded:'';
    $slice: 2000;
    $index: 0;
    $loops: ceil(str-length($svg)/$slice);
    @for $i from 1 through $loops {
        $chunk: str-slice($svg, $index, $index + $slice - 1);
        //
        //   Encode
        //
        $chunk: str-replace($chunk,'"', '\'');
        $chunk: str-replace($chunk,'%', '%25');
        $chunk: str-replace($chunk,'&', '%26');
        $chunk: str-replace($chunk,'#', '%23');
        $chunk: str-replace($chunk,'{', '%7B');
        $chunk: str-replace($chunk,'}', '%7D');
        $chunk: str-replace($chunk,'<', '%3C');
        $chunk: str-replace($chunk,'>', '%3E');

        //
        //    The maybe list
        //
        //    Keep size and compile time down
        //    ... only add on documented fail
        //
        //  $chunk: str-replace($chunk, '|', '%7C');
        //  $chunk: str-replace($chunk, '[', '%5B');
        //  $chunk: str-replace($chunk, ']', '%5D');
        //  $chunk: str-replace($chunk, '^', '%5E');
        //  $chunk: str-replace($chunk, '`', '%60');
        //  $chunk: str-replace($chunk, ';', '%3B');
        //  $chunk: str-replace($chunk, '?', '%3F');
        //  $chunk: str-replace($chunk, ':', '%3A');
        //  $chunk: str-replace($chunk, '@', '%40');
        //  $chunk: str-replace($chunk, '=', '%3D');

        $encoded: #{$encoded}#{$chunk};
        $index: $index + $slice;
    }
    @return url("data:image/svg+xml,#{$encoded}");
}

//  Background svg mixin
@mixin background-svg($svg){
    background-image: svg-url($svg);
}

//  Helper function to replace characters in a string
@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);
    @return if($index,
        str-slice($string, 1, $index - 1) + $replace +
        str-replace(str-slice($string, $index +
        str-length($search)), $search, $replace),
        $string);
}

