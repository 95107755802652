/* Slider */
// BEGIN slick base styles
// DO NOT edit
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &::before,
    &::after {
        content: "";
        display: table;
    }

    &::after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}
// END slick base styles

// BEGIN slick override styles
.slick-dotted {
    margin-bottom: 50px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 60px;
    }
}

.slick-arrow {
    @include size(17px, 52px);
    @include background-svg($svg-slick-arrow);
    background-color: transparent;
    background-repeat: no-repeat;
    border: 0;
    position: absolute;
        top: 30%;
        z-index: 1;
    overflow: hidden;
    padding: 0;
    text-indent: -999em;

    &.slick-disabled {
        cursor: default;
        opacity: .25;
    }

}

.slick-prev {
    left: -11px;
}
.slick-next {
    right: -11px;
    transform: rotate(180deg);
}

.slick-dots {
    padding: 0;
    text-align: center;
    position: absolute;
        right: 0;
        left: 0;

    li {
        background-color: #d5d5d5;
        border-radius: 50%;
        display: inline-block;
        font-size: 0;
        height: 8px;
        margin-left: 5px;
        margin-right: 5px;
        width: 8px;

        button {
            background-color: transparent;
            border: none;
            outline: none;
        }
    }
    .slick-active {
        background-color: $gray-600;
    }
}

// prevent flash of content before slick initialized
[data-slick]:not(.slick-initialized) {
    > :first-child ~ * {
        display: none;
    }
}

// Specific placeholder/smooth fade-in for product recommendation carousels on PDP
.pdp-below-productdetails-wrapper {
    [data-slick]:not(.slick-initialized) {
        opacity: 0;
        max-height: 350px; // Minimize CLS by preserving space for eventual product carousel content (pre initialization) #CoreWebVitals
    }

    .slick-initialized {
        opacity: 1;
        max-height: none; // Let the natural height apply after initialization
        transition: opacity .5s;
    }

    .slick-dots {
        transform: translateY(-30px); // Move pagination dots up closer to carousel content
    }
}

.mini-cart-content .dw-apple-pay-button {
    display: none !important;
    margin-top: 12px;					;
}

#pdpMain .mini-cart-content .dw-apple-pay-button {
    margin-top: initial
}

.mini-cart-content .dw-apple-pay-button.dw-apple-pay-mini-cart {
	width: 100%;
	float: none;
	margin-bottom: 20px !important;
	padding-bottom: 10px !important;
    padding-top: 10px 	!important;
	border-radius: 0 !important;
	display: block !important;
}

.product-add-cart__buttons-wrap .dw-apple-pay-button {
	border-radius: 0 !important;
	width: 100% !important;
	padding: 14px 15px 10px 15px !important;
	font-size: 14px !important;
	font-weight: 600 !important;
	display: inline-block !important;
	letter-spacing: 1.5px !important;
	margin-left: 0px !important;
}

.cart-action-checkout .dw-apple-pay-button {
	display: none !important;
}

.cart-action-checkout .dw-apple-pay-button.dw-apple-pay-cart {
	width: 100% !important;
	float: none;
	display: block !important;
	border-radius: 0 !important;
	font-size: 14px !important;
	padding: 14px 15px 10px 15px !important;
}

#dwfrm_billing .applepay .dw-apple-pay-button {
    width: 100% !important;
    max-width: 260px;
	float: none;
	display: block !important;
	border-radius: 0 !important;
	font-size: 14px !important;
    padding: 14px 15px 10px 15px !important;
    margin: 0 0 -60px 0;
}
