/* ============================================================================
    Form Rows
    ============================================================================ */

$ns: "c-form-row";

.#{$ns} {
    display: block;
    position: relative;

    & + & {
        margin-top: 22px;
    }

    // adjust for nested form-rows
    .#{$ns} {
        margin: 0 12px 0 0;
    }

    // Form Row Label
    // 1. Allows us to style box model properties.
    &__label {
        display: block;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: .5px;
        margin-bottom: .33em;
        text-transform: uppercase;
    }

    &.cc-field {
        > iframe {
            max-width: 427px;
        }
    }

    > .#{$ns}__label {
        @include media-breakpoint-up(sm) {
            margin-top: .5em;
            &--inline {
                align-self: auto;
            }
            &--left-aligned {
                text-align: left;
            }
        }
        @include media-breakpoint-up(lg) {
            &--left-aligned {
                min-width: 0;
            }
        }

        .s-form-flatten & {
            min-width: auto;
            width: auto;
        }

        .s-form-flatten--tablet & {
            @include media-breakpoint-up(sm) {
                min-width: auto;
                width: auto;
            }
        }

        .s-form-flatten--desktop & {
            @include media-breakpoint-up(lg) {
                min-width: auto;
                width: auto;
            }
        }
    }

    // Form row container
    &__form-field-container {
        display: flex;
        flex-direction: column;
        width: 100%;

        &--row {
            flex-direction: row;
        }

        button + button,
        button + .tooltip {
            margin-left: 5px;
        }
        textarea {
            + .char-count {
                font-size: 14px;
            }
        }

        .#{$ns}__input ~ .error {
            color: $danger;
            font-size: 12px;
            font-weight: normal;
            letter-spacing: .5px;
        }
    }

    // Form row container
    &__button-group {
        display: flex;
        flex-direction: row;

        &__button {
            margin-left: 12px;
        }

    }

    //Form row input
    &__input {

        .has-error & {
            background-color: white;
            border-color: $danger;
        }

        @include media-breakpoint-up(sm) {
            &.input-textarea {
                min-height: 100px;
            }
        }

    }

    &__tooltip,
    &__caption {
        font-size: 12px;
    }

    // Form Row Caption
    &__caption {
        display: block;
    }

    // Form Submit Button
    &__button {
        margin-top: 15px;
    }


    // Form Row Error
    &__error-message {
        color: $danger;
        display: block;
        font-size: 13px;
        width: 100%;
    }

    &__label-required {
        display: none;

        .required & {
            display: inline;
        }

    }
    &__label-optional {
        color: $gray-500;
        font-size: 10px;
        font-weight: normal;
        margin-left: 5px;
    }
    &__label-characters {
        color: $gray-600;
        font-size: 10px;
        font-weight: normal;
        margin-left: 5px;
        display: block;
    }
    .form-row {
        @include media-breakpoint-down(sm) {
            margin-left: 0;
            margin-right: 0;
        }
    }
    .input-checkbox,
    .field-wrapper {
        + .form-caption,
        + label {
            flex-basis: calc(100% - 18px - .4em);

            @include media-breakpoint-up(md) {
                flex-basis: auto;
            }
        }
    }

    &--hide-label-optional {
        .#{$ns}__label-optional {
            @include sr-only;
        }
    }

}

/* Modifier classes
* ============================================================================ */

.#{$ns}--inline {

    .#{$ns}__input {
        margin-top: .1em;
    }

    .#{$ns}__label {
        margin-bottom: 0;
    }

    &.#{$ns}--radio {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;

        & ~ & {
            margin-top: map-get($spacers, 3);
        }

        .#{$ns}__label,
        .#{$ns}__form-field-container {
            display: block;
            margin: 0;
            width: auto;
        }

        .#{$ns}__form-field-container {
            padding-left: map-get($spacers, 2);
        }

        .#{$ns}__form-field-container {
            .#{$ns}__label {
                display: inline;
                margin-right: .5em;
            }
        }

    }

}

.#{$ns}--group {

    &.#{$ns},
    .#{$ns} {
        display: flex;

        &__form-field-container {
            margin-left: map-get($grid-gutter-widths, xs);
        }

        &__label {
            display: inline-block;
        }

    }

    .#{$ns}__label + .#{$ns}__label {
        margin-left: map-get($grid-gutter-widths, xs);
    }

}

.#{$ns}--inline-group {
    // inline group of sub c-form-row's. i.e. cc expiration
    .#{$ns} {
        display: flex;
        flex-direction: column;

        .#{$ns}__input {
            width: 100%;
        }

    }

}

// input with inline button
.#{$ns}--inline-button {
    align-items: center;
    display: flex;

    .#{$ns}__label {
        + .#{$ns}__input {
            margin-left: map-get($spacers, 2);
        }
    }

    .#{$ns}__input {
        width: 100%;
    }

    .#{$ns}__button {
        margin: 0;
        margin-left: map-get($spacers, 2);
    }

}

.#{$ns}--checkbox {

    .#{$ns}__label {
        font-size: 13px;
        font-weight: inherit;
        letter-spacing: inherit;
        text-transform: none;
    }

}


.s-form-row-stacked {
    .#{$ns} {
        display: block;

        &__label {
            min-width: auto;
            width: auto;
        }
    }
}

.s-form-row-narrow {
    .#{$ns} {
        @include media-breakpoint-up(sm) {
            &__label {
                flex: 1;
                min-width: auto;
                width: auto;
            }
            &__form-field-container {
                flex: 2;
            }
        }
    }
}

.s-form-hide-required {
    .c-form-row__label-required {
        display: none !important;
    }
}


.form-row {
    &:not(.#{$ns}--inline) {
        margin-bottom: map-get($grid-gutter-widths, xs);
    }
}

