.pt_product-search-result {
    .c-top-promo-banner {
        height: inherit;
        
    }
    .primary-content {
        .content-header {
            font-style: normal;
            margin-bottom: map-get($grid-gutter-widths, xs);
            padding: 0 10px;

            @include media-breakpoint-up(lg) {
                padding: 0;
            }
        }
    }
}

.pt_content-search-result {
    .primary-content {
        .pagination {
            padding: 0;
        }
    }
    .folder-content-list {
        margin-bottom: 0;
        margin-top: -#{map-get($spacers, 4)};
        padding: 0;
    }
    .search-result-options--bottom {
        margin-bottom: 10px;
    }
}
.category-head,
.folder-head {
    overflow: auto; // To account for child margins
    position: relative;
}
.pt_product-search-noresult {
    .section-header {
        padding-left: 0;
        padding-right: 0;
    }
    .simple-search {
        padding-bottom: 8px;
        padding-top: 9px;

        @include media-breakpoint-down(xs) {
            margin-top: 12px;
        }
    }
}

.category-main-banner img {
    max-width: 100%;
}

.no-hits-message {
    margin-bottom: map-get($spacers, 4);
}
.no-hits-search-term,
.no-hits-search-term-suggest {
    color: $error;
    font-style: italic;
}

.no-hits-footer {
    margin: 20px 0;
    padding: 20px 0;
}

.search-result-bookmarks,
.search-result-options,
.pt_product-search-result .content-header {
    padding: 1em 10px;

    @include media-breakpoint-up(lg) {
        padding-left: 0;
        padding-right: 0;
    }
}

.pt_product-search-result,
.pt_content-search-result {
    h1 {
        margin-right: 0;
        margin-left: 0;
        text-align: left;
    }

    .slot-grid-header {
        .html-slot-container {
            margin-bottom: map-get($spacers, 4);
        }
    }
}
.endofproducts {
    @include media-breakpoint-up(lg) {
    	border-top: 1px solid $border-color;
	    padding: 20px 10px;
	}
}

.plp-loadmore {
	text-align: center;
}

.search-result-options {
    font-size: 13px;
    letter-spacing: .25px;

    .sort-by {
        padding-bottom: 1em;
        @include media-breakpoint-up(lg) {
            float: left;
            margin-right: 20px;
            min-width: 160px;
            padding-bottom: 0;
        }
    }
    .no-results-text,
    .items-per-page {
        float: left;
        margin-right: .5em;
    }
    form {
        margin-bottom: 0;
    }
    label {
        display: none;
    }
    .input-select--alt {
        margin-right: 30px;
        width: auto;
    }
    .total-items {
        position: absolute;
            right: 0;
            top: 14px;
        display: block;
        white-space: nowrap;

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
    .pagination {
        font-size: 15px;
        text-align: center;
        vertical-align: top;

        @include media-breakpoint-up(lg) {
            float: right;
            padding-top: .8em;
        }
        .results-hits {
            color: $black;
            display: inline-block;
            font-weight: 300;
            margin-right: 2px;
            vertical-align: top;

            @include media-breakpoint-up(lg) {
                display: block;
                float: left;
                margin-right: 10px;
            }
        }
        ul {
            align-items: center;
            border: none;
            border-radius: 5px;
            background-color: transparent;
            display: flex;
                justify-content: space-between;
            list-style: none;
            margin: 0 -12px;
            padding: 0;
            width: calc(100% + 24px);

            @include media-breakpoint-up(sm) {
                display: inline-flex;
                    justify-content: flex-start;
                margin-left: 0;
                margin-right: 0;
                width: auto;
            }
            @include media-breakpoint-up(lg) {
                display: flex;
                float: right;
            }
        }
        li {
            border: none;
            float: left;
            line-height: 1.4;
            letter-spacing: 0.3px;
            margin: 0 5px;
            padding: 0 4px;

            a,
            &.current-page,
            i {
                display: block;
                font-size: inherit;
                height: auto;
                line-height: inherit;
                text-align: center;
                width: auto;
            }
            &.current-page {
                border-bottom: 2px solid $primary;
                color: $primary;
                font-weight: 500;
            }
            a {
                color: inherit;
            }
        }

        .svg-symbol {
            display: block;
            height: 1rem;
            width: .6rem;

            &.svg-angle-right,
            &.svg-angle-left {
                width: .4rem;
            }

        }

    }
    .items-per-page {
        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
            min-width: 125px;
        }
    }
        
    &--bottom {
        position: relative;
        padding-left: 0;
        padding-right: 0;
		
		
        .pagination {
            padding-top: 0;
        }

        .items-per-page,
        .sort-by {
            display: none;
        }

        .total-items {
            display: none;
            top: -1.75rem;

            @include media-breakpoint-up(lg) {
                display: block;
            }

        }

        .no-results-text,
        .back-to-product-results {
            display: none;

            @include media-breakpoint-up(lg) {
                display: block;
            }
        }
    }
}
.search-result-bookmarks {
    border-bottom: 1px solid $border-color;
    clear: both;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.6px;
    padding: 0 0 2px 0;
    margin: 15px 0 30px 0;
    text-align: center;
    text-transform: uppercase;
    width: 100%;

    @include media-breakpoint-up(sm) {
        width: auto;
    }

    a,
    span {
        border-bottom: 2px solid transparent;
        color: inherit;
        cursor: pointer;
        margin: 0 17px;
        padding: 0;

        &.is-active,
        &:hover {
            border-bottom-color: $primary;
            text-decoration: none;
        }
    }
}

.search-result-options--top {
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
        @include clearfix;
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: 45px;
    }

    .pagination {
        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
        }

    }

}

.search-result-items {
    padding: 0;

    ul {
        list-style: none outside none;
    }

    &.tiles-container {
        @include make-row();
    }

    &.slots-container {
        display: block;
    }

    .grid-tile {
        @include make-col-ready();
        @include make-col(6);
        list-style: none outside none;
        margin-bottom: map-get($spacers, 4);
        padding: 0 10px;

        @include media-breakpoint-up(md) {
            @include make-col(4);
        }
    }

    &.slots-container {
        display: -ms-grid;
        display: grid;

        grid-template-columns: 1fr 1fr;
        @include media-breakpoint-up(md) {
            -ms-grid-columns: 1fr 1fr 1fr;
            -ms-grid-rows: 1fr 1fr 1fr 1fr;
            grid-template-columns: repeat(3, 1fr);
        }

        .grid-tile {
            max-width: 100%;
            flex: none;
       
            @include media-breakpoint-up(md) {
                &.column-1{
                    -ms-grid-column: 1;
                }
                &.column-2{
                    -ms-grid-column: 2;
                }
                &.column-3{
                    -ms-grid-column: 3;
                }
                &.column-4{
                    -ms-grid-column: 4;
                }
                &.row-1{
                    -ms-grid-row: 1;
                }
                &.row-2{
                    -ms-grid-row: 2;
                }
                &.row-3{
                    -ms-grid-row: 3;
                }
                &.row-4{
                    -ms-grid-row: 4;
                }
            }
        }

        .columnonebyone {
            /* 1 x 1 */
            //nothing needed yet
            @include media-breakpoint-down(sm) {
                .c-graphical-asset__body {
                    padding: 0 10px;
                }
                .slotWrap {
                    height: 100%;
                }
                .c-graphical-asset {
                    height: 100%;
                }
                .c-graphical-asset__wrapper {
                    height: 100%;
                }
                .c-graphical-asset__image {
                    height: 100%;
                }
                picture {
                    height: 100%;
                    img {
                        height: 100%;
                    }
                }
            }
            .grid-asset-header {
                 color: #FFF;
                 letter-spacing: normal;
                 font-family: $font-family-sans-serif;
                 text-transform: uppercase;
                 @include media-breakpoint-down(sm) {
                    font-size: 29px;
                    margin-top: 10px;
                    text-align: inherit;
                }
            }

            .grid-asset-body {
                font-family: $font-family-sans-serif;
                color: #FFF;
                @include media-breakpoint-down(sm) {
                    font-size:15px;
                    font-weight: 400;
                }
            }

            .justify-content-start {
                margin-top: 20px;
            }

            .justify-content-end {
                margin-bottom: 20px;
            }

            .cta-asset {
                background-color: #fff;
                color: #000;
                text-align: center;
                width: 100%;
                font-size:15px;
                z-index: 2;
                padding: 15px 0 12px 0;
                text-decoration: none;
                font-family: $font-family-sans-serif;
                font-weight: 600;
                line-height: 1.5;
                letter-spacing: 1.5px;
                
                &:hover {
                    text-decoration: underline;
                }
                @include media-breakpoint-down(sm) {
                    font-size:13px;
                    padding: 11px 0 8px 0;
                }
            }

            .fine-print {
                margin-top: 15px;
                @include media-breakpoint-down(sm) {
                    font-size: 12px;
                }
            }
        }
        .columntwobyone {
            /* 2 x 1 */
            -ms-grid-column-span: 2;
            grid-column: span 2;
            grid-row: span 1;
            .slotWrap {
                height: 100%;
            }
            .c-graphical-asset {
                height: 100%;
            }
            .c-graphical-asset__wrapper {
                height: 100%;
            }
            .c-graphical-asset__image {
                height: 100%;
            }
            picture {
                height: 100%;
                img {
                    height: 100%;
                }
            }
           .grid-asset-header {
                 color: #FFF;
                 letter-spacing: normal;
                 font-family: $font-family-sans-serif;
                 text-transform: uppercase;
            }

            .grid-asset-body {
                font-family: $font-family-sans-serif;
                color: #FFF;
                @include media-breakpoint-down(sm) {
                    font-size:15px;
                    font-weight: 400;
                }
            }

            .justify-content-start {
                margin-top: 20px;
            }

            .justify-content-end {
                margin-bottom: 20px;
            }

            .justify-content-split {
                .grid-asset-body {
                    flex: 0 1 60%;
                    @include media-breakpoint-down(sm) {
                        flex: inherit;
                    }
                }
                .fine-print {
                    flex: 0;
                    @include media-breakpoint-down(sm) {
                        flex: inherit;
                    }
                }
            }

            .cta-asset {
                background-color: #fff;
                color: #000;
                text-align: center;
                width: 45%;
                font-size:15px;
                z-index: 2;
                padding: 15px 0 12px 0;
                text-decoration: none;
                font-family: $font-family-sans-serif;
                font-weight: 600;
                line-height: 1.5;
                letter-spacing: 1.5px;
                
                &:hover {
                    text-decoration: underline;
                }
                @include media-breakpoint-down(sm) {
                    width: 100%;
                    font-size:13px;
                    padding: 11px 0 8px 0;
                }
            }

            .fine-print {
                margin-top: 15px;
                @include media-breakpoint-down(sm) {
                    font-size: 12px;
                }
            }
        }

        .columnthreebyone {
            /* 3 x 1 */
            grid-column: span 2;
            @include media-breakpoint-up(md) {
                -ms-grid-column-span: 3;
                grid-column: span 3;
            }
        }

        .columnonebytwo{
            -ms-grid-row-span: 2;
            grid-row: span 2;
            .slotWrap {
                height: 100%;
            }
            .c-graphical-asset {
                height: 100%;
            }
            .c-graphical-asset__wrapper {
                height: 100%;
            }
            .c-graphical-asset__image {
                height: 100%;
            }
            picture {
                height: 100%;
                img {
                    height: 100%;
                }
            }
            @include media-breakpoint-down(sm) {
                .c-graphical-asset__body {
                    padding: 0 10px;
                }
             }
            .grid-asset-header {
                 color: #FFF;
                 letter-spacing: normal;
                 font-family: $font-family-sans-serif;
                 text-transform: uppercase;
                 @include media-breakpoint-down(sm) {
                    font-size: 29px;
                    margin-top: 10px;
                    text-align: inherit;
                }
            }
            

            .grid-asset-body {
                font-family: $font-family-sans-serif;
                color: #FFF;
                @include media-breakpoint-down(sm) {
                    font-size:15px;
                    font-weight: 400;
                }
            }

            .justify-content-start {
                margin-top: 20px;
            }

            .justify-content-end {
                margin-bottom: 20px;
            }

            .cta-asset {
                margin-top: 15px;
                background-color: #fff;
                color: #000;
                text-align: center;
                width: 100%;
                font-size:15px;
                z-index: 2;
                padding: 15px 0 12px 0;
                text-decoration: none;
                font-family: $font-family-sans-serif;
                font-weight: 600;
                line-height: 1.5;
                letter-spacing: 1.5px;
                
                &:hover {
                    text-decoration: underline;
                }
                @include media-breakpoint-down(sm) {
                    font-size:13px;
                    padding: 11px 0 8px 0;
                }
            }

            .fine-print {
                margin-top: 15px;
                @include media-breakpoint-down(sm) {
                    font-size: 12px;
                }
            }
        }
        
        .columntwobytwo {
            /* 2 x 2 */
            -ms-grid-column-span: 2;
            grid-column: span 2;
            -ms-grid-row-span: 2;
            grid-row: span 2;
        }
        
        .columnthreebytwo {
            /* 3 x 2 */
            -ms-grid-row-span: 2;
            grid-row: span 2;
            grid-column: span 2;
            @include media-breakpoint-up(md) {
                -ms-grid-column-span: 3;
                grid-column: span 3;
            }
        }
    }
    
    .invisible {
        display: none;
    }

}

.search-result-content {
    clear: both;
    .product-price {
        .price-standard {
            color: #999;
            font-weight: 100;
            text-decoration: line-through;
        }
    }
}

.search-promo {
    padding: 20px;
}

#results-content {
    padding-top: 30px;
}

.product-tile,
.folder-content-list {
    .name-link {
        color: $body-color;
        display: inline-block;
        font-size: 13px;

        @include media-breakpoint-up(lg) {
            font-size: 16px;
        }

        .product-brand {
            font-weight: 600;
        }
        .product-name,
        .content-title {
            font-weight: $font-weight-light;

            @include media-breakpoint-up(lg) {
                line-height: 1.5;
            }
        }
    }
}
.folder-content-list {
    clear: both;
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    padding: 0;
    width: auto;

    @include media-breakpoint-up(lg) {
        min-width: 100%;
    }

    li {
        background-color: $gray-100;
        border: 1px solid $gray-400;
        flex-basis: 100%;
        list-style-type: none;
        margin: 0 10px 15px;
        padding: 1rem;

        @include media-breakpoint-up(lg) {
            flex-basis: calc(50% - 20px);
            margin: 10px;
            max-width: calc(50% - 20px);
        }
    }
    p {
        line-height: 1.1rem;
    }
    .content-title {
        display: block;
    }
    .content-excerpt {
        font-size: 13px;
    }
    .readmore {
        font-size: 12px;
        font-style: italic;
    }
}

.no-results {
    padding: 20px;
}

.toggle-grid {
    display: block;
    float: left;
    margin-right: 0.5em;
    i {
        cursor: pointer;
        margin-right: 0.3em;
    }
    [data-option="column"] {
        color: $body-color;
    }
    &.wide {
        [data-option="wide"] {
            color: $body-color;
        }
        [data-option="column"] {
            color: $gray-600;
        }
    }
}
#grid-sort-header {
    &:focus,
    &:active {
        @include media-breakpoint-down(lg) {
            font-size: 16px;
        }
    }
}

.infinite-scroll-div {
    .infinite-scroll-messaging {
        text-align: center;
    }
}

.progress-bar {
    width: 25%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    background-color: $gray-300;
}

.progress-percent {
    width: 1%;
    height: 10px;
    background-color: $primary;
}