$locale-fr-breakpoint: 1200px;

@import "nav";
@import "nav-desktop";
@import "menu_utility";

// The styles of children of the header with a #header selector that use breakpoints
// Allow the breakpoints to vary in cases like locale
@mixin header-variable-styles($breakpoint-up: lg, $breakpoint-down: md, $breakpoints: $grid-breakpoints) {
    @include media-breakpoint-up($breakpoint-down, $breakpoints) {
        border-bottom: none;
    }

    .header-container {
        @include media-breakpoint-up($breakpoint-up, $breakpoints) {
            align-items: center;
            height: 72px;
            padding-top: 0;
            padding-bottom: 0;
        }

        &__left {
            @include media-breakpoint-up($breakpoint-up, $breakpoints) {
                display: none !important;
            }
        }

        &__right {
            @include media-breakpoint-up($breakpoint-up, $breakpoints) {
                flex-shrink: 1;
                display: block;
                height: 100%;
                width: 445px;
            }
        }
    }

    .stores-link {
        @include media-breakpoint-up($breakpoint-up, $breakpoints) {
            margin-right: 3px;
        }

        &__icon {
            @include size(24px);
        }

        &__label {
            @include media-breakpoint-down($breakpoint-down, $breakpoints) {
                @include sr-only;
            }

            @include media-breakpoint-up($breakpoint-up, $breakpoints) {
                padding-left: map-get($spacers, 4);
            }
        }
    }

    .search-toggle {
        @include media-breakpoint-up($breakpoint-up, $breakpoints) {
            display: none;

            &--desktop {
                border-bottom: 3px solid transparent;
                display: flex;
                align-items: center;
                margin-left: 0;
                padding: 22px 0;

                .is-search-active & {
                    border-color: $primary;
                }

            }

        }
    }
}

// The styles of children of the header without a #header selector that use breakpoints
// Allow the breakpoints to vary in cases like locale
@mixin header-variable-content-styles($breakpoint-up: lg, $breakpoint-down: md, $breakpoints: $grid-breakpoints) {
    @include media-breakpoint-down($breakpoint-up, $breakpoints) {
        html[lang="fr"] {
            .search-toggle__label {
                @include sr-only();
            }
        }
    }

    .primary-logo {

        @include media-breakpoint-up($breakpoint-up, $breakpoints) {
            flex-shrink: 1;
            margin: 1px map-get($spacers, 4) 0 0;
            text-align: left;
            width: 445px;
        }

        a {
            @include media-breakpoint-up($breakpoint-up, $breakpoints) {
                max-width: 100%;
                width: 192px;
            }

        }

    }

    .header-search {
        @include media-breakpoint-down($breakpoint-down, $breakpoints) {
            box-shadow: 0 20px 30px -5px rgba(116, 116, 116, 0.5);
            padding: 0 map-get($grid-gutter-widths, xs) map-get($spacers, 3);
            position: absolute;
                top: 100%;
                right: 0;
            width: 100vw;
            z-index: 1;
        }

        @include media-breakpoint-up($breakpoint-up, $breakpoints) {
            flex-shrink: 2;
            display: block;
            flex-basis: 550px;
            min-width: 550px;
            &.mobile-only {
                display:none;
            }
        }

        #{&}__input {
            @include media-breakpoint-up($breakpoint-up, $breakpoints) {
                font-size: 14px;
                padding: 8px 0 8px 14px;
            }

        }
    }
}

.header-banner {
    --header-banner-background-color: #{inspect($primary)};
    --header-banner-color: #{inspect($white)};
    background-color: $primary;
    background-color: var(--header-banner-background-color);
    color: $white;
    color: var(--header-banner-color);
    overflow: hidden;
    position: relative;
    max-height: 75px;
    transition: max-height .25s;
    z-index: $zindex-header-banner;

    @include media-breakpoint-down(md) {
        display:none;
    }
    .is-navigation-active & {
        display: none;
    }

    a {
        font-size: 13px;
        font-weight: 500px;

        &, &:hover {
            color: inherit;
        }
    }

    &__banner {
        color: #fff;
        font-size: 13px;
        font-weight: 500;
        line-height: .5;
        letter-spacing: 1px;
        text-align: center;
        text-transform: uppercase;

        &.mobile-only {
            @include media-breakpoint-down(md) {
                background-color:$primary;
            }
        }
        .svg-symbol {
            @include size(32px);
            margin-right: 5px;
        }

        p {
            align-items: center;
            display: flex;
            line-height: 1.2;
            justify-content: center;
            margin: 0;
        }

    }

    @include media-breakpoint-up(lg) {
        max-height: none;
        overflow: visible;
    }

    &__util {
        display: none;
        min-width: 16rem;

        @include media-breakpoint-up(lg) {
            display: block;
        }

        &--right {
            text-align: right;
        }

    }

}


.header-banner-container {
    @include make-container();
    @include make-container-max-widths();

    min-height: 36px;
    margin: auto;

    @include media-breakpoint-up(lg) {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

}

#header {
    // Place styles before includes to make sure everything is loaded in the proper order
    background-color: $body-bg;
    border-bottom: 1px solid $border-color;
    position: relative;
    z-index: $zindex-header - 2;

    .has-sticky-header & {
        -webkit-transform: translate3d(0, 0, 0); // forces hardware acceleration. Helps stop flickering of fixed positioned elements.
        position: fixed;
            top: 0;
            right: 0;
            left: 0;
            z-index: $zindex-header;
        width: 100%;
    }
    .header-container {
        @include make-container();
        @include make-container-max-widths();
        align-items: center;
        display: flex;
        height: 53px;
        justify-content: space-between;
        margin: auto;
        position: relative;

        &__icon {
            color: $primary;
        }
        .email-signup-header-mobile {
            margin: 1px 0px 0px 30%;
        }
    }
    .stores-link {
        align-items: center;
        color: inherit;
        display: flex;
        font-size: 14px;
        font-weight: normal;
        margin-right: 20px;
        padding: 0 0 1px;

        &__label {
            font-weight: 300;
            line-height: 1.2;
            padding-right: 4px;
            text-align: right;
        }
    }
    .search-toggle {
        color: $body-color;
        display: flex;
        font-size: 13px;
        font-weight: normal;
        letter-spacing: .5px;
        margin: 1px 0 -1px 21px;

        &__icon {
            @include size(24px);
        }

        &--desktop {
            display: none;
        }

        &.desktop-only {
            display: none;
        }
    }

    &:not(.header--fr) {
        @include header-variable-styles();
        @include header-variable-content-styles();
        @include nav-variable-styles();
        @include desktop-nav-variable-styles(lg, $grid-breakpoints, break, (break: 1074px));
    }

    &.header--fr {
        @include header-variable-styles('locale-fr', 'locale-fr', ('locale-fr': $locale-fr-breakpoint, 'locale-fr-next': $locale-fr-breakpoint));
        @include header-variable-content-styles('locale-fr', 'locale-fr', ('locale-fr': $locale-fr-breakpoint, 'locale-fr-next': $locale-fr-breakpoint));
        @include nav-variable-styles('locale-fr', ('locale-fr': $locale-fr-breakpoint, 'locale-fr-next': $locale-fr-breakpoint));
        @include desktop-nav-variable-styles('locale-fr', ('locale-fr': $locale-fr-breakpoint, 'locale-fr-next': $locale-fr-breakpoint));
    }
    .header-search {
        @include media-breakpoint-down(md) {
            &.mobile-only {
                position:relative;
                padding:0;
                box-shadow:none;
                display: block;
            }
            &.desktop-only {
                display: none;
            }
        }
    }
}

.primary-logo {
    line-height: 0;

    a {
        display: inline-block;
        max-width: 134px; // width of image
    }
}
.header-search {
    display: none;
    background-color: $body-bg;
    position: relative;

    &:focus-within {
        .header-search {
            &__container {
                border: 2px solid #eaf4db;
                outline: 1px solid #02a94d;
            }
        }
    }
    #{&}__container {
        border: 1px solid $primary-alt;
        display: flex;
        max-width: 100%;
    }
    #{&}__input {
        border: 0;
        font-size: 16px; // mist be 16px to prevent iOS zoom
        font-weight: 400;
        letter-spacing: normal;
        line-height: 1.45;
        max-width: none;
        padding: 6px 0 6px 8px;
        width: 100%;

        &:focus {
            outline: 0;
        }
        &::placeholder{
            @include media-breakpoint-down(md) {
                font-weight: 700;
            }
        }
    }
    #{&}__submit {
        align-items: center;
        background: none;
        border: none;
        display: flex;
        justify-content: center;
        min-width: 40px;
        padding: 0;

        &__icon {
            @include size(24px);
            color: $primary;
        }

    }
    .is-search-active & {
        display: block;
    }
}

.locale-select {
    a {
        &, &:hover {
            border-bottom: 1.5px solid transparent;
            display: inline-block;
            font-size: .9285em;
            letter-spacing: 1px;
            line-height: 1;
            padding: 0;
            text-decoration: none;
            text-transform: uppercase;

            &.is-active {
                border-color: rgba(white,.4);
            }

        }
    }

    a + a {
        margin-left: .5em;
    }

}
.mobile-only {
    display:none;
    @include media-breakpoint-down(sm) {
        display:block;
    }
}
.desktop-only {
    display:none;
    @include media-breakpoint-up(md) {
        display:block;
    }
}
