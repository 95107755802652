.svg-mcsubscribe {
   height: 30px;
   width: 30px;

   &-success {
      color: $primary;
   }

   &-error {
      color: $danger;
   }
}