$cart-link-size: 14px;

.dw-apple-pay-button,
.dw-apple-pay-button:hover {
    margin: 0;
}

.cart-shipping-table {
    thead {
        display: none;
        @include media-breakpoint-up(lg) {
            display: table-header-group;
        }
    }
    th,
    td {
        flex: 0 0 40%;
        padding-left: 8px;
        padding-right: 8px;

        @include media-breakpoint-up(sm) {
            flex: 0 1 33%;
        }
        @include media-breakpoint-up(lg) {
            flex: 0 1 11%;
        }
    }
    td {
        box-sizing: border-box;
        display: block;
        padding-bottom: 5px;
        padding-top: 5px;
    }
    .btn-link {
        border: none;
        font-size: $cart-link-size;
    }
    .cart-header-row,
    .cart-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .cart-header-row {
        align-items: flex-end;
        border-bottom: none;
    }
    .cart-row {
        padding: map-get($grid-gutter-widths, xs) 0;
    }
    .section-header {
        font-size: 16px !important;
        font-weight: $font-weight-semibold;
        padding-bottom: 15px;
        padding-top: 15px;
        text-align: left;
    }
    .header-item-image-details,
    .item-image {
        float: left; // LEGACY: to support IE (no flexbox)
        order: 1;
        text-align: center;

        @include media-breakpoint-up(lg) {
            float: none;
        }
    }
    .header-item-image-details {
        flex-basis: 42%;
        order: 2;
        text-align: left;
    }
    .item-image {
        flex-basis: 33%;
        max-width: 33%;
        padding-left: 0;

        @include media-breakpoint-up(sm) {
            flex: 0 0 20%;
            max-width: 20%;
        }
        @include media-breakpoint-up(lg) {
            flex-basis: 8.5%;
        }

        img {
            width: 100%;
        }
    }
    .item-details {
        float: left; // LEGACY: to support IE (no flexbox)
        flex-basis: 67%;
        margin-bottom: map-get($grid-gutter-widths, xs);
        max-width: 67%;
        order: 2;

        @include media-breakpoint-up(sm) {
            flex-basis: 80%;
            max-width: 80%;
        }
        @include media-breakpoint-up(lg) {
            flex-basis: 30%;
            float: none;
            margin-bottom: 0;
            padding-right: 5px;
        }
        .name {
            font-size: 1rem;
        }
        // Removes Edit Details Quickview link for handheld devices
        .item-edit-details {
            display: none;
            font-size: $cart-link-size;

            @include media-breakpoint-up(lg) {
                display: block;
            }
        }
        .product-list-item {
            .value {
                font-weight: $font-weight-semibold;
            }
        }
    }
    .cart-attr-value {
        display: inline-block;
    }
    .header-delivery-options,
    .item-delivery-options {
        order: 6;
        text-align: left;
        min-width: 100%;

        @include media-breakpoint-down(xs) {
            flex-basis: 100%;
        }
        @include media-breakpoint-up (sm) {
            min-width: auto;
            order: 3;
        }
        @include media-breakpoint-up(lg) {
            width: 8rem;
        }
        .form-row {
            font-size: $cart-link-size;
            justify-content: flex-start;
            margin: 0;
        }
    }
    .item-delivery-options {
        flex: 0 1 15%;
        input[type="radio"] {
            flex: 0 0 18px;
            float: left;
            margin: 4px 0;
        }
        label {
            display: flex;
            flex: 0 0 calc(100% - 18px);
            margin-left: 18px;
        }
    }
    .header-item-quantity,
    .item-quantity {
        float: left; // LEGACY: to support IE (no flexbox)
        max-width: 40%;
        order: 4;
        text-align: center;

        @include media-breakpoint-down(md) {
            padding-left: 0;
        }

        @include media-breakpoint-up(sm) {
            max-width: 33%;
        }

        @include media-breakpoint-up(lg) {
            flex-basis: 11.5%;
            float: none;
            max-width: 11.5%;
        }

        input {
            padding-left: 12px;
            width: 45px;
        }
    }
    .header-quantity-details,
    .item-quantity-details {
        float: left; // LEGACY: to support IE (no flexbox)
        order: 5;
        text-align: right;

        @include media-breakpoint-up(lg) {
            float: none;
            text-align: left;
        }
        .product-availability-list,
        .item-user-actions {
            font-size: $cart-link-size;
            text-align: right;

            @include media-breakpoint-up(lg) {
                margin: 0 5px;
                text-align: left;
            }
        }
        .product-availability-list {
            margin-bottom: 5px;
            padding: 0;

            .not-available {
                color: $secondary;
                font-size: 0.9em;
            }

            .on-order {
                color: $green-msg;
                font-size: 0.9em;
            }
        }
        .item-user-actions {
            a,
            button {
                display: inline-block;
                font-size: 1em;
                margin-bottom: 5px;
                padding: 0;
                text-align: inherit;
                width: 100%;

                @include media-breakpoint-up(lg) {
                    width: auto;
                }
            }
        }
    }
    .header-indiv-price,
    .item-price {
        order: 6;
        text-align: right;
    }
    .header-item-shipping,
    .item-shipping {
        flex-basis: 55%;
        order: 5;
        text-align: right;

        @include media-breakpoint-up(md) {
            flex-basis: 38.5%;
        }

        select {
            background-color: transparent;
            border: 1px solid $body-color
        }
    }
    .price-promotion {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        .price-sales,
        .price-standard {
            margin: 0 5px;
            text-align: right;
            width: 100%;
        }
        .price-sales {
            color: $promo;
        }
        .price-standard {
            text-decoration: line-through;
        }
    }
    .header-total-price,
    .item-total {
        flex-basis: 11.5%;
        flex-grow: 2; // take over item-delivery-options that it is not there
        font-size: 1rem;
        font-weight: $font-weight-semibold;
        order: 7;
        text-align: right;
        word-wrap: break-word;
        @include media-breakpoint-up(lg) {
            float: none;
            font-size: 1em;
        }
        .promo-adjustment {
            @include media-breakpoint-up(lg) {
                width: 100%;
                float: right;
            }
        }
    }
    .price-unadjusted {
        color: $gray-600;
        display: block;
        margin: 0 0 .42em;
        span {
            text-decoration: line-through;
        }
    }
    .price-adjusted-total {
        color: $gray-600;
        display: block;
        margin: .42em 0 0;
        span {
            color: $promo;
        }
    }
    .gift-cert-delivery {
        color: $promo;
    }
}

.product-list-item {
    .sku,
    .attribute {
        font-size: .8125em;
    }
}

.cart-promo.desktop-only {
    display: none;
    @include media-breakpoint-up(md) {
        display: flex;
        flex-wrap: wrap;
        justify-content: sapce-between;
	    background: #EAF8ED;
	    color: $gray-800;
	    margin: 0.3em 0;
	    padding: map-get($grid-gutter-widths, xs);
	
	    td {
	        padding: inherit;
	    }
	
	    .bonus-item-details {
	        padding-bottom: 10px;
	        padding-top: 10px;
	        flex-basis: 70%;
	        max-width: 70%;
	        .bonusTextTop {
	            font-weight: 600;
	            font-size: 18px;
	            color: #34871b;
	        }
	        .bonusTextBottom {
	            font-weight: 600;
	        }
	    }
	    .bonus-item-actions {
	        font-size: 1em !important;
	        flex-basis: 30%;
	        max-width: 30%;
	        padding-top: 10px;
	        padding-bottom: 10px;
	        text-align: right;
	        vertical-align: middle;
	        a {
	            width: 50%;
	            text-align: center;
	        }
	    }
    }
}

.cart-promo.mobile-only {
    display: none;
    @include media-breakpoint-down(md) {
        display: block;
	    background: #EAF8ED;
	    color: $gray-800;
	    margin: 0.3em 0;
	    padding: map-get($grid-gutter-widths, xs);
	
	    td {
	        padding: inherit;
	    }
	
	    .bonus-item-details {
	        padding: 0px;
	        padding-bottom: 10px;
	        text-align: center;
	        .bonusTextTop {
	            padding-bottom: 10px;
	            color: #34871b;
	        }
	        .bonusTextBottom {
	            padding-bottom: 5px;
	        }
	    }
	    .bonus-item-actions {
	        font-size: 1em !important;
	        padding-top: 0;
	        text-align: right;
	        vertical-align: middle;
	        a {
	            width: 100%;
	            text-align: center;
	        }
	    }
	}
}

.cart-footer {
    @include clearfix();
    margin: .83em 0;
}

.cart-coupon-code {

    @include media-breakpoint-up(lg) {
        height:auto;
    }

    &__inputs {
        display: flex;
    }

    &__input {
        width: 100%;

        @include media-breakpoint-up(lg) {
            width: 45%;
        }
    }

    &__button {
        @include button-variant($primary, $primary);

        margin-left: map-get($spacers, 2);

        &.button-fancy-large {
            min-width: auto;
        }

    }

    .error {
        color: $danger;
        padding-top: 0.4em;
        font-size: $font-size-sm;
    }

}

.cart-order-totals {
    margin: 1rem 0;
    @include media-breakpoint-up(lg) {
        float: right;
        margin-top: 0;
        width: 40%;
    }
    .show-coupon {
        text-align: right;
        margin-bottom:1%;
    }
    .coupon-code {
        margin-bottom: 3%;

        >* {
            display:flex;
            justify-content: flex-end;
        }

        &.is-hidden {
            display:none;
        }
        &.is-active {
            display:block;
        }

        .error {
            color: $danger;
        }

        .coupon-line-items {
            margin-top:3%;
            .cartcoupon {
                display:inline-flex;
            }
        }


    }
    .reserveinventory-timer {
        width: 100%;
        float: right;
        @include media-breakpoint-up(sm) {
            max-width: 250px;
        }
    }
}

.order-totals-table {
    td {
        text-align: right;

    }
    .order-totals-item {
        width: 60%;
    }

    .order-totals-value {
        width: 40%;
    }

    .order-subtotal {
        font-weight: $font-weight-semibold;
    }
    .order-total {
        font-weight: $font-weight-semibold;
    }
}
.order-value {
    white-space: nowrap;
}

.cart-actions {
    @include clearfix();

    form,
    button {
        width: 100%;

        @include media-breakpoint-up(sm) {
            width: auto;
        }
    }
    form {
        margin-bottom: -20px;
    }
    .dw-apple-pay-button {
        font-size: 1.35em;
        padding: .5em 2em;
    }

    button {
        @include media-breakpoint-up(sm) {
            min-width: 250px;
        }
    }
}

.cart-action-checkout {
    float: none;

    @include media-breakpoint-up(sm) {
        float: right;
    }
}

.cart-action-continue-shopping {
    @include media-breakpoint-up(lg) {
        float: right;
    }
    .cart-action-continue-shopping__button {
        border-color: $dawn2;
        color: $flint;
        margin-right: 1rem;
    }
}

.cart-actions-top {
    display: none;
    margin-top: .5rem;
    margin-bottom: 1rem;

    .cart-order-total-top {
        margin-bottom: 5%;
    }

}


.cart-empty {
    @include clearfix();
    margin-top: 4rem;

    .cart-action-continue-shopping {
        float: none;
        text-align: center;
        margin-bottom: 4rem;
    }
    .product-listing {
        @include clearfix();
        background-color: $gray-100;
        padding: 1rem 0;
        h2 {
            margin-left: 1.3rem;
        }
        .search-result-items {
            margin-top: 1em;
        }
    }
    .empty-cart-login {
        max-width: 34rem;
        text-align: center;
        margin: 0 auto 4rem auto;

        h2 {
            font-size: 24px;
            font-weight: 400;
            @include media-breakpoint-up(lg) {
                font-size: $h1-font-size-lg;
            }
        }
        h3 {
            font-size: 1.25rem;
            margin-bottom: 1rem;
        }
    }
}
.checkout-button-submit {
    margin-bottom: map-get($spacers, 3);
}
.promo-adjustment {
    color: $promo;
}

.account-welcome {
    .welcome-message {
        font-size: 1.2em;
        font-style: italic;
    }
    .not-user {
        margin: .83em 0;
    }
}

#password-reset{
    white-space: nowrap;
}

.product-tile-cart {
	width: 100%;
    margin: auto;
}

#additional-product-cart {
	margin: 0 auto;
	width: 72%;

	@include media-breakpoint-down(sm) {

		.suggestions-right-group {
			float: none;
			width: 210px;
			margin-left: 5%;
			margin-right:20px;
		}

		.product-tile-cart .product-image {
			width: 50%;
			margin: 0 auto;
		}

		.product-tile {
			margin-left: 5%;
		}

		#cart-add-product {
		    width: 85%;
		}
    }

    @include media-breakpoint-up(sm) {

		.suggestions-right-group {
			float: right;
			width: 30%;
			margin-right:20px;
			margin-left: 0%;
		}

		.product-tile-cart .product-image {
			float: left;
		    width: 28%;
		}

		.product-tile {
			width: 60%;
			float:left;
			margin-left: 0%;
		}

		#cart-add-product {
		    width: 100%;
		}

    }

	.name-link-wrap {
		margin-bottom: 6px;
    	margin-top: 20px;
	}

	.c-product-item {
		background: #ffffff;
		float: left;
		width: 98%;
		margin: 0 1% 1%;
		padding-bottom: 2%
	}

	.c-product-tile-carousel__header {
		margin: 0;
		margin: 7px 1% 3px;
    	text-align: left;
    	font-weight: bold;
	}

	.product-tile-cart {
		width: 100%;
		margin: auto;
		background: #e1e1e1;
		float: left;
	}
	.product-swatches {
		margin-top: 1px;
	}

	.inventory {
	    float: left;
	    margin-top: 5%;
	    width: 60%;
	}

	.availability {
	    float: left;
	    margin-top: 25px;
	    margin-left: 10px;
	}

	#cart-add-product {
	    float: left;
	    margin-top: 5%;
	    padding-left: 0;
	    padding-right: 0;
	    clear: both
	}
}

#cart-items-form {
    clear: both;
    @include media-breakpoint-up(sm) {
        fieldset {
            margin-bottom: 0;
        }
    }
}

.oob-container {
    width: 100%;
    background: $gray-100;
    padding: 10px 20px;
    border: 2px solid $dawn2;
    display: flex;
    flex-direction: column;
    .header-oob {
        display: flex;
        flex-direction: column;
        padding-bottom: 15px;
        .title-text {
            font-weight: bold;
            padding-bottom: 15px;
        }
        .instructions {
            font-weight: lighter;
        }
    }
    .oob-table {
        display: flex;
        flex-direction: row;
        .oob-col-header {
            font-weight: bold;
        }
        .oob-col {
            flex-grow: 1;
            &.reason {
                flex-grow: 2;
            }
            &.discount {
                display: flex;
                flex-direction: column;
            }
            &.applybutton button {
                color: $white;
                background-color: #be1e24;
                border-color: #be1e24;
            }
            .invalid {
                border-color: $form-feedback-invalid-color;
                color: $form-feedback-invalid-color;
            }
        }
        span.error {
            color: #ed001c;
            font-size: 12px;
            font-weight: normal;
            letter-spacing: .5px;
        }
    }
}

#doordashTips {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #fff;
    display:inline-flex;
    border-left: 0.25px solid;
    border-top-right-radius:30px;
    border-bottom-right-radius:30px;
    border-top-left-radius:30px;
    border-bottom-left-radius:30px; 
    box-shadow: 0px 0px 0px 2px darkgrey;
    margin-bottom: 20px;
    margin-top: -100px;
 
  
  .doordashTip {
    float: left;
    border-right:0.5px groove #e0e0e0;
  }
  
  .doordashTip:last-child {
    border-right: none;
  }
  
  .doordashTip a {
    display: block;
    color: #111;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-weight: bold;
  }
  
  .doordashTip a:hover:not(.active) {
    background-color: #34871b;
    color:#fff;
    
  }
  .active {
    background-color: #34871b; 
    
  }
  
  .active a {
    color:#fff;
  
  }
  .doordash-other-tip { 
    padding-top: 10px;
    padding-bottom: 20px;
  }
}
   /* Chrome, Safari, Edge, Opera */
   input::-webkit-outer-spin-button,
   input::-webkit-inner-spin-button {
     -webkit-appearance: none;
     margin: 0;
   }

   .same-day-delivery-zip { 
    text-transform: uppercase;
   }

   .same-day-delivery-btn-submit {
    padding: 8px !important;
    margin-top: 8px !important;  
    width: 100px !important;  
   }

   .doordash-delivery-no-success-text {
    color: #be1e24 !important;
   }

   .shipping-option-details-topdiv {
       height:30%;
   }

.DoorDash_logo {
    @media (max-width: 769px) {
        max-width: 50% !important;
    }
}
