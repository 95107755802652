.pt_product-search-result,
.pt_content-search-result {
    .category-header,
    .folder-header {
        font-size: 24px;
        font-weight: 600;
        letter-spacing: -.2px;
        margin-bottom: 28px;
        padding-top: 6px;
        text-align: left;

        @include media-breakpoint-up(sm) {
            margin-bottom: 8px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 27px;
            letter-spacing: normal;
            margin-top: 10px;
        }
    }
}

.refinements {
    padding-bottom: map-get($grid-gutter-widths, xs);

    &#secondary {
        display: none;
        width: 100%;

        @include media-breakpoint-up(lg) {
            display: block;
            width: auto;
        }
    }
}



// for sticky refinements sidebar.
// This basicaly allows the primary region
// to define the page height. Solves problem of footer
// jump when search results lists get short.
@include media-breakpoint-up(lg) {
    .refinements {
        .sticky-elem-wrap {
            height: 100%;
            overflow: auto;
            position: absolute;
                top: auto;
                right: map-get($grid-gutter-widths, lg) / 2;
                bottom: auto;
                left: map-get($grid-gutter-widths, lg) / 2;
                z-index: $zindex-sticky-elem;
                .is-sticky {
                    max-height: calc(100vh - 50px - var(--header-height, 85px)) !important;
                }
            }
    }
}

#secondary {
    font-size: 14px;

    a {
        color: $link-color;
        text-decoration: underline;
    }
    .account-nav-asset{
        margin: 0;
    }
}

#category-level-1 {
    li {
        color: $gray-600;
        display: block;
        .refinement-link {
            background: none repeat scroll 0 0 transparent;
            padding: 0;
        }
        &.expandable {
            .refinement-link {
                padding: 0;
            }
            .active {
                font-weight: bold;
                padding: 0 0 0 .2em;
            }
        }
    }
}

#category-level-2 {
    a {
        &.active {
            color: $gray-800;
            font-weight: 700;
        }
        &.refinement-link {
            margin: 0 0 0 .4em;
            padding: 0;
        }
    }
}
#category-level-2 {
    padding: .5em .1em 0 .7em;
}
#category-level-3 {
    padding: .3em 0 0;
    a {
        &.refinement-link {
            font-style: normal;
            margin: 0 0 0 .9em;
            padding: 0;
        }
    }
}

.refinement-header {
    background-color: $gray-100;
    border-top: 1px solid $gray-200;
    display: block;
    font-size: 1.1em;
    font-weight: bold;
    letter-spacing: 0.125em;
    margin: 0;
    padding: 1.15em .75em .75em;
    text-transform: uppercase;
}

.nav {
    .secondary-navigation,
    .refinement {
        ul {
            margin: -7px 0 0;

            li {
                margin-bottom: 0;
            }
        }
    }
}
#secondary {
    margin-bottom: map-get($spacers, 4);

    p {
        line-height: 1.7;
    }

    .secondary__header,
    .c-accordion__header {
        font-style: normal;
        margin: 0;
        padding: 14px 0;

        @include media-breakpoint-up(lg) {
            padding: 15px 0;
        }
    }
}
.secondary-navigation,
.refinement {
    li {
        padding: 6px 0;

        @include media-breakpoint-up(lg) {
            padding: 7px 0;
        }
    }
    .secondary__header,
    .c-accordion__header {
        font-weight: normal;
        letter-spacing: normal;
        padding: 16px 0 !important;
        text-transform: none;

        @include media-breakpoint-up(lg) {
            padding: 18px 0 !important;
        }
    }
    .c-accordion__header {
        font-size: 17px;
        font-weight: 500;
    }

    .c-accordion__body {
        font-size: 15px;
        font-weight: 300;
    }
}
.secondary-navigation {
    margin-bottom: 16px;

    .secondary__header {
        &:first-child {
            padding-top: 11px;
        }
    }
}
.refinement {
    position: relative;

    .size {
        &.swatches{
            border-left: 1px solid $gray-300;
            border-top: 1px solid $gray-300;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 7px;
            margin-top: 10px;
            max-width: 246px;
            overflow: visible;
            padding-top: 0;

            .swatch {
                border-bottom: 1px solid $gray-300;
                border-right: 1px solid $gray-300;
                flex: 0 1 49px;
                height: 49px;
                margin: 0;
                max-width: 49px;

                &.selected {
                    border: 2px solid;
                    flex-basis: 50px;
                    height: 50px;
                    margin-left: -1px;
                    margin-top: -1px;
                    max-width: 50px;
                }
                &.unselectable {
                    a {
                        background-color: #f5f5f5;
                        background-image: none;
                        color: #a6a6a6 !important;
                    }
                }
                a {
                    align-items: center;
                    display: flex;
                    height: 100%;
                    justify-content: center;
                    width: 100%;
                }
            }
        }
    }
    // Hide Refinements on Category Landing Pages
    .pt_categorylanding & {
           display: none;
           &.category-refinement {
               display: block;
           }
       }
    h3 {
        font-size: 1.1em;
        letter-spacing: 0.125em;
        margin: 0 0 .65em;
        padding: 1.15em .75em .75em;
        text-transform: uppercase;
        .ie7 & {
            clear: both;
        }
    }
    ul {
        list-style: none outside none;
        margin: 0;
        padding: 3px 0 0;
    }
    ul {
        li {
            i {
                color: $gray-200;
            }
        }
        &.swatches li {
            margin-bottom: 1px;
            padding: 0;
            vertical-align: top;

            &.unselectable a {
                background-image: url("../images/interface/icon-color-swatch-unselectable.png");
                background-position: right top;
            }
        }

        &.refinementColor,
        &.color {
            li a {
                background-clip: padding-box;
                border-color: #fff;
                border-width: 3px;
                box-shadow: inset 0 0 3px 1px rgba(0,0,0,0.2);
                display: inline-block;
                height: 38px;
                position: relative;
                vertical-align: top;
                width: 38px;
            }
            .unselectable {
                border-color: transparent;

                a {
                    overflow: hidden;

                    &::before,
                    &::after {
                        content: '';
                        height: 100%;
                        position: absolute;
                        top: 0;
                    }
                    &::before {
                        background-color: rgba(127, 127, 127, .35);
                        border: 2px solid rgba(0, 0, 0, 0.22);
                        border-radius: inherit;
                        left: 0;
                        width: 100%;
                        z-index: 2;
                    }
                    &::after {
                        background-color: rgba(0, 0, 0, 0.22);
                        height: 100%;
                        left: 50%;
                        transform: translateX(-50%) rotateZ(45deg);
                        width: 2px;
                        z-index: 3;
                    }
                }
            }
        }
    }

    a {
        color: $body-color !important;
        text-decoration: none !important;
    }

    .refinement-options {
        display: flex;
        justify-content: flex-end;
    }

    .clear-refinement {
        flex: 0 0 50%;
        font-size: 14px;
        font-weight: 300;
        padding: 0 0 0 1.05em;
        text-align: right;

        a {
            &::after {
                @include background-svg($svg-modal-close);
                background-size: cover;
                content: '';
                display: inline-block;
                height: 16px;
                margin-left: 8px;
                position: relative;
                    top: 3px;
                width: 16px;
            }
        }
    }
    .refinement-link {
        border: 0 none;
    }
    .refinement-link-checkbox {
        &::before {
            border: 1px solid $gray-300;
            content: '';
            display: inline-block;
            height: 17px;
            margin-right: 6px;
            vertical-align: middle;
            width: 17px;
        }
    }
    .selected {
        .refinement-link-checkbox {
            &::before {
                @include background-svg($svg-checkbox-filled);

                background-size: contain;
                border-color: $primary;
            }
        }
    }
    .refinement-top-level {
        font-weight: $font-weight-bold;
        padding: 7px 0;

        &__view-all {
            font-size: 12px;
        }
    }
    .refinement-see-all {
        flex: 0 0 50%;
        text-align: left;

        button {
            border: none;
            color: inherit;
            padding-bottom: 0;
        }
    }

    &.c-accordion {
        .c-accordion__item {
            &:first-of-type {
                border-top: none;
            }
            &.is-expanded {
                .c-accordion__body {
                    margin-top: -7px;
                    padding: 0 0 14px;
                    position: relative;
                }
            }
        }
    }
    .c-accordion__body {
        padding-left: 0;
        padding-right: 0;

        &.c-accordion__see-all {
            ul {
                max-height: inherit !important;
            }
            .refinement-see-all__see-all {
                display: none;
            }
            .refinement-see-all__hide-extra {
                display: inline;
            }
        }
        li {
            padding-left: 0;
            padding-right: 0;
        }
        &.has-cutoff {
            ul {
                max-height: 222px; //Fallback, equivalent to --cutoff equals 6
                max-height: calc(12px + 35px * var(--cutoff, 5));
                overflow: hidden;
                transition: max-height .15s;
            }
        }
        .refinement-see-all__see-all,
        .refinement-see-all__hide-extra {
            &::before {
                display: inline-block;
                font-size: 18px;
                font-weight: bold;
                line-height: 1;
                margin-right: 7px;
                vertical-align: top;
            }
        }
        .refinement-see-all__see-all {
            &::before {
                content: '+';
            }
        }
        .refinement-see-all__hide-extra {
            display: none;

            &::before {
                content: '-';
            }
        }
    }
}

.mobile-refinement-filter {
    &.search-result-options {
        padding: 0;
    }
    @include media-breakpoint-down(md) {
        .has-sticky-header & {
            -webkit-transform: translate3d(0, 0, 0); // forces hardware acceleration. Helps stop flickering of fixed positioned elements.
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            z-index: 5;
            width: 100%;
            margin-top: 75px;
            background: white;
            padding:60px 20px 20px 20px;
            .total-items {
                right: 20px;
                top: 0px;
            }
        }
    }

    .mobile-refinement-filter__c-accordion__item {
        border: none !important;
        display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            justify-content: space-between;

        @include media-breakpoint-up(lg) {
            display: block;
        }

        &.is-expanded {
            .mobile-refinement-filter__c-accordion__header {
                @include background-svg($svg-accordion-up);
                background-position: right center;
                border-color: $primary;
            }
            .mobile-refinement-filter__c-accordion__body {
                max-height: 70vh !important;
                overflow: auto;
                padding-top: 8px;
            }
        }
    }

    .mobile-refinement-filter__c-accordion__header {
        @include background-svg($svg-dropdown-arrow);
        align-items: center;
        background-position: calc(100% - 10px) center;
        border: 1px solid $input-border-color;
        display: block;
        font-size: 15px;
        font-weight: 300;
        justify-content: flex-end;
        line-height: normal;
        padding: 9px 12px 6px 12px !important;
        text-align: left;
        text-transform: none;
        width: calc(50% - 10px);

        span {
            margin-right: 20px;
        }
    }

    .mobile-refinement-filter__c-accordion__body {
        width: 100%;
    }

    .refinements {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &.search-result-options {
        .sort-by {
            font-size: 12px;
            display: block;
            padding-bottom: 0;
            width: calc(50% - 10px);

            @include media-breakpoint-up(lg) {
                width: auto;
            }
        }

        fieldset {
            margin-bottom: 0;
        }
    }
}

#mobile-refinements {
    width: 100%;
}

.scrollable {
    height: 10em;
    overflow: auto;
}

.nav {
    ul {
        border: 0 none;
        list-style: none outside none;
        margin: .58em 0;
        padding: 0;
        li {
            list-style: none outside none !important;
            margin: 0 0 .58em;
        }
    }
    .toggle {
        border: 0 none;
        display: block;
        font-size: 1em;
        font-weight: 700;
        margin: .83em 0 .58em;
        text-transform: uppercase;
        span {
            background: none repeat scroll 0 0 transparent;
        }
    }
    .expanded {
        background-position: left -96px;
    }
}
