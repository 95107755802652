/* ============================================================================
   Slick Slider
   ============================================================================ */

$ns: s-slick-slider;

.#{$ns} {
    display: block;
    opacity: 0;
    visibility: hidden;
    transition: opacity .25s ease;

    &.slick-initialized {
        opacity: 1;
        visibility: visible;
    }

    .slick-arrow {
        background-position: center center;
        height: 100%;
        position: absolute;
            z-index: 100;
            top: 50%;
        transform: translateY(-50%);
        width: 76px;

        @include media-breakpoint-up(sm) {
            width: 98px;
        }

        @include media-breakpoint-up(md) {
            width: 120px;
        }
    }

    .slick-prev {
        @include background-svg($svg-arrow-prev);
        left: 0;
    }

    .slick-next {
        @include background-svg($svg-arrow-next);
        right: 0;
    }


}
