.search-suggestion-wrapper {
    background-color: $body-bg;
    box-shadow: 0 20px 40px -20px rgba(77, 77, 77, 0.3), inset 0 -3px 0 0 $primary;
    color: $body-color;
    font-size: 13px;
    max-height: calc(100vh - 165px);
    overflow: auto;
    position: absolute;
        left: 0;
        top: 100%;
        z-index: 1;
    width: 100%;

    @include media-breakpoint-up(sm) {
        top: calc(100% + 1px);
    }
    @include media-breakpoint-up(lg) {
        max-height: calc(100vh - 175px);
        max-width: 550px;
        left: auto;
    }

    a {
        &, &:hover {
            color: inherit;
        }
    }

    .phrase-suggestions,
    .product-suggestions {
        padding: map-get($spacers, 4) map-get($grid-gutter-widths, xs) map-get($spacers, 5) map-get($grid-gutter-widths, xs) + 8px;

        @include media-breakpoint-up(lg) {
            padding-left:  map-get($grid-gutter-widths, lg);
        }

    }
    .product-suggestions {
        &__submit {
            background-color: transparent;
            border: none;
            color: $primary;
            font-size: 14px;
            letter-spacing: .4px;
            margin-bottom: 6px;
            padding-left: 2px;

            @include media-breakpoint-up(sm) {
                margin-bottom: 0;
            }
        }
    }

    &.full {

        @include media-breakpoint-up(sm) {
            display: flex;
                flex-wrap: wrap;
        }

        @include media-breakpoint-up(lg) {
            border: 0;
        }

        .phrase-suggestions {
            border-right: 1px solid #e5e5e5;
            margin-bottom: map-get($spacers, 5);
            padding: map-get($spacers, 2) map-get($grid-gutter-widths, sm) 0 map-get($grid-gutter-widths, sm);

            @include media-breakpoint-up(sm) {
                order: 1;
                padding: map-get($spacers, 4) map-get($grid-gutter-widths, xs) / 2 0 map-get($grid-gutter-widths, xs) + 8px;
                width: 50%;
            }

            @include media-breakpoint-up(lg) {
                border-top: 0;
                padding: map-get($spacers, 4) map-get($grid-gutter-widths, lg) / 2 0 map-get($grid-gutter-widths, lg);
                float: left;
            }

        }

        .product-suggestions {
            padding: 34px map-get($grid-gutter-widths, sm) map-get($spacers, 3)  map-get($grid-gutter-widths, sm);

            @include media-breakpoint-up(sm) {
                order: 2;
                padding: map-get($spacers, 4) map-get($grid-gutter-widths, xs) map-get($spacers, 5) map-get($grid-gutter-widths, xs) / 2;
                width: 50%;
            }

            @include media-breakpoint-up(lg) {
                padding: calc(#{map-get($spacers, 4)} + 3px) map-get($grid-gutter-widths, lg) map-get($spacers, 5) map-get($grid-gutter-widths, lg) / 2;
                float: right;
            }

        }
    }

    .hitgroup {
        margin-bottom: 22px;

        @include media-breakpoint-up(sm) {
            margin-bottom: 29px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        .header {
            font-size: 16px;
            font-weight: 500;
            letter-spacing: normal;
            margin-bottom: 18px;

            @include media-breakpoint-up(sm) {
                margin-bottom: 6px;
            }
        }

        .hit {
            border-bottom: 1px solid $primary;
            color: $primary;
            display: inline-block;
            font-size: 14px;
            line-height: 1;
            margin-bottom: 22px;
            text-decoration: none;
            transition: border-color .2s;

            @include media-breakpoint-up(sm) {
                margin-bottom: 9px;
            }

            &:hover {
                border-color: $body-color;
            }

        }

    }

    .search-phrase {
        font-size: 14px;
        line-height: 1;
        margin-bottom: 2px;
        padding-left: map-get($grid-gutter-widths, sm);
        padding-right: map-get($grid-gutter-widths, sm);
        padding-top: 10px;
        width: 100%;

        @include media-breakpoint-up(sm) {
            font-size: 14px;
            padding-left: map-get($grid-gutter-widths, xs);
            padding-right: map-get($grid-gutter-widths, xs);
            padding-top: 24px;
        }
        @include media-breakpoint-up(lg) {
            padding-left: map-get($grid-gutter-widths, lg);
            padding-right: map-get($grid-gutter-widths, lg);
        }

        a {
            border-bottom: 1px solid $gray-300;
            color: $primary;
            display: inline-block;
            text-decoration: none;
            transition: border-color .2s;
            vertical-align: top;

            &:hover {
                border-color: $body-color;
            }
        }

    }

}

.product-suggestion {
    margin-bottom: 30px;

    &:last-of-type {
        margin-bottom: 5px;
    }

    @include media-breakpoint-up(sm) {
        margin-bottom: 24px;

        &:last-of-type {
            margin-bottom: 16px;
        }
    }

    .product-link {
        display: flex;
    }

    .product-image {
        flex: 0 0 52px;
        max-width: 52px;

        img {
            max-width: 100%;
        }

    }

    .product-details {
        padding-left: 14px;

        .product-brand {
            font-size: 12px;
            font-weight: $font-weight-semibold;
        }
        .product-price {
            font-size: 12px;
            .price-standard {
                text-decoration: line-through;
            }
            .percent-savings {
                display: none;
            }
        }

    }

}
