.ui-widget-overlay {
    background-color: rgba(#333, .8);
    bottom: 0;
    position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: $zindex-modal - 1 !important;
}

.ui-dialog {
    background-color: $body-bg;
    box-shadow: 0 50px 60px -30px rgba(0, 0, 0, 0.7);
    font-size: 15px;
    font-weight: 300;
    height: calc(100vh - #{map-get($spacers, 5) * 2}) !important;
    max-width: calc(100vw - #{map-get($grid-gutter-widths, xs)});
    overflow: hidden;
    position: fixed !important;
        top: 50% !important;
        left: 50% !important;
        z-index: $zindex-modal !important;
    transform: translate(-50%, -50%);
    width: 90vw !important;
    &.weChatBarCode {
        top: 300px !important; 
    }
    @include media-breakpoint-up(md) {
        max-height: auto !important;
        max-width: 1000px ;
        width: calc(100vw - #{map-get($grid-gutter-widths, lg)});
    }

    @media (min-width: map-get($grid-breakpoints, md)) and (min-height: 754px) {
        height: 675px !important;
    }

    // modifier to allow dialog to better fit it's content
    &--relax {
        height: auto !important;

        @include media-breakpoint-up(sm) {
            width: auto !important;
        }

    }
    &#{&}--password-reset {
        .ui-dialog-content {
            padding-top: 0;
        }
    }
    &--password-reset {
        padding-bottom: 30px;
    }

    .ui-widget-header {
        border: 0 none;
        border-radius: 0 0 0 0;
    }
    .ui-dialog-title {
        color: inherit;
        font-weight: inherit;
    }
    .ui-icon-closethick {
        background-image: url("../images/interface/icon-ui-close.png");
        background-position: left top;
        height: 32px;
        width: 32px;
        left: 0;
        top: 0;
        margin-left: 0;
        margin-top: 0;
    }
    .ui-dialog-titlebar-close {
        @include background-svg($svg-modal-close);
        @include size(40px);
        background-color: transparent;
        background-position: center;
        background-repeat: no-repeat;
        border: 0 none;
        border-radius: 0 0 0 0;
        cursor: pointer;
        margin: 0;
        padding: 0;
        position: absolute;
            right: 10px;
            top: 10px;
            z-index: 1;

        span {
            margin: 0;
            padding: 0;
        }
    }

    .ui-dialog-content {
        padding:  map-get($grid-gutter-widths, xs);

        @include media-breakpoint-up(lg) {
            padding:  20px 50px 0;
        }
    }

    .ui-widget-content {
        &.ui-dialog-buttonpane {
            padding: 0;
            padding-top: map-get($spacers, 5);
            padding-bottom: 50px;
        }

    }
}

.ui-dialog-titlebar {
    color: $primary;
    font-size: 33px;
    font-weight: bold;
    line-height: 1.05;
    letter-spacing: normal;
    padding: 55px map-get($grid-gutter-widths, xs) 30px;
    position: relative;
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
        padding-right: 50px;
        padding-left: 50px;
    }

    &::after {
        content: "";
        background-color: $primary;
        display: block;
        height: 4px;
        position: absolute;
            left: auto;
            bottom: 15px;
        width: 39px;
    }

    &--empty {
        padding-bottom: 0;
        line-height: 0;

        &::after {
            display: none;
        }
    }

}
.no-close {
    .ui-dialog-titlebar-close {
        display: none;
    }
}

.ui-dialog-content {
    height: calc(100% - 99px) !important;
    height: calc(100% - var(--dialog-titlebar-height)) !important;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100% !important;

    .pdp-main .pdp-main-row {
        margin: 0;
    }
}

.ui-accordion .ui-accordion-content {
    padding: 0;
}

.ui-tabs {
    .ui-tabs-nav {
        height: 2.45em;
        padding: 0;
        li {
            border-color: $body-bg;
            border-style: solid;
            border-width: 0 0 3px 0;
            &.ui-state-active {
                border-bottom-color: $body-color;
                a {
                    color: $body-color;
                    font-weight: 700;
                }
            }
            a {
                color: $gray-500;
            }
        }
    }
    .ui-tabs-panel {
        background-color: $body-bg;
        border: 1px solid $body-bg;
        line-height: 1.5em;
        margin: 0;
        padding: 20px 10px;
        h3 {
            @media screen and (min-width: 768px) {
                display: none;
            }
        }
    }
}
