$ns: "c-full-width-banner";

.#{$ns} {
    $eyebrow-border-offset: 7px;
    $font-size: 16px;

    // Inheritable border color without giving element a border
    border-color: $black;
    border-width: 0;
    font-family: $font-family-sans-serif;
    margin-bottom: map-get($spacers, 5);
    margin-right: auto;
    margin-left: auto;
    overflow: hidden;
    position: relative;

    @include media-breakpoint-up(lg) {
        height: 500px;
    }
    @include media-breakpoint-up(xxl) {
        height: 750px;
    }

    &--center {
        text-align: center;

        .#{$ns} {
            &__content {
                @include media-breakpoint-up(md) {
                    display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;
                    max-width: 100%;
                    text-align: center;
                    width: 100%;
                }
            }
            &__heading,
            &__eyebrow {
                @include media-breakpoint-up(md) {
                    width: 844px;
                }
            }
            &__heading {
                @include media-breakpoint-up(md) {
                    border-color: inherit;
                    border-style: solid;
                    border-width: 3px;
                    border-bottom: none;
                    margin-bottom: 0;
                    padding: 28px 38px 20px;
                }
            }
            &__eyebrow {
                @include media-breakpoint-up(md) {
                    display: flex;
                    margin-top: $eyebrow-border-offset * -1;
                }

                &__inner {
                    @include media-breakpoint-up(md) {
                        flex: 0 1 auto;
                        padding-left: 25px;
                        padding-right: 25px;
                    }
                }

                &::before,
                &::after {
                    @include media-breakpoint-up(md) {
                        border-top-color: inherit;
                        border-top-style: solid;
                        border-top-width: 3px;
                        content: '';
                        flex: 1 1 auto;
                        margin-top: $eyebrow-border-offset;
                        min-width: 60px;
                    }
                    @include media-breakpoint-up(lg) {
                        min-width: 80px;
                    }
                }
            }
            &__text {
                @include media-breakpoint-up(md) {
                    max-width: 660px;
                }
            }
            &__buttons {
                justify-content: center;

                @include media-breakpoint-up(md) {
                    max-width: 680px;
                }
            }
        }
    }
    &--right {
        @include media-breakpoint-up(md) {
            text-align: right;
        }

        .#{$ns} {
            &__content {
                @include media-breakpoint-up(md) {
                    left: auto;
                    right: 0;
                    text-align: right;
                }
            }
            &__buttons {
                @include media-breakpoint-up(md) {
                    justify-content: right;
                }
            }
        }
    }
    * {
        font-family: inherit;
    }
    &__background {
        &--poi {
            height: 464px;
            position: relative;

            @include media-breakpoint-up(md) {
                height: auto;
            }
        }

        &__pic,
        &__pic__img {
            display: block;
            height: auto;
            min-height: 100%;
            min-width: 100%;
            width: auto;

            @include media-breakpoint-up(lg) {
                min-height: 500px;
                max-width: none;
            }
            @include media-breakpoint-up(xxl) {
                min-height: 750px;
            }
        }
        // Create strong selector
        & &__pic {
            &--poi {
                width: 768px;

                @include media-breakpoint-up(md) {
                    width: 100%;
                }
            }
        }
        &__pic {
            &--poi {
                position: absolute;
                    left: 50%;
                    top: 0;
                transform: translateX(-50%);

                @include media-breakpoint-up(md) {
                    position: static;
                    transform: none;
                }
            }

            & &__img {
                width: auto;

                @supports (object-fit: cover) {
                    @include media-breakpoint-up(lg) {
                        height: 500px;
                        object-fit: cover;
                        width: 100%;
                    }
                    @include media-breakpoint-up(xxl) {
                        height: 750px;
                    }
                }
            }
        }
    }
    &__content {
        max-height: 100%;
        max-width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 15px;
        position: absolute;
            left: 50%;
            top: 50%;
            z-index: 1;
        text-align: center;
        transform: translate(-50%, -50%);
        width: 810px;

        @include media-breakpoint-up(md) {
            left: 0;
            max-width: 66%;
            padding-bottom: 0;
            padding-left: 64px;
            padding-bottom: 10px;
            text-align: left;
            transform: translateY(-50%);
        }
        @include media-breakpoint-up(lg) {
            max-width: 54%;
            padding-left: 80px;
            padding-right: 80px;
        }
    }
    &__eyebrow,
    &__heading {
        text-transform: uppercase;
    }
    &__eyebrow {
        font-size: 13px;
        font-weight: $font-weight-semibold;
        letter-spacing: 1.5px;
        line-height: normal;
        margin-bottom: 9px;

        @include media-breakpoint-up(md) {
            font-size: 15px;
            font-weight: $font-weight-bold;
            line-height: 1.4;
            margin-bottom: 13px;
        }
    }
    &__heading {
        font-size: em(33px, $font-size);
        font-weight: bold;
        line-height: 1;
        margin-bottom: 10px;

        @include media-breakpoint-up(md) {
            font-size: em(60px, $font-size);
            letter-spacing: em(-1px, 60px);
            line-height: 1.05;
            margin-bottom: 16px;
        }
    }
    &__text {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.31;
        margin-bottom: 28px;
        max-width: 640px;

        @include media-breakpoint-up(md) {
            font-size: 18px;
            font-weight: 400;
            line-height: 1.5;
            margin-bottom: 25px;
        }
    }
    &__buttons {
        display: flex;
            flex-wrap: wrap;
            justify-content: center;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;

        @include media-breakpoint-up(md) {
            flex-wrap: nowrap;
            justify-content: left;
            margin-left: -10px;
            margin-right: -10px;
            max-width: 660px;
            padding-left: 0;
            padding-right: 0;
            width: calc(100% + 20px);
        }
    }
    &__button {
        @include button-variant($secondary, $secondary, $bright-red, $bright-red);

        border: none;
        display: inline-block;
        flex: 0 0 100%;
        font-size: 14px;
        font-weight: $font-weight-semibold;
        letter-spacing: 1.5px;
        margin-bottom: 12px;
        padding: 15px 32px 12px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
            flex: 1 1 calc(50% - 20px);
            margin-left: 10px;
            margin-right: 10px;
            max-width: 280px;
        }
        @include media-breakpoint-up(lg) {
            flex: 1 1 calc(33.3% - 20px);
        }

        &:hover {
            text-decoration: none;
        }
    }

    &__carousel-wrap {
        .slick-arrow {
            margin:6% 2%;
            @include media-breakpoint-down(md) {
                margin: 15% 4%;
            }
        }
        .slick-dots {
            bottom: 14px;
            top: auto;

            li {
                background-color: rgba(177, 177, 177, 1);
                cursor: pointer;
            }
            .slick-active {
                background-color: $white;
            }
        }
    }
    &__carousel {
        margin-bottom: 0;
        vertical-align: top;

        &.u-full-bleed {
            margin-left: 0;
            width: 100%;
        }
    }
}
