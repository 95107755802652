#wrapper {
    border: 0;
    left: 0;
    position: relative;
    transition: left 0.2s ease-in;

    &.menu-active {
        left: 50%;
    }

    @include media-breakpoint-up(lg) {
        &.menu-active {
            left: auto;
        }
    }
}

#main {
    @include make-container();
    @include make-container-max-widths();
    margin: auto;

    &.full-width {
        @include media-breakpoint-up(lg) {
            margin-top: 20px;
        }
    }

    .main-row {
        @include make-row();

        &--reverse {
            @include media-breakpoint-up(lg) {
                flex-direction: row-reverse;
            }
        }
        &--mobile-column-reverse {
            flex-direction: column-reverse;

            @include media-breakpoint-up(lg) {
                flex-direction: row;
            }
        }

    }

    .pt_product-details & {
        margin-right: 0;
        margin-left: 0;
        max-width: none;
        padding: 0;

        .breadcrumb,
        .pdp-main-container {
            @include make-container();
            @include make-container-max-widths();
        }

        .breadcrumb {
            padding-left: 20px;
            padding-right: 20px;

            @include media-breakpoint-down(xs) {
                line-height: .9
            }

            @include media-breakpoint-up(lg) {
                padding-left: map-get($grid-gutter-widths, lg);
                padding-right: map-get($grid-gutter-widths, lg);
            }

            &-element {
                font-size: 11px;
                letter-spacing: 1px;
                text-transform: uppercase;
            }
        }
    }

    &.full-bleed {
        max-width: none;
        padding-right: 0;
        padding-left: 0;
    }

}

#primary {
    max-width: 100%;
    width: 100%;
    .pt_product-details & {
        padding-left: 0;
        padding-right: 0;
    }

    @include make-col-ready();

    @include media-breakpoint-up(lg) {
        @include make-col(9);

        .full-width & {
            @include make-col(12);
        }
    }

}

#secondary {
    padding: 0 10px;
    width: 100%;
    @include make-col-ready();

    @include media-breakpoint-up(lg) {
        @include make-col(3);

        min-width: 0;

        nav {
            a {
                display: block;
            }
        }
        .primary-focus & {
            background: none;
            border: none;
        }
    }

    .primary-focus & {
        margin-left: auto;
        margin-right: auto;
    }

    // Remove Refinements for responsive design
    .pt_product-search-result &,
    .pt_content-search-result &,
    .pt_order & {
        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
        }
    }

}
.pt_customer-service.s-contact-us {
    .main-row {
        flex-direction: column-reverse;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
        }
    }
}
.pt_product-search-noresult {
    .main-row {
        flex-direction: column;
    }
}
.pt_customer-service {
    &.s-contact-us {
        .confirmation-message {
            padding-left: map-get($grid-gutter-widths, xs);
            padding-right: map-get($grid-gutter-widths, xs);
        }
    }
}
