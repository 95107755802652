$ns: st-footer;

.#{$ns} {
    background-color: $gray-100;
    color: $black;
    font-family: $font-family-sans-serif;
    position: relative;
    min-height: 700px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    @include media-breakpoint-up(lg) {
        z-index: $zindex-footer; // required for sticky refinements sidebar
    }

    &__wrapper {
        @include make-container();
        @include make-container-max-widths();
        padding-left: 20px;
        padding-top: 40px;
        position: relative;

        @include media-breakpoint-up(lg) {
            padding-top: 50px;
            position: initial;
        }
    }

    &__col {
        @include media-breakpoint-up(lg) {
            &-last {
                padding-left: 0;
            }
        }
    }

    &__border {
        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
        }

        &-line {
            background-color: $border-color;
            height: 100%;
            margin: 0 auto;
            width: 1px;
        }
    }

    &__leaderboard {
        margin-bottom: 30px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 40px;
        }

        &-text {
            font-size: 16px;
            line-height: 1.38;
            margin-bottom: 20px;
        }

        .content-asset {
            @include media-breakpoint-up(lg) {
                max-width: 280px;
            }
        }

        &-heading {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 15px;
            line-height: normal;
            letter-spacing: -0.2px;
        }
    }

    &__social {
        margin-bottom: 30px;

        &-heading {
            font-size: 16px;
            font-weight: 500;
            line-height: 2.06;
            letter-spacing: normal;
            margin-top: 0;
        }

        &-links {
            display: flex;
            list-style: none;
            margin: 0 !important;
            padding: 0 !important;
        }

        &-link {
            margin-right: 18px;

            @include media-breakpoint-up(lg) {
                margin-right: 15px;
            }
        }

        &-svg {
            color: $primary;
            fill: $primary;
            height: 42px;
            width: 42px;

            @include media-breakpoint-up(lg) {
                height: 36px;
                width: 36px;
            }

            &:hover {
                color: $dell;
                fill: $dell;
            }
        }

    }

    &__menu {
        .content-asset-title {
            display: none;
        }

        & &__item {
            &:first-of-type {
                border-top: none;
            }
        }

        & &__header {
            background-image: none !important;
            font-size: 13px;
            font-weight: 500;
            line-height: 2.54;
            letter-spacing: 1px;
            padding-top: 10px;
            padding-bottom: 10px;
            position: relative;
            text-transform: uppercase !important;

            &::before,
            &::after {
                content: '';
                background-color: $primary;
                height: 12px;
                position: absolute;
                    right: 0;
                    top: 50%;
                width: 12px;
            }

            &::before {
                top: calc(50% - 5px);
                right: 5px;
                width: 2px;
            }

            &::after {
                height: 2px;
            }

            @include media-breakpoint-up(lg) {
                margin-bottom: 15px;
                padding-top: 0;

                &::before,
                &::after {
                    display: none;
                }
            }
        }

        .is-expanded &__header {
            &::before {
                display: none;
            }
        }

        &__ul {
            list-style: none;
            margin: 0 !important;

            @include media-breakpoint-up(lg) {
                padding: 0 !important;
            }

            &-li {
                font-size: 14px;
                font-weight: 300;
                line-height: 1.54;
                line-height: 1.43;

                & + & {
                    margin-top: 10px;

                    @include media-breakpoint-up(lg) {
                        margin-top: 20px;
                    }
                }

                a {
                    color: $black;
                }
            }
        }
    }

    &__payments {
        padding-top: 20px;
        max-width: 230px;

        @include media-breakpoint-up(lg) {
            border-top: 1px solid $border-color;
            margin-top: 30px;
        }

        &-ul {
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            margin: 0 !important;
            padding: 0 !important;
        }

        &-svg {
            color: $flint;
            cursor: pointer;
            fill: $flint;
            height: 28px;
            margin-right: 10px;
            margin-bottom: 15px;
            width: 46px;

            &.visa-checkout {
                height: 25px;
                width: 86px;
            }

            &:hover {
                color: $primary;
                fill: $primary;
            }
        }

        .content-asset-title {
            display: block;
            font-size: 16px;
            font-weight: 500;
        }

    }

    &__copy {
        color: $black;
        font-family: $font-family-sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.67;
        letter-spacing: 0.2px;
        margin-top: 20px;
        width: 200px;

        @include media-breakpoint-up(lg) {
            position: absolute;
                bottom: 30px;
                left: 0;
            text-align: center;
            width: 100%;
        }

        .content-asset-title {
            display: none;
        }
    }

    &__img {
        margin-top: -185px;
        width: 100%;

        @include media-breakpoint-up(lg) {
            display: block;
            margin-top: -160px;
        }
    }

    .st-footer {
        background-color: #f4f2f1;
        color: #000;
        font-family: hind,sans-serif;
        position: relative;
        background-image: linear-gradient(180deg, rgb(244, 242, 241), rgb(244, 242, 241), rgba(244, 242, 241, 80%), rgba(244, 242, 241, 50%), transparent), url(../images/footer-desktop_updater1.jpg);
        min-height: 700px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
}
