$ns: "c-event-detail";

.#{$ns} {
    $max-width: 660px;

    &__heading {
        border-bottom: 1px solid $border-color;
        font-size: 27px;
        font-weight: 600;
        letter-spacing: normal;
        line-height: normal;
        margin: 0;
        margin-bottom: 23px;
        max-width: $max-width;
        padding-bottom: 4px;
        text-align: left;
    }

    &__summary {
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.5;
        letter-spacing: normal;
        margin-bottom: 32px;

        &__location,
        &__date,
        &__time {
            margin: 0;
        }

        &__location {
            font-weight: bold;
        }

        &__date {

        }

        &__time {

        }

    }

    &__body {
        max-width: $max-width;

        @include media-breakpoint-up(lg) {
            margin-bottom: 6rem;
        }

        h3 {
            font-weight: 600;
            margin-bottom: 4px;

            + p {
                margin-bottom: 1.15rem;
            }

        }

        img {
            margin-bottom: 2.2rem;
        }

        p,
        ul {
            font-size: $font-size-base;
            font-weight: 300;
            line-height: 1.56;
            margin: 0;
            margin-bottom: 1.7rem;
        }

        ul {
            list-style: none;
            padding: 0;

            li {
                margin-bottom: $font-size-base;
                padding-left: 20px;
                position: relative;

                &::before {
                    content: '';
                    background-color: $green-haze;
                    border-radius: 6px;
                    height: 6px;
                    position: absolute;
                        left: 0;
                        top: 8px;
                    width: 6px;
                }
            }
        }

        hr {
            margin: 32px 0 35px;
        }

    }

    &__back {
        color: $body-color !important;
        font-size: 17px;
        font-weight: 500;
        letter-spacing: normal;
        text-decoration: none !important;
        vertical-align: middle;

        &__icon {
            @include size(20px);
            margin-top: -2px;
            margin-left: -6px;
        }
    }
}
