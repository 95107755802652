body {

    &::before {
        // overlay
        background-color: rgba(#666, 0.5);
        content: '';
        display: block;
        height: 100vh;
        opacity: 0;
        position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: $zindex-header - 10;
        pointer-events: none;
        transition: opacity .45s;
        width: 100vw;

        .is-search-active &,
        .is-navigation-active &{
            @include media-breakpoint-down(md) {
                opacity: 1;
                pointer-events: auto;
            }
        }
        .is-minicart-active &  {
            opacity: 1;
            pointer-events: auto;
        }

        .is-pdp-image-zoom-active & {
            opacity: 1;
            pointer-events: auto;
            z-index: $zindex-modal-backdrop;
        }

    }

}
