$ns: 'c-tile-carousel';

.#{$ns} {
    margin-bottom: map-get($spacers, 5);

    width: 100%;
    width: calc(100% + #{map-get($grid-gutter-widths, xs) * 2});
    margin-right: map-get($grid-gutter-widths, xs) * -1;
    margin-left: map-get($grid-gutter-widths, xs) * -1;

    @include media-breakpoint-up(lg) {
        // width: calc(100% + #{map-get($grid-gutter-widths, lg) * 2});
        // margin-right: map-get($grid-gutter-widths, lg) * -1;
        // margin-left: map-get($grid-gutter-widths, lg) * -1;
        width: auto;
        margin-right: 0;
        margin-left: 0;
    }

    &__wrapper {

    }

    &__item {

        // @include media-breakpoint-up(md) {

        // }

    }

    &__image {

        img {

        }
    }

    &__text {
        display: block;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.25;
        letter-spacing: 1px;
        margin-top: map-get($spacers, 4);
        text-align: center;
        text-transform: uppercase;

        &--link {
            &, &:hover {
                color: inherit;
                text-decoration: none;
            }
        }

        &__text {

            @include media-breakpoint-down(sm) {
                @include sr-only;
            }
        }

        &__text-mobile {
            @include media-breakpoint-up(md) {
                display: none;
            }
        }


    }

    .slick-track {
        margin-left: 20px;

        @include media-breakpoint-up(lg) {
            margin-left: 0;
        }

    }

    .slick-slide {
        margin-right: vw(35px, 375px);
        width: vw(69px, 375px);

        @include media-breakpoint-up(md) {
            margin-right: vw(45px, map-get($grid-breakpoints, lg));
            width: vw(135px, map-get($grid-breakpoints, lg));
        }

        $allGutters: map-get($grid-gutter-widths, lg) * 3;
        @include media-breakpoint-up(lg) {
            margin-right: calc(#{40px / (map-get($grid-breakpoints, xl) - $allGutters)} * (100vw - #{$allGutters}));
            width: calc(#{135px / (map-get($grid-breakpoints, xl) - $allGutters)} * (100vw - #{$allGutters}));
        }

        @include media-breakpoint-up(xl) {
            margin-right: 40px;
            width: 135px;
        }

    }

    .slick-arrow {
        background-repeat: no-repeat;
        background-position: center;
        background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #ffffff);
        height: 100%;
        pointer-events: none;
        top: 0;
        width: 43px;
    }

    .slick-disabled {
        background-image: none;
    }

    @include media-breakpoint-up(lg) {
        $slick-arrow-size: 24px;

        .slick-arrow {
            background-image:
                svg-url($svg-slick-arrow-black),
                linear-gradient(to left, rgba(255, 255, 255, 0), #ffffff 80%)
            ;
            background-position:
                left center,
                center
            ;
            background-size:
                $slick-arrow-size,
                100%
            ;

            // To align arrow vertically with center of image assuming
            // image is 1:1 aspect ratio.
            height: vw(135px, map-get($grid-breakpoints, xl));
            pointer-events: auto;
            width: vw(150px, map-get($grid-breakpoints, xl));

        }

        .slick-prev {
            left: -#{$slick-arrow-size};
        }

        .slick-next {
            right: -#{$slick-arrow-size};
        }

        .slick-disabled {
            background-image: none;
        }

    }

    @include media-breakpoint-up(xl) {
        .slick-arrow {
            // To align arrow vertically with center of image assuming
            // image is 1:1 aspect ratio.
            height: 135px;
        }
    }

    &--simple {
        .slick-arrow {
            height: 100%;
            top: 50%;

            &.slick-prev {
                left: -8px;
                transform: translateY(-50%);
            }
            &.slick-next {
                right: -8px;
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }
}
