/* ============================================================================
   PowerReviews
   ============================================================================ */

   $ns: 's-powerreviews';

   .#{$ns} {
       &.pr-displaywrite {
           margin-bottom: map-get($spacers, 4);
       }

       .p-w-r {
           label {
               @extend .c-form-row__label;

               font-family: $font-family-primary;

               span {
                   font-size: inherit;
               }
           }
           input {
               &[type=text],
               &[type=phone],
               &[type=email] {
                   @extend .input-text;
               }
           }
           textarea {
               @extend .input-textarea;
           }

           .pr-btn {
               &.active {
                   background-color: $primary;
               }
           }
           .pr-btn-fileinput,
           .pr-btn-add-tag a {
                @include button-outline-variant($flint, $bright-red, transparent, $bright-red, $dawn2);

               border-bottom-width: 1px;

               &:hover {
                   span,
                   span::after {
                       color: inherit;
                   }
               }

               span,
               span::after {
                   color: inherit;
               }
           }
           .pr-btn-add-tag {
               color: $flint;

               &:hover {
                   color: $bright-red;
               }
           }
           .pr-btn-review {
                @include button-variant($secondary, $secondary, $bright-red, $bright-red);

                appearance: none;
                margin-right: map-get($spacers, 4);
           }
       }
   }
