$ns: c-image-grid;

.#{$ns} {
    &__wrapper {
        @include make-row();
        margin-bottom: map-get($spacers, 5);
    }
    &__item {
        @include make-col-ready();
        @include make-col(4);

        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: map-get($spacers, 4);

        @include media-breakpoint-up(lg) {
            @include make-col(2);

            padding: 0 15px;
        }
    }
    &__link {
        max-width: 193px;
    }
}
