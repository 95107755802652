$ns: "c-tooltip";

.#{$ns} {
    &__icon {
        @include background-svg($svg-tooltip-icon);

        background-size: cover;
        display: inline-block;
        height: 23px;
        vertical-align: top;
        width: 23px;

        :hover {
            @include background-svg($svg-tooltip-inverse);
        }
    }
}
