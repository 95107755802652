.bonus-product-list {
    .bonus-product-item {

        &__container {
            display: flex;
            justify-content: center;
        }

        @include clearfix();

        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        .product-content {
            border: 1px solid rgba(0, 0, 0, 0.1);
        }

        .product-primary-image,
        .product-details {
            width: 100%;
        }

        .product-add-to-cart {
            border-bottom: none;
        }
        
        .product-primary-image {
            justify-content: center;
            max-width: 100%;
            width: 100%;
        }
        
    }
    .product-add-to-cart {
        padding-bottom: 0;
        .inventory {
            width: auto;
        }
    }
    .product-name {
        float: none;
        margin: 1em 0;
    }
    .quantity-error {
        color: red;
        text-transform: inherit;
    }
    .bonus-product-list-footer {
        @include clearfix();
        padding-top: 1em;
        padding-bottom: 1em;
        .add-to-cart-bonus {
            float: right;
        }
    }
    .selected-bonus-item {
        margin: 1em 0 1em 1em;
        position: relative;
        .item-name {
            font-weight: bold;
        }
        .remove-link {
            @include background-svg($svg-modal-close);

            background-position: center center;
            background-size: 120%;
            cursor: pointer;
            height: 15px;
            left: -1em;
            position: absolute;
            width: 15px;
        }
    }
}

.bonus-discount-container {
    .promo-details {
        display: none;
        &.visible {
            display: block;
        }
    }
}

.select-bonus {
    border-width: 1px;
    display: inline-block;
}
#more-bonus-products {
    display: block;
    margin: map-get($spacers, 4) auto;
    min-width: 125px;
}
