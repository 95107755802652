// The styles of the site nav bar
// Allow the breakpoints to vary in cases like locale
@mixin nav-variable-styles($breakpoint-down: md, $breakpoints: $grid-breakpoints) {
    @include media-breakpoint-down($breakpoint-down, $breakpoints) {
        html.is-navigation-active {
            &, body {
                height: 100%;
                overflow: hidden;
                position: relative;

                &::before {
                    background-color: rgba(117, 112, 103, .9);
                }
            }
        }
        #navigation {
            $nav-width: 330px;
            background: $body-bg;
            font-size: 13px;
            font-weight: normal;
            letter-spacing: .9px;
            line-height: normal;
            margin-top: 1px;
            max-height: calc(100vh - 100%);
            max-width: 100vw;
            position: absolute;
                top: 100%;
                left: -$nav-width;
                z-index: $zindex-navbar;
            transition: transform .3s;
            width: $nav-width;

            ul, li {
                list-style: none;
                margin: 0;
                padding: 0;
            }

            ul, li, a {
                display: block;
            }

            li {
                font-size: 15px;
                font-weight: normal;
            }

            .is-navigation-active & {
                overflow: auto;
                transform: translate($nav-width);
            }

            .level-1 {
                .is-active {
                    .level-2 {
                        padding-bottom: 5px;
                        padding-top: 5px;
                        transition: max-height .2s linear, padding .2s;
                    }
                }

                > li, > div > li {
                    font-weight: 500;

                    > a {
                        @include background-svg($svg-menu-link-arrow);
                        background-repeat: no-repeat;
                        background-position: calc(100% + 6px) center;
                        border-bottom: 1px solid $border-color;
                        text-transform: uppercase;

                        &, &:hover {
                            margin-left: map-get($grid-gutter-widths, xs);
                            margin-right: map-get($grid-gutter-widths, xs);
                            padding: 13px 0;
                        }
                    }

                    &.has-sub-menu {
                        > a {
                            @include background-svg($svg-accordion-expand);
                            border: 0;
                        }

                        &.is-active {
                            > a {
                                @include background-svg($svg-accordion-contract);
                            }
                        }

                    }

                }
            }

            .level-2,
            .level-3,
            .level-2__row .has-sub-menu > ul,
            .level-2--alt .has-sub-menu > ul {
                max-height: 0;
                overflow: hidden;
                transition: max-height .2s linear;
            }

            .level-2,
            .level-3 {
                > ul > li,
                > li {
                    > a {
                        &, &:hover {
                            padding: 11px map-get($grid-gutter-widths, xs);
                        }
                    }
                    &.has-sub-menu {
                        > a {
                            border: 0;

                            &::after {
                                @include background-svg($svg-accordion-black-expand);

                                background-position: center center;
                                background-repeat: no-repeat;
                                background-size: contain;
                                content: '';
                                display: inline-block;
                                height: 22px;
                                vertical-align: middle;
                                width: 22px;
                            }
                        }

                        &.is-active {
                            > a {
                                &::after {
                                    @include background-svg($svg-accordion-black-contract);
                                }
                            }
                        }
                    }

                }
            }

            .is-active > .level-2,
            .is-active > .level-3,
            .level-2__row .has-sub-menu.is-active > ul,
            .level-2--alt .has-sub-menu.is-active > ul {
                max-height: 300em;
            }


            .level-2 {
                border-bottom: 1px solid $border-color;
                margin-left: map-get($grid-gutter-widths, xs);
                margin-right: map-get($grid-gutter-widths, xs);
                transition: max-height .2s linear, padding 0s .2s; // Wait to update the padding, so as not to affect the feal of opening and closing the parent menu

                > ul {
                    margin-left: -10px;
                    margin-right: -10px;
                    margin-top: -5px;
                }

                &__banner {
                    display: none;
                }

            }

            .level-2,
            .menu-utility-user-mobile {
                a {
                    &, &:hover {
                        font-size: 15px;
                        letter-spacing: .5px;
                        padding: 13px map-get($grid-gutter-widths, xs);
                        text-transform: none;
                    }
                }

            }

            .level-3 {
                li > a {
                    &, &:hover {
                      font-weight: 300;
                      padding: 1rem map-get($grid-gutter-widths, xs);
                      padding-left: map-get($grid-gutter-widths, xs) + 14px;
                    }
                }
            }

            .menu-utility-user-mobile {
                background-color: $primary;
                color: $white;
                font-size: 15px;
                font-weight: normal;
                line-height: normal;
                letter-spacing: normal;
                padding-top: 10px;
                padding-bottom: 100px;

                a {
                    &, &:hover {
                        color: inherit;
                        font-size: inherit;
                        font-weight: inherit;
                        letter-spacing: inherit;
                        padding-top: 10px;
                        padding-bottom: 10px;
                    }
                }

                .user-info {
                    > a {
                        align-items: center;
                        background-image: none;
                        display: flex;

                        .svg-symbol {
                            @include size(16px);
                            margin-left: 10px;
                            transition: transform .2s;
                        }

                    }

                    &.has-sub-menu {
                        > a {
                            border-bottom: 0;
                        }
                    }

                }

                .user-panel {
                    border-bottom: 1px solid $border-color;
                    max-height: 0;
                    overflow: hidden;
                    transition: max-height .2s linear;

                    a {
                        &, &:hover {
                            text-transform: none;
                            border-bottom: 0;
                        }
                    }

                }

                .has-sub-menu {
                    .user-panel {
                        border-bottom: 1px solid $border-color;
                    }
                }

                .is-active {
                    .user-panel {
                        max-height: 200em;
                    }

                    &.user-info {
                        > a {
                            .svg-symbol {
                                transform: rotate(180deg);
                            }
                        }
                    }

                }

                .locale-select {
                    margin: 10px 0;
                    margin-left: map-get($grid-gutter-widths, xs);

                    a {
                        display: inline-block;
                        padding: 0;
                    }

                    a + a {
                        margin-left: 25px;
                    }

                }

            }

        }

    }
}

.menu-toggle {
    background-color: transparent;
    border: none;
    display: flex;
    margin-top: 1px;
    padding: 0;

    &__icon {
        @include size(24px);


        &--burger {
            .is-navigation-active & {
                display: none;
            }
        }

        &--close {
            display: none;

            .is-navigation-active & {
                display: block;
            }

        }

    }


}

#navigation {
    color: $body-color;

    ul, li {
        list-style: none;
    }

    a {
        &, &:hover {
            color: inherit;
        }
    }

}
