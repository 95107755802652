.pt_brand {
    .breadcrumb {
        margin-bottom: 38px;
    }
}

.brand-intro-container {
    border-bottom: 1px solid $border-color;
    padding-bottom: map-get($spacers, 5);
    padding-left: 10px;
    padding-right: 10px;

    @include media-breakpoint-up(md) {
        padding-bottom: 24px;
        padding-left: map-get($grid-gutter-widths, lg);
        padding-right: map-get($grid-gutter-widths, lg);
    }
}
.brand-intro-row {
    @include media-breakpoint-up(md) {
        margin-left: auto;
        margin-right: auto;
        max-width: 84%;
    }
}
.brand-logo-col {
    @include media-breakpoint-up(md) {
        flex-basis: auto;
        flex-grow: 0;
        max-width: 48%;
        width: auto;
    }
}
.brand-logo-wrap {
    margin-bottom: map-get($spacers, 3);
    margin-left: auto;
    margin-right: auto;
    max-width: 230px;
    padding: 10px 0 0;
    text-align: center;

    @include media-breakpoint-up(md) {
        margin-bottom: 28px;
        max-width: 100%;
        padding-left: map-get($grid-gutter-widths, xs);
        padding-right: map-get($grid-gutter-widths, xs);
    }
}
.brand-banner-wrap,
.brand-carousel,
.pt_brand .category-header {
    padding: 0 10px;

    text-align: center;
    @include media-breakpoint-up(lg) {
        padding: 0;
    }
}
.brand-banner-wrap,
.brand-carousel {
    margin-bottom: map-get($grid-gutter-widths, xs);

    @include media-breakpoint-up(lg) {
        margin-bottom: map-get($grid-gutter-widths, lg);
    }
}
.brand-banner-wrap {
    display: block;
}
.brand-carousel {
    .slick-list {
        overflow: hidden;
    }
    .slick-slide {
        float: left;
    }
}
.cat-header-slots {
    img {
        width:100%;
    }
    margin-bottom:3%;
}

