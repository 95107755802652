/* ============================================================================
   Amplience UGC
   ============================================================================ */

/**
* Amplience UGC, by @pixelmedia
*/

// HELPERS
// ============================================================================

// Please Note: All partials inside the helpers directory should be namespaced with helpers.

// Helpers are made up of the global variables, mixins, and functions that are used within the
// codebase.

@import "helpers/helpers.ugc";


// COMPONENTS
// ============================================================================

// Please Note: All partials inside the components directory should be namespaced with components.

/**
 * Components are concrete, implementation-specific pieces of UI. All of the changes you make to
 * its styles should be detectable in the context you're currently looking at. Modifying these
 * styles should be safe and have no side effects.
 *
 * Please namespace all component classes with c-.
 *
 * Do not remove the {{COMPONENTS}} Sass comment below as it is needed to scaffold
 * out components.
 */

@import "components/components.ugc-gallery";
@import "components/components.shoppable-ugc";


// SCOPES
// ============================================================================

// Please Note: All partial filenames inside this directory should be namespaced with scopes.

/**
 * Scopes create a new styling context. These should be used sparingly. they can be open to abuse
 * and lead to poor CSS if not used wisely. The primary use case for scopes within an SFCC standup
 * is to provide styles within a content asset or content slot. The secondary use case is when
 * styling an SG component in order to easily style the SG classes that are in place.
 *
 * Please namespace all scope classes with s-
 */

@import "scopes/scopes.slick-slider";
@import "scopes/scopes.ugc-dialog";
