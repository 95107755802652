.breadcrumb {
    margin: 13px 0;

    li {
        display: inline;
        list-style: none outside none;
    }

}

.paging-current-page {
    left: -999em;
    position: absolute;
}

.breadcrumb-element,
.breadcrumb-divider {
    color: inherit;
    font-size: 11px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.breadcrumb-element {
    &:hover {
        &::before {
            text-decoration: none;
        }
    }
    &:last-of-type {
        &::after {
            content: '';
            margin-right: 4px;
        }
    }
}

.breadcrumb-refinement {
    background-color: $black-10;
    border-radius: 10px;
    display: inline-block;
    font-size: 12px;
    font-weight: 300;
    margin: 0 10px 0 0;
    padding: 2px 10px 0px;
    text-transform: capitalize;

    &:last-of-type {
        &::after {
            content: '';
        }
    }
}

.breadcrumb-relax {
    @include background-svg($svg-close-red);

    background-position: center center;
    background-size: 15px auto;
    display: inline-block;
    height: 14px;
    margin-left: 4px;
    vertical-align: middle;
    width: 14px;
}
.breadcrumb-result-text {
    font-size: 12px;
    letter-spacing: 0.3px;
    padding-right: 5px;
    text-transform: initial;
}

.breadcrumb-result-text a,
.breadcrumb .searchphraselink {
    background: none;
    color: $body-color;
    padding: 0;
}
