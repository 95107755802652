$ns: "c-block-list";

.#{$ns} {

    ul {
        list-style: none;

        li {
            background-color: rgb(237,237,237);
            line-height: 1.56;
            margin: 0;
            padding: 12px 30px 8px;

            &::before {
                display: none;
            }

            &:nth-child(even) {
                background-color: rgb(245,245,245);
            }

            strong, b {
                font-weight: 600;
            }

        }

    }

}
