$ns: "c-personalization-image";

.#{$ns} {
    background-color: $body-bg;
    position: relative;

    &__personalized {
        position: absolute;
            top: 0;
            left: 0;
        width: 100%;

        ~ .#{$ns}__shim {
            // Original placeholder image to define space
            // and prevent flash of content.
            opacity: 0;
        }

    }

}
