/* ============================================================================
   Daily Deal Product
   ============================================================================ */

$ns: "c-daily-deal-product";

.#{$ns} {
    $font-size: $font-size-base-px;
    $theme-color: $secondary;

    margin: map-get($spacers, 4) 0;

    &__header,
    &__body {
        align-items: flex-end;
        border-bottom: 3px solid $theme-color;
        display: flex;
        justify-content: space-between;
    }

    &__header {
    }

    &__heading {
        color: $theme-color;
        font-size: rem(27px);
        letter-spacing: normal;
        line-height: 1.3;
        margin: 0;
    }

    &__date-time {
        color: $theme-color;
        font-size: rem(12px);
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 2.5;
        text-align: right;
    }

    &__body {
        padding: rem(15px) 0 rem(14px);

        &__first {
            display: flex;
        }

    }

    &__offer,
    &__time {
        &__label {
            color: $gray-600;
            font-size: rem(12px);
            font-weight: 600;
            letter-spacing: 1px;
            line-height: normal;
            text-transform: uppercase;
        }

        &__value {
            color: $body-color;
            font-size: rem(21px);
            font-weight: bold;
            letter-spacing: normal;
            line-height: 1.2;
            margin-top: 2px;
            text-transform: uppercase;
        }
    }

    &__offer {
        padding-right: rem(28px);

        &__label {

        }
        &__value {

        }
    }

    &__time {
        border-left: 1px solid rgba($body-color, .2);
        padding-left: rem(24px);
        // padding-bottom: rem(2px);

        &__label {

        }

        &__value {
            letter-spacing: 1px;
        }
    }

    &__signup {

        &__link {
            &, &:hover {
                color: $theme-color;
                font-size: rem(13px);
                font-weight: normal;
                line-height: normal;
                letter-spacing: normal;
            }

            &:hover {
                text-decoration: underline;
            }

        }
    }

}
