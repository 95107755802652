$ns: "c-category-up-tile";

.#{$ns} {
    align-items: center;
    display: flex;
    position: relative;
    text-align: center;

    &__image {
        display: block;
        height: auto;
        width: 100%;
    }

    &__label {
        color: #ffffff;
        display: block;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 1.9px;
        line-height: normal;
        padding: 0 map-get($spacers, 3);
        position: absolute;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
    }

    &-grid {
        @include make-row();

        .s-graphical-asset--banner + & {
            margin-top: map-get($spacers, 5);
        }

        &__item {
            @include make-col-ready();

            .#{$ns}-grid--3up & {
                @include make-col(4);
                margin-bottom: map-get($spacers, xs);

                @include media-breakpoint-up(lg) {
                    margin-bottom: map-get($spacers, lg);
                }

            }

            .#{$ns}-grid--4up &, .#{$ns}-grid--5up & {
                @include make-col(6);
                margin-bottom: map-get($spacers, xs);

                @include media-breakpoint-up(lg) {
                    @include make-col(3);
                    margin-bottom: map-get($spacers, lg);
                }

            }

        }

    }

}
