.pt_store-locator {
    font-size: 0.813rem;
    h1,
    .h1 {
        color: $body-color;
        font-weight: $headings-font-weight;
        letter-spacing: -0.3px;
        line-height:  43px;
    }

    h4,
    .h4 {
        font-family: $font-family-sans-serif;
        font-weight: $font-weight-normal;
    }


    h6,
    .h6 {
        color: $body-color;
        font-size: $font-size-base;
        font-family: $font-family-sans-serif;
        font-weight: $font-weight-normal;
    }
    p {
        font-size: 0.813rem;
        margin: 0.63rem 0;
    }

    .button {
        display: inline-block;
        font-family: "Nunito Sans", sans-serif;
        line-height: 44px;
        height: 46px;
        min-width: 174px;
        padding: 0px 25px;
    }

    a {
        text-decoration: underline;
    }

    a:hover {
        text-decoration: none;
    }

    input[type=text], input[type="password"], select {
        height: 46px;
        max-width: 100%;
        padding: 0px 10px 0px 12px;
    }

    .dialog-required {
        text-transform: none;
    }
}

#store-location-results th {
    background-color: $gray-100;
    border-right: 1px solid $body-bg;
}

#store-location-results th:last-child {
    border-right: none 0px;
}

.store-locator-header {
    font-size: 1.75em;
    font-style: italic;
    font-weight: 400;
    padding: 20px 0 20px;
}

.store-information {
    background: none repeat scroll 0 0 $gray-200;
    border-bottom: 1px solid $body-bg;
    width: 50%;
}

.store-name {
    font-weight: bold;
    margin: 0 0 10px;
}

.store-map {
    border-bottom: 1px solid $gray-200;
    width: 25%;
}

.store-locator-details {
    font-size: 0.813rem;

    @include media-breakpoint-up(md) {
        float: left;
        width: 40%;
    }

    img {
        max-width: 100%;
    }

    h1 {
        font-size: 2.5em;
        font-style: normal;
        margin: 0.25em 0;
    }

    p {
        margin: 0.25em 0 0.8em;
        &:last-child {
            margin-bottom: 0;
        }
    }

}

.store-locator-details-map {
    min-height: 300px;

    @include media-breakpoint-up(md) {
        float: right;
        min-height: 500px;
        width: 57%;
    }
}

.store-locator-no-results {
    padding-top: 10px;
}
