/* ============================================================================
   Shoppable UGC
   ============================================================================ */

$ns: c-shoppable-ugc;

.#{$ns} {
    display: flex;

    &__col {
        width: 100%;

        @include media-breakpoint-up(lg) {
            width: 50%;
        }

        &--image {
            display: none;

            @include media-breakpoint-up(lg) {
                display: block;
            }
        }

        &--content {
            background-color: #F1F0EF;
            padding: 40px 20px;

            @include media-breakpoint-up(lg) {
                padding: 47px 51px;
            }
        }
    }

    &__heading {
        color: $brand-call-to-action;
        font-size: 33px;
        font-weight: 700;
        margin-bottom: 30px;
        text-align: center;
        text-transform: uppercase;

        @include media-breakpoint-up(lg) {
            text-align: left;
        }

        &::after {
            background-color: $brand-call-to-action;
            content: "";
            display: block;
            height: 4px;
            margin-top: 5px;
            margin-right: auto;
            margin-left: auto;
            width: 39px;

            @include media-breakpoint-up(lg) {
                margin-right: 0;
                margin-left: 0;
            }
        }

    }

    &__product-line-item {

        & + & {
            margin-top: 40px;
        }

    }

    &__product {
        display: flex;

        &:hover {
            text-decoration: none;
        }

        &-image-wrapper {
            background-color: white;
            box-shadow: 0 15px 30px -15px rgba(0, 0, 0, 0.4);;
            display: flex;
                align-items: center;
                justify-content: center;
            flex-shrink: 0;
            height: 138px;
            padding: 10px;
            width: 138px;
        }

        &-image {
            max-width: 100%;
            max-height: 100%;
        }

        &-content-wrapper {
            padding-left: 20px;
        }

    }

    &__brand {
        color: #757575;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    &__name {
        color: black;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.3;
        margin-top: 3px;
    }

    &__price-wrapper {
        margin-top: 12px;
    }

    &__price {
        color: black;
        font-weight: 600;

        &--strikeout {
            display: inline-block;
            position: relative;

            &::before {
                background-color: $brand-call-to-action;
                content: "";
                display: block;
                height: 2px;
                position: absolute;
                    top: 50%;
                    left: -2px;
                transform: translateY(-50%);
                width: calc(100% + 4px);
            }
        }

        &--sale {
            color: $brand-call-to-action;
        }
    }

    &__link-wrapper {
        margin-top: 39px;
    }

    &__link {
        display: flex;
            align-items: center;
            justify-content: center;
        font-size: 14px;
        font-weight: 600;
        height: 48px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
    }
}
