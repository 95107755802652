.js .simple-submit {
    display: none;
}

fieldset {
    margin-bottom: map-get($spacers, 5);
}

legend {
    @extend .h2;

    span {
        font-size: 12px;
        font-weight: 400;
        position: relative;
        text-transform: none;
        padding: 0 .5em;
    }

}

.form-label-text {
    font-size: 1.1em;
    font-weight: 700;
    padding: 0.75em 0 0;
}

.field-wrapper {
    .label-inline & {
        width: auto;
    }
    .label-above & {
        padding-left: 0;
        width: auto;
    }
}

.input-text,
.input-textarea {
    appearance: none;
}
.input-text,
.input-textarea,
.input-select,
.bml-terms-and-conditions,
.giftcert-apply + .form-row,
.coupon-error {
    &.error,
    .error {
        border-color: $danger;
        color: $danger;
    }
}
.input-text,
.input-textarea,
.input-select,
.bml-terms-and-conditions {
    @extend %input; // Located in placeholders/_form_components

    &--shrink {
        width: auto;
    }

}
.input-text,
.input-select {
    &:not(.c-qty__input) {
        @extend %input__not-qty;
    }
}
.input-text {
    &.valid {
        @include background-svg($svg-checkbox-valid);
    }
    &.error {
        @include background-svg($svg-checkbox-error);
    }
    &.c-qty__input {
        background-image: none;
    }

    &.no-validate {
        background: none;
    }

}
.input-select {
    @extend %input-select; // Located in placeholders/_form_components
}
.input-select--alt {
    background-position: center right;
    border-width: 0;
    border-bottom-width: 1px;
    font-size: inherit;
    padding: 0;
    padding-right: 20px;

    &:hover {
        border-color: $body-color
    }
    &:focus {
        border-bottom-width: 2px;
    }
}

.input-radio,
.input-checkbox {
    margin: 0 .4em 0 0;

    @include media-breakpoint-up(sm) {
        margin: 0 .5em 0 0;
    }

    .label-inline & {
        margin: 0 .4em 0 0;

        @include media-breakpoint-up(sm) {
            margin: 0 .5em 0 0;
        }
    }
}

.form-caption {
    clear: left;
    width: auto;

    .label-above & {
        margin-left: 0;
    }

    .form-indent & {
        margin-left: 0;
    }
}

.error-message {
    color: $danger;
}

span.error,
div.error {
    background: none;
}

.form-inline {
    form {
        padding: 0 0 2em;
    }
    label {
        padding: 0 0 0.75em;
        width: auto;
    }
    input[type="text"], select {
        margin-left: 0;
    }
    button {
        padding-left: 0;
        padding-right: 0;
    }
}

.row-inline {
    display: inline;
    label {
        width: auto;
    }
}

// Required Indicator and Text
.dialog-required {
    display: inline;
    font-size: 12px;
    position: relative;
    padding: 0 .5em;
    em {
        color: $danger;
        font-style: normal;
        margin: 0 .25em;
    }
}
.required-indicator {
    color: $danger;
    padding: 0;
    white-space: nowrap;
}
.coupon-error {
    margin-top: 20px;
}

input::-ms-clear, input::-ms-reveal {
    display: none;
}

.required-message {
	&-primary {
    	color: $primary;
    }
}

.non-scrollable .ui-dialog-content{
    overflow-x: hidden;
    overflow-y: hidden;
}