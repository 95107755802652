$ns: "c-leaderboard";

.#{$ns} {

    .ui-dialog-titlebar {
        padding: 0;
        height: 0;

        &::after {
            display: none;
        }

    }

    .ui-dialog-content {
        padding: 0;
        height: 100% !important;

        > *,
        > * > * {
            // height for inner layout containers to maintain background fill
            height: 100%;
        }
        .mcsubscribe-success-message,
        .mcsubscribe-error-message {
            height: auto;
        }

    }

    &__heading {
        color: $primary;
        font-size: 33px;
        font-weight: bold;
        line-height: 1.05;
        letter-spacing: normal;
        position: relative;
        text-align: center;
        text-transform: uppercase;

        &::after {
            content: "";
            background-color: $primary;
            display: block;
            height: 4px;
            margin-left: -19.5px;
            position: absolute;
                left: 50%;
                bottom: -0.25em;
            width: 39px;
        }

        @include media-breakpoint-up(md) {
            color: #fff;
            text-align: left;

            &::after {
                background-color: #fff;
                margin-left: 0;
                left: 0;
            }

        }

        @include media-breakpoint-up(lg) {
            font-size: 40px;
        }

    }

    &__panel-a {

        &__content {
            height: 100%;
            padding-top: 48px;
            padding-right: map-get($grid-gutter-widths, xs);
            padding-bottom: map-get($spacers, 5);
            padding-left: map-get($grid-gutter-widths, xs);

            @include media-breakpoint-up(md) {
                background-image: var(--c-leaderboard-bgimage);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                display: block;

                @media
                    (-webkit-min-device-pixel-ratio: 2),
                    (min-resolution: 192dpi) {
                        background-image: var(--c-leaderboard-bgimage-lg);
                }

            }

            @include media-breakpoint-up(lg) {
                padding-top: 45px;
                padding-left: map-get($grid-gutter-widths, lg);
            }

        }

    }

    &__panel-b {
        &__content {
            padding-top: map-get($spacers, 3);
            padding-right: map-get($grid-gutter-widths, xs);
            padding-bottom: map-get($spacers, 5);
            padding-left: map-get($grid-gutter-widths, xs);

            @include media-breakpoint-up(md) {
                padding-top: 34px;
            }

            @include media-breakpoint-up(lg) {
                padding-left: 0;
                padding-right: map-get($grid-gutter-widths, lg);
            }

        }

    }
    
    &__agreement-confirmation {
        margin-bottom: 8px;
        padding-top: 3px;

        .d-flex {
            display: block !important;
        }
        .c-form-row {
            &__input {
                float: left;
            }
        }
    }

}
