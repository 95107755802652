$ns: 'pdp-image-zoom';

.#{$ns} {
    $header-height: 0;

    align-items: center;
    background-image: none;
    display: none;
    flex-direction: column;
    max-height: 100vh;
    justify-content: center;
    margin: auto;
    position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10000;
    text-align: center;
    width: 100vw;

    .is-#{$ns}-loading &,
    .is-#{$ns}-active & {
        display: flex;
    }

    &__inner {
        box-shadow: 0px 40px 50px -20px rgba(0,0,0,.4);
        max-width: 1440px;
    }

    &__header {
        height: $header-height;
        position: relative;
    }

    &__close {
        @include background-svg($svg-modal-close);
        border-radius: 50%;
        box-shadow: 0 0 10px rgba(255,255,255,1);
        background-color: white;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        display: block;
        height: 35px;
        opacity: 0;
        position: fixed;
            top: 12px;
            right: 12px;
            z-index: 1;
        transition: opacity 1s;
        width: 35px;

        @include media-breakpoint-up(lg) {
            top: 20px;
            right: 20px;
        }

        @include media-breakpoint-up(lg) {
            position: absolute;
        }

        .is-#{$ns}-active & {
            opacity: 1;
        }

    }

    &__body {
        background-image: url("../images/loading-small.gif");
        background-repeat: no-repeat;
        background-position: center;
        overflow: auto;
        position: relative;
        max-height: 100vh;
        max-width: 100vw;
        width: 100vw;

        @include media-breakpoint-up(lg) {
            width: auto;
        }
    }

    &__image {
        display: none;
        height: auto;
        min-height: 100vh;
        min-width: 100vw;
        max-height: 100vw;
        max-width: 100vh;
        opacity: 0;
        transition: opacity 1s;
        width: auto;

        @include media-breakpoint-up(lg) {
            min-height: 0;
            min-width: 0;
            max-height: none;
            max-width: 100%;
            width: 100%;
        }

        .is-#{$ns}-loading &,
        .is-#{$ns}-active & {
            display: inline-block;
        }

        .is-#{$ns}-active & {
            opacity: 1;
        }

    }


}

.is-#{$ns}-active {
    &, body {
        height: 100%;
        overflow: hidden;
        position: relative;
    }
}
