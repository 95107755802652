$ns: "c-personalizationli";

.#{$ns} {
    font-size: rem(13px);
    margin: map-get($spacers, 3) 0;

    &__heading {
        font-size: 1rem;
        font-weight: normal;
        margin: 0 0 .5em;
    }

    &__thead {

        @include media-breakpoint-up(sm) {
            display: table-header-group !important;
        }

        &__row {
            display: table-row;

            &__heading {
                font-size: rem(13px);
                font-weight: 500;

                @include media-breakpoint-up(sm) {
                    display: table-cell !important;
                }

            }

        }
    }

    &__tbody {
        &__row {
            font-size: rem(13px);
            display: flex;
            flex-wrap: wrap;

            @include media-breakpoint-up(sm) {
                display: table-row;
            }

            &__value {

                @include media-breakpoint-up(sm) {
                    display: table-cell !important;
                }

            }
        }
    }

    &__link {
        font-size: rem(13px);
    }

    &__footer {
        padding: .5rem .4rem;
        text-align: right;
    }

}
