img {
    max-width: 100%;
}

table {
    width: 100%;
}

b, strong {
    font-weight: $font-weight-bold;
}
