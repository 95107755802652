.s-bb-links {
    // Links withing this scope will display with border-bottom

    a[href] {
        @include bb-link;
    }

    &--inherit-color {
        a[href] {
            color: inherit;

            &:hover {
                color: inherit;
            }

        }
    }

}
