button,
.button {
    cursor: pointer;

    // Override default button styles to better match comps
    &.btn-outline-primary {
        @include button-outline-variant($primary, $primary, transparent);
    }
    &.btn-outline-dark {
        @include button-outline-variant($flint, $bright-red, transparent, $bright-red, $dawn2);
    }
    &.btn-primary {
        @include button-variant($primary, $primary, #356b26, #356b26);
    }
    &.btn-secondary {
        @include button-variant($secondary, $secondary, $bright-red, $bright-red);
    }
}

.button {
    &-fancy-large {
        // Don't override outline buttons that are using this for its other styles
        &:not(.btn-outline-dark) {
            @include button-variant($secondary, $secondary, $bright-red, $bright-red);
        }

        border-style: solid;
        font-size: 14px;
        font-weight: $font-weight-semibold;
        letter-spacing: 1.5px;
        min-width: 60px;
        padding: 13px 15px;
        text-decoration: none;
        text-transform: uppercase;

        &.disabled {
            background-color: gray !important;
            border-color:gray !important;
            pointer-events: none !important;
        };

        &:disabled {
            cursor: auto;
            opacity: .35;
        }
        &:hover {
            text-decoration: none;
        }
    }
    &-fancy-medium {
        padding: 3px 14px !important;
    }
    
    &-text {
        @extend .btn-link;
    }

    &-full {
        border: 0;
        display: block;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 1.6px;
        padding: 15px;
        text-transform: uppercase;
        text-align: center;
        width: 100%;

        &:hover {
            text-decoration: none;
        }
    }
}


// bootstrap button overrides
.btn {

    &.btn-primary {
        font-size: 14px;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.5px;
        text-transform: uppercase;
    }

    &.btn-sm {
        font-weight: 500;
        padding: 0.25rem 1rem;
        text-transform: none;
    }

    &.btn-lg {
        padding: 0.719rem 1rem;
    }


}
