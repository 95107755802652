$ns: c-alpha;

.#{$ns} {
    &-container {
        width: 75%;

        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }

    &-menu__container,
    &-header,
    &-list {
        max-width: 984px;
    }
    &-menu {
        &__container {
            margin: auto;
        }
        &__wrapper {
            background-color: #f4f2f1;
            display: flex;
            flex-wrap: wrap;
            padding: 11px 27px;
            justify-content: center;

            @include media-breakpoint-up(md) {
                justify-content: space-between;
                border-radius: 25px;
            }
        }
        &__header {
            font-size: 27px;
            font-weight: 600;
            letter-spacing: -0.2px;
            margin-bottom: 12px;
            text-align: center;
        }
        &__item {
            border-bottom: 2px solid transparent;
            flex: 0 0 30px;
            font-size: 13px;
            letter-spacing: .35px;
            list-style: none;
            padding: 2px 3px;
            text-align: center;

            @include media-breakpoint-up(md) {
                flex-basis: auto;
            }

            &:hover {
                border-bottom-color: $body-color

                a {
                    text-decoration: none;
                }
            }

            a {
                color: inherit;
            }
        }
    }
    &-header,
    &-list {
        padding: 0 map-get($grid-gutter-widths, xs);

        @include media-breakpoint-up(lg) {
            padding: 0 41px;
        }
    }

    &-row {
        border-bottom: 1px solid $border-color;
        padding: 20px 0;
    }

    &-header {
        &__container {
            position: relative;
        }
        &__header {
            color: $green-haze;
        }
        &__marker {
            position: absolute;
            top: -65px;

            @include media-breakpoint-up(sm) {
                top: -80px;
            }
        }
    }
    &-list {
        flex-wrap: wrap;
        margin-bottom: 6px;
        &__link {
            word-break: normal;
        }
    }
}
