$ns: "c-simple-banner";

.#{$ns} {
    margin-bottom: 12px;

    @include media-breakpoint-up(md) {
        margin-bottom: 30px;
    }

    &__background {
        &__pic,
        &__pic__img {
            width: 100%;
        }
    }
}
