/* ============================================================================
   Full-bleed
   ============================================================================ */

$ns: 'u-full-bleed';

.#{$ns} {
    @include media-breakpoint-up(xl) {
        margin-left: calc(-1 * (50vw - (#{map-get($container-max-widths, xl) / 2} - #{map-get($spacers, 5)})));
        width: 100vw;
    }
}
