/* ============================================================================
   Basic Promo
   ============================================================================ */

$ns: 'c-basic-promo';

.#{$ns} {
    color: $black;
    $padding-mobile: 10px;
    $padding-full: 20px;

    font-family: $font-family-sans-serif;
    margin-bottom: map-get($spacers, 5);
    padding: 0 $padding-mobile;
    text-decoration: none;

    @include media-breakpoint-up(md) {
        padding-left: $padding-full;
        padding-right: $padding-full;
    }

    &:hover {
        color: $black;
        text-decoration: none;
    }

    * {
        font-family: inherit;
    }

    &__img-wrap {
        position: relative;
    }
    &__img {
        display: block;
        margin-bottom: 22px;
        width: 100%;

        &__img {
            width: 100%;
        }
    }
    &__title-wrap {
        padding-left: 15px;
        padding-right: 15px;
        position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            z-index: 1;
        text-align: center;
        transform: translateY(-50%);
    }
    &__title {
        font-size: 16px;
        font-weight: $font-weight-bold;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
    &__eyebrow {
        color: #569842;
        font-size: 12px;
        font-weight: $font-weight-semibold;
        letter-spacing: 1px;
        line-height: 1.25;
        margin-bottom: 7px;
        text-transform: uppercase;
    }
    &__heading {
        font-size: 21px;
        font-weight: 500;
        margin-bottom: 12px;
    }
    &__text {
        font-weight: 300;
        line-height: 1.38;
        margin-bottom: 10px;
    }
    &__button {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #569842;
        color: inherit;
        display: inline-block;
        font-size: 15px;
        line-height: 1.15;
        padding-left: 0;
        padding-right: 0;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

    &__3-up-wrap,
    &__4-up-wrap {
        display: flex;
            flex-wrap: wrap;
            margin-left: -$padding-mobile;
            margin-right: -$padding-mobile;

        @include media-breakpoint-up(md) {
            margin-left: -$padding-full;
            margin-right: -$padding-full;
        }
    }
    &__3-up,
    &__4-up {
        flex: 0 0 100%;

        @include media-breakpoint-up(md) {
            flex-basis: 50%;
        }
    }

    &__3-up {
        margin-bottom: map-get($spacers, 5);

        @include media-breakpoint-up(md) {
            margin-bottom: 40px;
        }
        @include media-breakpoint-up(lg) {
            flex-basis: 33.3%;
        }
    }

    &__4-up-wrap {
        @include media-breakpoint-up(md) {
            padding-bottom: 0;
            padding-top: 0;
        }
    }
    &__4-up {
        margin-bottom: map-get($spacers, 5);

        @include media-breakpoint-up(lg) {
            flex-basis: 25%;
        }

        .#{$ns} {
            &__img  {
                margin-bottom: 15px;

                @include media-breakpoint-up(md) {
                    margin-bottom: 20px;
                }
            }
            &__heading {
                margin-bottom: 6px;

                @include media-breakpoint-up(md) {
                    margin-bottom: 9px;
                }
            }
        }
    }
}
