$pdp-general-padding: map-get($grid-gutter-widths, xs);

.product-sales-price,
.price-sales {
    &.has-standard-price {
        color: $promo;
    }
}
.active-promo-names {
    color: $promo;
}
.pt_product-details {
    .primary-content {
        width: 100%;
    }
}

.pdp-main {
    padding-top: 15px;

    h1 {
        margin: 0;
        text-align: left;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .pdp-main-row {
        position: relative;
        @include make-row();

        @include media-breakpoint-down(sm) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .product-col-1,
    .product-col-2,
    .product-col-inner {
        @include media-breakpoint-up(md) {
            @include make-col-ready();
        }
    }
    .product-col-1,
    .product-col-2 {
        @include media-breakpoint-up(lg) {
            padding-bottom: $pdp-general-padding;
        }
    }
    .product-col-1 {
        @include media-breakpoint-up(md) {
            @include make-col(7);
        }
    }
    .product-col-2 {
        padding-bottom: $pdp-general-padding;

        @include media-breakpoint-up(sm) {
            width: 100%;
        }

        @include media-breakpoint-up(md) {
            @include make-col(5);
        }
    }
    .product-col-inner {
        @include media-breakpoint-down(sm) {
            padding: 0;
        }
        @include media-breakpoint-up(lg) {
            @include make-col(6);
        }
    }
    .product-image-container,
    .product-col-1 {
        display: flex;
        overflow: hidden;
        flex-wrap: wrap;
    }

    .product-col-inner {
        display: flex;
        flex-wrap: wrap;

        > * {
            &:not(.sr-only) {
                width: 100%;
            }
        }
    }
    .product-col-inner--1 {
        align-content: flex-start;
    }
    .product-col-inner--2 {
        align-content: flex-end;
    }

    .product-detail {
        #pr-reviewsnippet {
            // fixed height to prevent content jump
            min-height: 21px;
            margin-bottom: 15px;
        }
    }

    .product-brand,
    .product-name {
        display: block;
        font-family: $font-family-primary;
        font-weight: 600;
    }

    .product-brand {
        color: $gray-600;
        font-size: 13px;
        letter-spacing: 0.9px;
        margin-bottom: 5px;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
            margin-bottom: 8px;
        }
    }

    .product-brand-link {
        color: $gray-600;
    }

    .product-name {
        color: $black;
        font-size: 21px;
        line-height: 1.29;
        letter-spacing: -0.2px;
        margin-bottom: 15px;

        a {
            color: inherit;
        }
    }

    .product-number,
    .percent-savings,
    .product-short-description {
        display: none;
    }

    .product-id{
        font-size: 15px;
        font-weight: 500;
        line-height: 1.67;
        margin-bottom: 5px;
    }

    .product-price {
        font-size: 27px;
        line-height: 1.22;
        margin-bottom: 25px;

        @include media-breakpoint-up(md) {
            margin-bottom: 15px;
        }

        .price-standard {
            color: $flint;
            font-size: 21px;
            text-decoration: line-through;
        }

        .price-tiered {
            color: $promo;
        }

        .active-promo-names {
            color: $promo;
            font-size: 16px;
            line-height: normal;
        }
        .percent-savings {
            display: inline-block;
            font-size: 19px;
        }
        .salesprice {
            font-size: 1.5rem;;
            margin-top: 12.5px;
            font-weight: bold;
            margin-bottom: 0;

        }
    }

    .product-review {
        margin-bottom: 13px;
        min-height: 18px;
    }

    .product-long-description {
        @include media-breakpoint-up(lg) {
            margin-bottom: 38px;
        }
    }

    .country-availability {
        font-size: 16px;
        font-weight: normal;
        letter-spacing: .5px;
        margin-bottom: 14px;

        @include media-breakpoint-up(lg) {
            margin-bottom: $pdp-general-padding;
        }

        .attribute {
            padding: .2em 0;
        }
        .label {
            padding: 0;
        }
    }

    .product-primary-image {
        display: flex;
        overflow: hidden;
        text-align: center;
        @include media-breakpoint-up(md) {
            width: 77%;
            flex-basis: 77%;
        }
        .slick-track {
            display: flex;
        }
    }

    .product-primary-image-inner {
        width: 100%;

        @include media-breakpoint-up(sm) {
            padding-bottom: 9px;
        }
        @include media-breakpoint-up(md) {
            max-height: 500px;
            padding-bottom: 0;
            margin-bottom: 0;
        }

        &:not(.slick-initialized) {
            .product-image {
                display: none;

                &:first-child {
                    display: block;
                }
            }
        }

        .slick-slide {
            align-self: center;
        }

        .slick-dots {
            margin-top: 4px;

            li {
                background-color: $alto;

                &.slick-active {
                    background-color: $secondary;
                }
            }
        }
    }

    .pdp-below-productdetails-wrapper {
        flex-basis: 100%;
        max-width: 100%;
        width: 100%;

        .c-product-tile-carousel__header {
            margin-bottom: 0;
            h2 {
                text-align: left;
                font-size: 18px;
                text-indent: 1em;
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-up(md) {
            position: absolute;
            width: 55.5%;
            top: 700px;
            left: 1%;
        }
    }

    .product-info {
        background: $tertiary;
        color: $black;
        font-weight: $font-weight-light;
        width: auto;
		clear: both;

        // Height for PDP Tabs
        @include media-breakpoint-up(lg) {
            padding-left: 0;
            padding-right: 0;
            padding-top: 1em;
        }

        h2,
        h4 {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 13px;
        }

        h3 {
            font-size: 18px;
            font-weight: 500;
            line-height: 1.33;
            margin-bottom: 9px;
        }

        p,
        ul {
            font-size: 16px;
            font-weight: 300;
            line-height: 1.56;
            margin-bottom: 16px;
        }

        ul {
            li {
                margin-bottom: 16px;
                padding-left: 20px;
                position: relative;

                &::before {
                    content: '';
                    background-color: $green-haze;
                    border-radius: 6px;
                    height: 6px;
                    position: absolute;
                        left: 0;
                        top: 8px;
                    width: 6px;
                }
            }
        }

        .c-accordion__item {
            border-bottom-width: 1px;

            &:first-of-type {
                border-top: none;
            }

            &:last-of-type {
                border-bottom: none;
            }

            .c-accordion__header {
                @include background-svg($svg-accordion-black-expand);
                font-size: 15px;
                font-weight: 500;
                letter-spacing: 0.7px;
                line-height: normal;
                padding: 15px 1px 12px 3px;
            }

            &.is-expanded > .c-accordion__header {
                @include background-svg($svg-accordion-black-contract);
            }
        }

        .table {
            margin-bottom: 40px;
        }

        .tab-content {
            padding-left: 0;
            padding-right: 0;

            &__2-col-wrap {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            &__col {
                flex: 0 0 100%;
                margin-bottom: map-get($grid-gutter-widths, xs);

                @include media-breakpoint-up(lg) {
                    flex-basis: calc(50% - #{map-get($grid-gutter-widths, xs)});
                    margin-bottom: 0;
                }
            }
        }
    }

    .product-info-container {
        @include make-container();
        @include make-container-max-widths();
        margin: auto;
    }

    .product-info-container {
        @include media-breakpoint-up(lg) {
            padding-top: 46px;
            padding-bottom: 49px;
        }
    }

    // Product Add to Cart Area (Availability, Quantity)
    .product-add-to-cart {
        border-bottom: 1px solid $border-color;
        margin: 0;
        padding: 10px 0 $pdp-general-padding;
        overflow: hidden;

        &.add-sub-product {
            border-bottom: none;
            margin-bottom: $pdp-general-padding;
        }

        .quantity {
            label {
                text-align: right;
            }
            input {
                text-align: center;
            }
        }

        .dw-apple-pay-button {
            margin-left: .5rem;
            max-height: 48px;
            @include media-breakpoint-up(sm) {
                margin-top:10px;
            }
            @include media-breakpoint-up(md) {
                margin-top:10px;
            }
            @include media-breakpoint-up(lg) {
                width: 172px; // make sure apple pay button is as big as Add To Cart
                margin-top:10px;
            }
        }
    }

    .promotion {
        background-color: $gray-100;
        border-top: 1px solid #ccc;
        overflow: hidden;
        padding: 1.5em;
        .promotion-title {
            font-weight: bold;
            margin-bottom: .3rem;
            text-transform: uppercase;
        }
        .promotion-callout {
            color: $promo;
            font-size: 1rem;
            .tooltip {
                font-size: .7rem;
                margin-left: .2rem;
            }
        }
    }

    .product-availability {
        align-self: center;
        flex: 0 1 55%;
        font-size: 15px;
        font-style: normal;
        padding-bottom: 0;

        &__label {
            display: none;
        }

        .value {
            display: inline-block;
        }

        .availability-novariation,
        .availability-msg * {
            font-style: normal;
            font-size: 15px;
        }
    }
    .product-availability-quickview > div {
        padding-top: 15px;
    }
    .inventory {
        flex: 0 0 auto;
        margin-right: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
        max-width: 41%;
    }

    // Product Set Display
    .product-set {
        &.product-col-2 {
            margin-bottom: 75px;
            padding-top: 10px;
            position: relative;
            border: solid 1px $primary;
            background-color: $offwhite;
            padding-left: 10px;
            padding-right: 10px;
            @include media-breakpoint-up(sm) {
                padding-left: 25px;
                padding-right: 50px;
                .product-set-list .product-set-image {
                    width: 30%;
                }
            }
            .product-actions {
                position: absolute;
                bottom: -50px;
            }
            .product-actions .socialsharing .share-icon__svg:not(:hover) {
                color: $midgray;
            }
            .product-set-item-nav-buttons {
                position: absolute;
                right: 22px;
                top: 50%;
                transform: translateY(-50%);
                display: flex;
                flex-flow: column;
                justify-content: space-around;
                .product-set-item-nav-button {
                    background-color: $midgray;
                    cursor: pointer;
                    padding: 0;
                    border-radius: 50%;
                    height: 8px;
                    width: 8px;
                    margin: 4px 0;
                    &:hover, &.active {
                        height: 10px;
                        width: 10px;
                        margin: 3px -1px;
                        background-color: $primary;
                    }
                }
            }

            @include media-breakpoint-down(md) {
                .product-set-item-nav-buttons {
                    display: none;
                }
                .product-actions {
                    width: 100%;
                    .share-icon:last-child {
                        margin-right: 15px;
                    }
                }
                button.button-fancy-large:not(#add-all-to-cart) {
                    max-width: unset;
                }
            }
        }

        .product-set-list::-webkit-scrollbar {
            display: none;
        }
        .short-description {
            text-align: center;
        }
        > .product-name {
            font-size: 21px;
            font-weight: 600;
            margin-bottom: 0;
            width: auto;
            text-align: center;
            @include media-breakpoint-up(md) {
                font-size: 25px;
                line-height: 1.22;
                letter-spacing: -0.3px;
                margin-bottom: 8px;
            }
        }
        .product-set-item .product-name {
            font-size: 18px;
            font-weight: 500;
            @include media-breakpoint-up(md) {
                font-size: 21px;
            }
        }

        .product-add-cart__buttons-wrap {
            justify-content: space-between;
            button {
                width: 100%;
                max-width: 15rem;
            }
            @include media-breakpoint-up(lg) {
                flex-direction: row;
                button {
                    width: 49%;
                }
            }
        }
        .product-set-list {
            position: relative;
            @include media-breakpoint-up(sm) {
                height: 500px;
                overflow-y: auto;
                -ms-overflow-style: none;
                scrollbar-width: none;
            }

            .product-set-details {
                width: calc(100% - 20px);
                padding-left: $pdp-general-padding;
            }
            .product-set-image {
                width: 22%;
            }
            .product-set-item {
                background-color: #fff;
                border: solid 1px $lightgray;
                display: flex;
            }
            .product-set-item {
                margin-bottom: 10px;
                padding: 1em 0 0;
            }
            .product-set-image {
                width: 50%;

                img {
                    width: 100%;
                }
            }
            .product-price {
                font-size: 1rem;
                margin: .5em 0;
                .price-standard, .price-sales {
                    font-size: 25px;
                }
            }
            .attribute {
                h3 {
                    width: 25%;
                }
                .label {
                    width: 25%;
                    white-space: nowrap;
                }
            }
        }
        .product-add-to-cart {
            border-top: none;
            padding-top: 0;
            margin-bottom: 0;
            padding-bottom: 7px;
            .product-availability {
                &__label {
                    float: none;
                    padding: top;
                }
            }
        }
        .product-set__product-add-to-cart {
            padding-top: 1rem;
            text-align: center;
            border: none;
            padding-bottom: 0;
            position: relative;
            z-index: 1;
            font-weight: 600;
            label {
                display: inline;
                font-size: 1.5rem;
            }
            .product-price {
                display: inline;
            }
            .product-price > * {
                display: inherit;
                font-weight: inherit;
            }
            .product-add-cart__buttons-wrap {
                display: block;
            }
        }
        button.button-fancy-large {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }
        .attribute  {
            display: inline-block;
            width: 48%;
            .value {
                width: 100%;
                max-width: unset;
            }
        }
        .product-variations .attribute .size-chart-link {
            max-width: unset;
            float: right;
        }
        .product-set-details-row.row-1 {
            display: flex;
        }
        .product-set-details-row.row-2 {
            .product-variations > ul {
                display: flex;
                width: 100%;
                flex-wrap: wrap;
                justify-content: space-between;
            }
            .custom-quantity-control {
                height: 39px;
                button, input {
                    height: 100%;
                }
            }
            .inventory-availability-wrap {
                display: none;
            }
            .availability-sku {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
            }
            .availability-msg b {
                font-weight: 500;
            }
        }
        .product-name-pricing {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .product-name {
                margin-bottom: 5px;
            }
            .product-price {
                margin: 0;
            }
        }
    }
    // Product Recommendations
    .recommendations {
        clear: left;
        padding: 2em 0;
        .recommendation-tooltip {
            background: none repeat scroll 0 0 $body-bg;
            border: 1px solid;
            left: 50%;
            position: absolute;
            text-align: left;
            top: 50%;
            width: 300px;
            z-index: 100;
        }
        h2 {
            font-size: 2em;
            font-style: italic;
            font-weight: 400;
            margin-left: 14px;
        }
        #carousel-recommendations {
            margin: auto;
            position: relative;
            overflow: hidden;
        }
        ul {
            display: flex;
            flex-wrap: wrap;
            list-style-type: none;
            position: relative;
        }
        .product-tile {
            padding: 0 2em;
        }
        .product-name {
            font-size: 1em;
            margin: .2em 0;
            width: 100%;
        }
        .product-price {
            font-size: 1em;
        }
        .product-tile:hover .recommendation-tooltip {
            display: block !important;
        }
    }

    .inventory-availability-wrap,
    .product-add-cart__buttons-wrap {
        display: flex;
        flex-direction: column;

    }
    .doordash-addtocart-btn{
        max-width: 100%;
    }
    .shipping-options-details {
        display: flex;
        max-width: 100%;
        flex-direction: row;
        padding-top: 4%;
    }
    .pickup-store-info{
        width: 32%;
    }
    .expressDelivery{
        width:32%
    }

    @media (max-width: 767px) {
        .shipping-options-details {
            flex-direction: column;
        }
        .pickup-store-info,
        .expressDelivery{
            width: auto !important;
            height: auto !important;
            padding: 10px;
            border-bottom-style: ridge;
            border-bottom-width: thin;
        }
        .standard-Shipping-info {
            width: auto !important;
            height: auto !important;
            padding-top: 10px;
        }
        .shipping-option-details-bottomdiv {
            padding-left: 40px !important ;
        }
        .shipping-options-details-a,
        .set-preferred-store {
            vertical-align: super !important;
            font-weight: 600;
            font-size: 15px;
        }
        .shipping-option-details-bottomdiv br {
            display: none;
        }
        .doorDashLogoImage {
            max-width: 50%;
            margin-bottom: 10px;
        }
        }
    .standard-delivery{
        margin-bottom: 0px !important;
    }
    .product-actions {
        justify-content: flex-start;

        @include media-breakpoint-up(sm) {
            align-items: center;
            flex-wrap: nowrap;
            justify-content: space-between;
        }

        @include media-breakpoint-up(md) {
            align-items: flex-start;
            flex-wrap: wrap;
        }

        @include media-breakpoint-up(lg) {
            align-items: center;
            flex-wrap: nowrap;
            justify-content: space-between;
        }

        @include media-breakpoint-up(xl) {
            flex-wrap: nowrap;
            padding-top: 23px;
        }

        &__item {
            padding: 0;
            position: relative;

            &::after {
                content: '';
                background-color: $black;
                display: inline-block;
                height: 22px;
                opacity: 0.1;
                position: absolute;
                    right: 0;
                width: 1px;

                @include media-breakpoint-up(lg) {
                    top: calc(50% - 11px);
                }
            }
        }

        .returnandexchage {
            color: $flint;
            flex-basis: 54%;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0.9px;
            line-height: 2;
            margin: 0;
            min-width: 190px;
            text-transform: uppercase;
            text-align: center;

            @include media-breakpoint-up(sm) {
                flex-basis: 39%;
                padding-right: 12px;
            }

            @include media-breakpoint-up(md) {
                flex-basis: 54%;
                padding-right: 0;
            }

            @include media-breakpoint-up(lg) {
                flex-basis: 37%;
                line-height: 13px;
                min-width: auto;
                padding-right: 12px;
            }

            &::after {
                display: none;

                @include media-breakpoint-up(sm) {
                    display: inline-block;
                }

                @include media-breakpoint-up(md) {
                    display: none;
                }

                @include media-breakpoint-up(lg) {
                    display: inline-block;
                }
            }
        }

        .wishlist {
            color: $primary;
            font-size: 15px;
            flex-basis: 41%;
            padding-bottom: 18px;

            @include media-breakpoint-up(sm) {
                flex-basis: 27%;
                padding-bottom: 0;
            }

            @include media-breakpoint-up(md) {
                flex-basis: 41%;
                max-width: none;
                margin-right: 0;
                padding-bottom: 18px;
            }

            @include media-breakpoint-up(lg) {
                flex-basis: 27%;
                line-height: 13px;
                padding-bottom: 0;
            }

            svg {
                margin-top: -8px;
            }
        }

        .socialsharing {
            flex-basis: 100%;
                justify-content: center;
            max-width: 100%;

            @include media-breakpoint-up(sm) {
                flex-basis: 29%;
            }

            @include media-breakpoint-up(md) {
                flex-basis: 100%;
            }

            @include media-breakpoint-up(lg) {
                flex-basis: 29%;
                justify-content: flex-end;
            }
        }
    }

    .product-details__detail {
        margin-bottom: 31px;
    }

    .product-features {
        ul {
            font-size: inherit;
            margin-left: 12px;
            padding-left: 15px;
            padding-top: 0;
            list-style-type: disc;
        }
        li {
            padding-bottom: 9px;
            padding-left: 5px;
        }
    }

}

.product-price,
.product-pricing {
    .percent-savings {
        color: $promo;
        letter-spacing: .5px;
    }
}

.product-pricing {
    .percent-savings {
        font-size: 14px;
    }
}

.s-product-fashion {
    .product-primary-image {
        margin: 0;
        min-width: 100%;
        width: auto;

        @include media-breakpoint-up(sm) {
            margin: 0 -10px;
        }
        @include media-breakpoint-up(lg) {
            margin: calc(#{map-get($grid-gutter-widths, xs)} * -1) calc(#{map-get($grid-gutter-widths, xs)} * -1) 0;
        }

        .main-image {
            padding: 0;

            @include media-breakpoint-up(sm) {
                padding: 0 map-get($grid-gutter-widths, xs);
            }
            @include media-breakpoint-up(lg) {
                padding: map-get($grid-gutter-widths, xs);
            }
        }
    }
    .product-image-container,
    .product-detail {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .product-image-container {
        @include media-breakpoint-up(lg) {
            flex-basis: 66.7%;
            max-width: 66.7%;
        }
    }
    .product-detail {
        @include media-breakpoint-up(lg) {
            flex-basis: 33.3%;
            max-width: 33.3%;
        }
    }
    .product-description-link {
        font-size: 12px;
    }
    .product-variations {
        .size-attribute {
            .value {
                @include media-breakpoint-up(lg) {
                    flex-basis: 46.9%;
                    max-width: 46.9%;
                }
            }
        }
    }
    .product-add-cart__buttons-wrap {
        .button-fancy-large {
            @include media-breakpoint-up(large) {
                padding: 13px 10px;
            }
        }
    }
    .product-actions {
        @include media-breakpoint-up(md) {
            flex-wrap: wrap;
            justify-content: flex-start;
        }

        .button {
            @include media-breakpoint-up(md) {
                flex-basis: 131px;
                margin-bottom: 18px;
                max-width: 131px;
            }
        }
        .socialsharing {
            @include media-breakpoint-up(md) {
                justify-content: flex-start;
                flex-basis: 100%;
                max-width: 100%;
            }
        }
    }
}

.product-tab {
    &--inline {
        display: inline;

        a {
            color: inherit;
            text-decoration: underline;
        }
    }
}

.s-product-wide {
    .pdp-main {
        .product-image-container,
        .product-detail {
            flex-basis: 100%;
            max-width: 100%;
        }
        .product-add-to-cart {
            border-bottom: none;
        }
    }
    .product-image-container {
        @include media-breakpoint-down(md) {
            padding: 0 28px map-get($grid-gutter-widths, xs);
        }
    }
    #product-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        @include media-breakpoint-up(md) {
            padding-left: 28px;
            padding-right: 28px;
        }
        @include media-breakpoint-up(lg) {
            margin: 0 -20px;
            padding-left: 116px;
            padding-right: 116px;
        }
    }
    .product-col-inner {
        border-bottom: 1px solid $border-color;
    }
    .product-col-inner--1 {
        @include media-breakpoint-down(md) {
            border-bottom: none;
        }
    }
    .product-actions {
        padding-left: 0;
        padding-right: 0;
        width: 100%;

        @include media-breakpoint-up(lg) {
            padding-left: $pdp-general-padding;
            padding-right: $pdp-general-padding;
            width: 50%;
        }
    }
}

.product-actions {
    align-items: flex-start;
    color: $gray-600;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: $pdp-general-padding 0 0;

    @include media-breakpoint-up(sm) {
        justify-content: flex-end;
    }
    @include media-breakpoint-up(lg) {
        align-items: center;
        padding-top: 17px;
    }

    .socialsharing {
        display: flex;
        flex: 0 0 57%;
        max-width: 57%;
        position: relative;

        @include media-breakpoint-up(sm) {
            flex-shrink: 2;
            justify-content: flex-end;
        }
        @include media-breakpoint-up(md) {
            flex-basis: 50%;
            max-width: 50%;
            padding-top: 0;
        }

        .dropdown-drawer {
            padding: 5px 10px;
        }
    }
    a {
        color: inherit;
        font-size: .81rem;
        margin-right: 15px;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            color: $body-color;
        }

        &.share-icon {
            display: inline-block;
        }
    }
    .share-icon__svg {
        color: $dawn2;
        height: 32px;
        vertical-align: middle;
        width: 32px;

        &:hover {
            color: $primary;
        }

        svg {
            max-height: 100%;
            max-width: 100%;
        }
    }
    .share-link,
    .share-icon {
        &:last-child {
            margin-right: 0;
        }
    }
    .share-link {
        &:last-child {
            a {
                margin-right: 0;
            }
        }
    }
}
.at-resp-share-element a.at-share-btn {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
}
.product-actions__svg {
    display: inline-block;
    height: 24px;
    vertical-align: middle;
    width: 24px;

    use {
        stroke: $gray-600;
    }
}

.product-info__header {
    font-weight: 600;

    @include media-breakpoint-up(lg) {
        font-size: 24px;
    }

    a {
        color: inherit;
    }
}
.product-info__headers-wrap {
    margin-bottom: 40px;

    li {
        display: inline-block;
        list-style: inherit;
        margin-right: 21px;
    }
}

.main-image {
    display: block;
}
.primary-image {
    max-width: 100%;
    @include media-breakpoint-down(md) {
       width: 100%;
    }
}

.product-thumbnails {
    display: none;
    flex: 0 1 120px;
    min-width: 120px;
    overflow: hidden;
    flex-basis: 15%;

    @include media-breakpoint-down(md) {
        display: none !important;
    }

    // thumbnail
    li {
        @include size(96px, 96px);
        border: 1px solid transparent;
        border-left: 3px solid transparent;
        margin-bottom: 15px;
        margin-right: 20px;
        overflow: hidden;
        padding: 6px;

        &.selected {
            border-color: $mercury;
            border-left-color: $secondary;
        }

        img {
            min-height: 100%;
            min-width: 100%;
        }
    }
    // thumbnail image
    img {
        box-sizing: border-box;
        cursor: pointer;
        max-width: 100%;
        height: auto;
    }
    h2 {
        margin: 1rem 0 .3rem;
    }
}

.product-thumbnails__link {
    a {
        align-items: center;
        background-color: transparent;
        border: 2px solid $frost;
        border-radius: 50%;
        color: $primary;
        display: flex;
        flex-direction: column;
        font-size: 12px;
        font-weight: 500;
        height: 100%;
        justify-content: flex-end;
        letter-spacing: 0.3px;
        line-height: normal;
        padding-bottom: 12px;
        text-align: center;
        text-transform: uppercase;
        width: 100%;

        &::before {
            border-bottom: 9px solid transparent;
            border-left: 15px solid $primary;
            border-top: 9px solid transparent;
            content: 'test';
            display: block;
            font-size: 0;
            height: 0;
            line-height: 0;
            margin: 0 auto 4px;
            padding: 0 !important;
            width: 0;
        }
    }
}

.rating {
    .product-info & {
        font-size: 2em;
    }
}

.product-variations,
.product-options {
    .attribute {
        margin: 0 0 17px;
        overflow: hidden;
        padding: 0;
        position: relative;

        h3 {
            font-weight: bold;
            margin-bottom: .3rem;
            text-transform: uppercase;
        }

        ul {
            float: right;
            width: 100%;
        }

        .value {
            display: flex;
            width: 100%;

            @include media-breakpoint-up(lg) {
                width: 75%;
            }

            select {

                @include media-breakpoint-up(lg) {
                    flex: 0 0 48%;
                    max-width: 48%;
                }
            }

            .variant-button-group {
                display: flex;

                &__item {
                    border: 1px solid $gray-200;
                    cursor: pointer;
                    display: flex;
                        align-items: center;
                        justify-content: center;
                    height: 40px;
                    text-align: center;
                    width: 80px;

                    &:last-child {
                        border-left: none;
                    }

                    &-link {
                        border: none;
                        border-radius: 0;
                        color: $black;
                        display: flex;
                            align-items: center;
                            justify-content: center;
                        font-size: 14px;
                        height: 100%;
                        letter-spacing: 0.3px;
                        width: 100%;

                        &:hover {
                            text-decoration: none;
                        }
                    }

                    &:not(.variant-button-group__item--disabled):hover,
                    &--selected {
                        background-color: $primary;
                        border-color: $primary;

                        .variant-button-group__item-link {
                            color: $white;
                        }
                    }

                    &--disabled {
                        cursor: default;

                        .variant-button-group__item-link {
                            color: $gray-200;
                        }
                    }
                }
            }
        }
        .label,
        .size-chart-link {
            flex: 0 0 48%;
            max-width: 48%;

            @include media-breakpoint-up(md) {
                flex-basis: 48%;
                max-width: 48%;
            }
        }
        .label {
            display: inline-block;
            font-size: 15px;
            font-weight: 500;
            line-height: 1.67;
            margin-bottom: 5px;

            @include media-breakpoint-up(sm) {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        .size-chart-link {
            @include media-breakpoint-up(sm) {
                flex-basis: 48%;
                max-width: 48%;
            }
        }
        .selected-value {
            border: 0 none;
            color: $body-color;
            font-weight: $font-weight-normal;

            @include media-breakpoint-up(lg) {
                padding-top: .8em;
            }
        }
        .size-chart-link {
            clear: both;
            float: none;
            a {
                border-color: $body-bg !important;
                padding: 0;
            }
        }
    }
    .attribute {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .size-chart-link {
            font-size: 13px;
            text-align: right;

            @include media-breakpoint-up(sm) {
                order: 2;
                text-align: left;
            }

            a {
                color: inherit;
            }
        }
        .size-chart-link__svg {
            height: 30px;
            vertical-align: middle;
            width: 30px;
        }
    }
    .size-attribute {
        justify-content: space-between;

        @include media-breakpoint-up(sm) {
            justify-content: flex-start;
        }

        .label {
                flex: 0 0 48%;

            @include media-breakpoint-up(sm) {
                flex-basis: 100%;
            }
        }
        .value {
            @include media-breakpoint-up(sm) {
                flex-basis: 48%;
                margin-right: 16px;
            }
            @include media-breakpoint-up(md) {
                margin: 0;
                margin-right: 11px;
                max-width: 48%;
        }

            select {
                flex-basis: 100%;
                max-width: 100%;
            }
        }
    }
    .option-dropdown__label {
        display: block;
        font-size: 14px;
        margin-bottom: 6px;
    }
    .swatches {
        margin-top: -6px;
    }
    .swatches li {
        float: left;
        &:hover,
        &.selected {
            a {
                border-color: $primary;
            }
        }
        &.unselectable {

            img {
                opacity: .5;
            }

            a {
                color: $gray-200;
                overflow: hidden;
                position: relative;

                &::before,
                &::after {
                    content: '';
                    height: 100%;
                    position: absolute;
                    top: 0;
                }
                &::before {
                    border: 2px solid rgba(0, 0, 0, 0.22);
                    border-radius: inherit;
                    left: 0;
                    width: 100%;
                    z-index: 2;
                }
                &::after {
                    background-color: rgba(0, 0, 0, 0.22);
                    height: 100%;
                    left: 50%;
                    transform: translateX(-50%) rotateZ(45deg);
                    width: 2px;
                    z-index: 3;
                }
            }
            &:hover {
                a {
                    border-color: $gray-200;
                }
            }
        }
        a {
            display: block;
            line-height: 3.5;
            margin: 0 7px 0 0;
            text-align: center;
            white-space: nowrap;
            @include media-breakpoint-up(lg) {
                line-height: 2.7;
                min-height: 2rem;
                min-width: 2rem;
            }
        }
    }
    .swatchanchor {
        @include size(38px);
        border: 2px solid transparent;
        border-radius: 100%;
        display: block;
        overflow: hidden;
        padding: 0;

        img {
            @include size(100%);
            border-radius: 100%;
            border: 3px solid transparent;
            display: block;
        }
    }
    .color li {
        a {
            line-height: 0;
        }
    }
    select {
        background-color: $body-bg;
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: 0;
        width: 100%;
        margin-left: 0;
    }
}

.product-add-cart__buttons-wrap {
    justify-content: space-between;
    margin: 0;

    button {
        flex: 0 0 100%;

        @include media-breakpoint-up(sm) {
            flex-basis: 48%;
            max-width: 48%;
        }

        @include media-breakpoint-up(md) {
            flex-basis: 100%;
            max-width: 100%;
        }

        @include media-breakpoint-up(lg) {
            flex-basis: 48%;
            max-width: 48%;
        }

    }
}
.add-to-cart,
#add-to-cart,
#find-in-store {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1.5px;
    margin-top: 10px;
    font-weight: bold;

}

#find-in-store {
    border-width: 2px;

    &:disabled:hover {
        border-color: $dawn2;
    }
}


.add-to-cart,
#add-to-cart {
    margin-bottom: 10px;

    @include media-breakpoint-up(sm) {
        margin-bottom: 0;
    }
}

// Tabs using just CSS, no javascript required
.tabs {

    &.js-product-tabs {
      max-width: 1360px;
      margin: 0 auto;
    }

    display: none;
    position: relative;

    &__inner {
        display: flex;
    }

    .tab {
        background-color: $ebb;
        height: 50px;
        min-width: 140px;
        text-align: center;
        vertical-align: bottom;
    }

    .tab-label {
        color: inherit;
        display: block;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: normal;
        margin-bottom: 0;
        padding: 15px 0 12px;
        position: relative;
            top: 0;
        text-decoration: none;
        text-transform: uppercase;
        transition: all 0.25s;

        span {
            border-bottom: 3px solid $ebb;

            @include media-breakpoint-up(lg) {
                padding: 12px 0 9px;
            }
        }

        @include media-breakpoint-up(lg) {
            cursor: pointer;

            &.is-active-tab,
            &:hover {
                background-color: $gray-100;

                span {
                    border-bottom-color: $green-haze;
                }
            }
        }
    }
}

.tab {
    @include media-breakpoint-up(lg) {
        display: inline-block;
        float: none;
    }

    .tab-switch {
        display: none;

        &:checked {
            + .tab-label {
                @include media-breakpoint-up(lg) {
                    background: #fff;
                    border-top-color: $primary;
                    color: $body-color;
                    font-weight: 700;
                    top: -0.0625rem;
                    transition: all 0.35s;
                    z-index: 1
                }
            }

            + label + .tab-content {
                opacity: 1;
                transition: all 0.35s;
                z-index: 2;
            }
        }
    }

    .tab-content {
        padding: 1em 0;
        text-align: left;
        z-index: 1;

        @include media-breakpoint-up(lg) {
            opacity: 0;
            transition: all 0.35s;
            width: 100%;
        }
    }
}

.tab-content-wrap {
    &.is-active-tab-content {
        display: block !important;
    }

    h3 {
        font-family: inherit;
        font-size: 20px;
        line-height: normal;
        margin-bottom: $pdp-general-padding;

        @include media-breakpoint-up(lg) {
            font-size: 24px;
        }
        }
    h4 {
        line-height: 1.2;
    }
}

.product-description-videos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 20px;

    h4 {
        width: 100%;
    }
}
.product-description-video {
    flex: 0 0 100%;
    margin-bottom: $pdp-general-padding;

    &:last-child {
        margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
        flex: 0 0 48.4%;
    }
}
.product-description-video__inner {
    padding-bottom: 55.94%;
    position: relative;

    @include media-breakpoint-up(lg) {
        padding-bottom: 56.25%;
    }

    iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

.pdp-gift-cert {
    @include make-container();
    @include make-container-max-widths();

    font-weight: 300;
    padding-bottom: map-get($spacers, 4);
    padding-top: map-get($spacers, 4);

    @include media-breakpoint-up(lg) {
        padding: 46px 156px 49px;
    }

    &__outer {
        background-color: $tertiary;
    }
    &__inner {
        @include make-row();
    }
    &__lookup,
    &__top-up {
        padding: 10px 5px;
        width: 100%;

        @include media-breakpoint-up(sm) {
            padding: map-get($grid-gutter-widths, xs) 30px;
        }
        @include media-breakpoint-up(md) {
            padding: 10px;
        }
        @include media-breakpoint-up(lg) {
            padding: map-get($grid-gutter-widths, xs);
        }

        h2 {
            font-family: inherit;
            font-size: 20px;
            line-height: normal;
            margin-bottom: 20px;
            text-transform: uppercase;

            @include media-breakpoint-up(lg) {
                font-size: 24px;
            }
        }
    }
    &__lookup {
        @include media-breakpoint-up(lg) {
            @include make-col(8);
        }
    }
    &__top-up {
        @include media-breakpoint-up(lg) {
            @include make-col(4);
        }

        .svg-symbol {
            height: 24px;
            width: 24px;
        }
    }

    .check-balance {
        border-bottom: none;

        @include media-breakpoint-down(md) {
            padding-bottom: 0;
        }
    }
    .icon-link {
        color: inherit;

        img {
            margin-right: 5px;
            max-height: 20px;
        }
    }
}

#compare-table {
    .product-actions {
        @include clearfix();
    }
}

.c-store-availability {
    .modalView{
        display: none;
    }
}

input.personalization-text {
    text-transform: uppercase;
}

h3.customMsg-add-to-cart {
 color: #34871b;
 font-style: normal;
 font-size: initial;
}

.ui-dialog #pdpMain {
    .view-full-details {
        float: right;
    }

    .quickviewset {
       float: initial;
    }

    .content-asset {
        display: none;
    }

    .product-availability {
        display: block;
        color: #34871b;
        font-size: 15px;
        width: 55%;
        margin-top: 58px;
    }

    .add-sub-product {
        .inventory {
            margin-right: 0px;
        }
        .product-availability {
	        .availability-msg {
	            margin-left : -15px;
	            @include media-breakpoint-up(lg) {
	                margin-left: 35px;
	            }
	        }
	        p {
	            @include media-breakpoint-up(lg) {
	                margin-left: -87px;
	            }
	            @include media-breakpoint-down(md) {
	                margin-left: -25px;
	            }
	        }
	    }
    }

    .check-store {
        width: 100%;
        text-align: center;
    }

    .c-store-availability {
        margin-top: 1rem;
        .modalView{
            display: block;
        }
    }
}

.pzballs-messaging {
    color: red;
}

#pickUpINStore {
    float: left;
    width: 33%;
    height: 180px;
}

#sameDayShipping {
    float: left;
    margin-left: 10px;
    width: 33%;
}

#expressDelivery {
    float: right;
    height: 180px;
}
.shipping-options-details-a{
    margin-left: 2px;
    font-weight: 600;
    font-size: 15px;
}

.shipping-info-tooltip {
    color: #34871b !important;
    text-decoration: underline !important;
    background-color: transparent !important;
}

@media only screen and (max-width: 729px) {
    #pdp-DD-logo {
        max-width: 50% !important;
    }
  }

@media only screen and (min-width: 729px) {
    .shipping-option-details-bottomdiv-sameday {
      width: 110%;
    }
}
