// The styles of the desktop nav bar
// Allow the breakpoints to vary in cases like locale
@mixin desktop-nav-variable-styles($breakpoint-up: lg, $breakpoints: $grid-breakpoints, $additional-breakpoint: null, $additional-breakpoint-map: null) {
    @include media-breakpoint-up($breakpoint-up, $breakpoints) {

        .menu-toggle {
            display: none;
        }

        #navigation {
            border-top: 1px solid $border-color;
            color: $body-color;
            display: flex;
            flex: 1;
            font-size: 13px;
            font-weight: normal;
            letter-spacing: .5px;

            html[lang="fr"] & {
                margin-left: 0;
            }

            a {
                display: inline-block;

                &, &:hover {
                    color: inherit;
                }

                &:hover {
                    text-decoration: underline;
                }

            }

            .menu-category {
                display: flex;
                justify-content: left;
                margin: 0 auto;
                max-width: map-get($grid-breakpoints, xl);
                @if not $additional-breakpoint {
                    padding: 0  ((map-get($grid-gutter-widths, lg) / 2) + 4px);
                } @else {
                    padding: 0  (map-get($grid-gutter-widths, lg) / 2);
                }
                width: 100%;

                @if $additional-breakpoint {
                    @include media-breakpoint-up($additional-breakpoint, $additional-breakpoint-map) {
                        padding: 0  ((map-get($grid-gutter-widths, lg) / 2) + 4px);
                    }
                }

                > li, > div > li {

                    &:hover > a {
                        background-color: transparent;
                    }

                }

            }

            .level-1 > li, .level-1 > div > li {
                    font-size: 14px;
                    font-weight: 500;

                    > a {
                        position: relative;
                        padding: 16px map-get($grid-gutter-widths, lg) * .4;

                        &, &:hover {
                            float: none;
                            letter-spacing: 1px;
                            text-decoration: none;
                            text-transform: uppercase;
                            width: 100%;
                        }

                        &::before {
                            background-color: transparent;
                            content: '';
                            display: block;
                            height: 3px;
                            margin-top: -5px;
                            position: absolute;
                                top: 100%;
                            transition: background-color .2s;
                        }

                    }

                    &:hover {
                        > a {
                            &::before {
                                background-color: $primary;
                            }
                        }
                    }

                }

            .level-2 {
                @include make-container();

                background-color: $body-bg;
                border-top: 1px solid $border-color;
                border-bottom: 3px solid $primary;
                box-shadow: 0 2px 4px 0 rgba(black, .15);
                color: $gray-800;
                visibility: hidden;
                line-height: 2.31;
                margin-top: -1px;
                padding: 0;
                position: absolute;
                top: 100%;
                left: 0;
                z-index: 1;

                // to pass width down to image so max-width works in IE11
                a, div {
                    width: 100%;
                }

                a {
                    line-height: 1.4;
                }

                img {
                    max-width: 100%;
                }

                li,
                .level-3 {
                    > ul {
                       padding: 0;
                   }
                }

                &.is-horizontal {
                    > ul {
                        @include make-row();
                        margin-top: 30px;
                        margin-bottom: 30px;
                        flex-wrap: nowrap;

                        > li:not(.level-2__banner) {
                            @include make-col-ready();
                            @include make-col(2);
                        }

                    }
                }

                &.is-vertical {
                    .level-2__row {
                        @include make-row();
                        margin-top: 30px;
                        margin-bottom: 30px;

                        > ul:not(.level-2__banner) {
                            max-width: map-get($grid-breakpoints, xl) - map-get($grid-gutter-widths, lg);
                        }

                        li{
                            width: 150px;
                            margin-right: 20px;
                        }

                    }


                }

                &__banner {
                    display: flex;
                    font-size: 14px;
                    font-weight: 600;

                    img {
                        @include size(auto);
                        flex: inherit;
                    }

                    &--span {
                        @for $i from 1 through 5 {
                            &-#{$i * 2} {
                                $col: $i * 2;
                                @if $col == 2 {
                                    padding-right: ($grid-gutter-width / 2);
                                    padding-left: ($grid-gutter-width / 2);
                                }
                                @include make-col($col);

                                > .col-2 {
                                    @include make-col(2, $i * 2);
                                }

                            }
                        }
                    }

                    h3 {
                        color: $body-color;
                        font-size: 1rem;
                        font-weight: 600;
                        letter-spacing: normal;
                        line-height: 1.31;
                        margin: 1.2rem 0 .5rem 0;
                    }

                    p {
                        color: $body-color;
                        font-size: 14px;
                        font-weight: 300;
                        letter-spacing: normal;
                        line-height: 1.5;
                        margin: .5rem 0;
                    }

                }

                &__alt-menu {
                    font-weight: 600;
                }

            }

            .level-2,
            .level-2__row {
                > ul {
                    > li {
                        > a {
                            // L2
                            &, &:hover {
                                font-size: 14px;
                                font-weight: 600;
                                text-transform: none;
                            }

                            + ul {
                                margin-top: 5px;
                            }

                        }
                    }
                }
            }

            .level-1 li:hover {
                .level-2 {
                    visibility:visible;
                    transition-delay: .5s;
                    display:flex;
                    justify-content: center;
                }
            }

            .level-3 {
                display: block;
                padding: 0;

                a {
                    font-weight: normal;
                    text-transform: none;
                }
            }

            .menu-utility-user-mobile {
                display: none;
            }

            .level-2 {

                &.is-vertical > .level-2__row,
                > ul {
                    max-width: map-get($grid-breakpoints, xl) - map-get($grid-gutter-widths, lg);
                    padding-right: 0;
                    padding-left: 0;
                    width: calc(100% + #{map-get($grid-gutter-widths, lg)});
                }

            }

            .html-slot-container {
                overflow-x: unset;
            }
        }

    }
}

@include media-breakpoint-up(break, (break: 1074px)) {
    #navigation {
        .level-1 > li, .level-1 > div > li {
            > a {
                padding-right: map-get($grid-gutter-widths, lg) * .5;
                padding-left: map-get($grid-gutter-widths, lg) * .5;
            }
        }

    }
}


