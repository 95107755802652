$ns: "c-event-result";

.#{$ns} {
    border-bottom: 1px solid $border-color;
    display: flex;
    font-family: $font-family-primary;
    padding: 30px 0;

    &:first-of-type {
        border-top: 1px solid $border-color;
    }

    &__col-a {
        min-width: 125px;
        width: 125px;

        @include media-breakpoint-up(md) {
            min-width: 135px;
            width: 135px;
        }

    }

    &__col-b {
        padding-left: map-get($grid-gutter-widths, xs);

        @include media-breakpoint-up(md) {
            padding-left: map-get($grid-gutter-widths, lg);
        }

    }

    &__img {
        width: 100%;
    }

    &__location {
        color: #569842;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.25;
        letter-spacing: 1px;
        margin: 3px 0 2px;
        text-transform: uppercase;
    }

    &__name {
        color: $body-color;
        display: inline-block;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.14;
        letter-spacing: normal;
        margin: 3px 0 2px;

        @include media-breakpoint-up(sm) {
            font-size: 21px;
        }

    }

    &__description {
        font-size: 16px;
        font-weight: 300;
        line-height: 1.5;
        letter-spacing: normal;
        margin: 6px 0 13px;

        @include media-breakpoint-down(sm) {
            @include sr-only;
        }

    }

    &__details {
        font-size: 14px;
        letter-spacing: normal;
        line-height: 1.29;
    }

    &__date,
    &__time {
        letter-spacing: normal;
        color: #757067;
        display: block;
        margin: 3.5px 0;

        @include media-breakpoint-up(sm) {
            display: inline;
            margin: 0;
        }

        &::after {
            @include media-breakpoint-up(sm) {
                background-color: rgba(0,0,0,.4);
                content: "";
                display: inline-block;
                height: 1em;
                margin: 0 7.5px;
                vertical-align: middle;
                width: 1px;
            }
        }

    }

    &__more {
        color: #00873d;
        font-weight: 500;

        &__icon {
            @include size(17px);
            display: inline-block;
            margin-left: -3px;
            vertical-align: middle;
        }

    }

}
