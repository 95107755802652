@include media-breakpoint-up(lg) {
    .menu-utility-user {
        align-items: center;
        display: inline-flex;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.2;

        a {
            color: inherit;
        }

        .user-account {
            align-items: center;
            display: inline-flex;

            @include media-breakpoint-up(lg) {
                padding: 9px 0;
            }

            &__icon {
                @include size(14px);
            }

        }

        .user-info {
            position: relative;

            .user-panel {
                background-color: $body-bg;
                box-shadow: 0 20px 30px -5px rgba(116, 116, 116, 0.4);
                color: $body-color;
                display: none;
                margin: 0;
                padding: 29px map-get($grid-gutter-widths, lg);
                position: absolute;
                    top: 100%;
                    right: -#{map-get($grid-gutter-widths, lg)};
                    z-index: $zindex-header-dropdown;
                text-align: left;
                text-transform: none;
                width: 198px;

                &__link {
                    display: block;
                    font-size: 14px;
                    font-weight: normal;
                    line-height: normal;
                    line-height: 1.43;
                    letter-spacing: normal;
                    padding: .5rem 0;
                }

            }

            &.active,
            &:hover {
                .user-panel {
                    display: block;
                }
            }
        }


    }
}

.menu-utility-user {
    &__item {
        &__icon {
            @include size(24px);
        }



        .header-banner__util--right & {
            @include media-breakpoint-up(lg) {
                margin-left: 25px;

                html[lang="fr"] & {
                    margin-left: 10px;
                }

            }

            @media (min-width: 1245px) {
                margin-left: 25px;
            }

        }

    }

    .locale-select {
        min-width: 3.2em;
    }

}

.dropdown-drawer {
    background-color: $body-bg;
    box-shadow: 0 20px 30px -5px rgba(116, 116, 116, 0.4);
    display: none;
    font-size: 12px;
    font-weight: normal;
    line-height: 2.5;
    letter-spacing: .5px;
    margin-top: 7px;
    margin-left: -1rem;
    padding: .8rem 0;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: $zindex-sticky-elem;
    text-align: left;
    text-transform: none;
    width: 162px;

    .active & {
        display: block;

    }
}
