$ns: 'c-fancy-promo';

.#{$ns} {
    $font-size: 16px;

    font-family: $font-family-sans-serif;
    padding-left: 0;
    padding-right: 0;
    position: relative;

    * {
        font-family: inherit;
    }

    &__background {
        &__pic,
        &__pic__img {
            display: block;
            width: 100%;
        }
    }
    &__content {
        max-height: 100%;
        padding-bottom: 0;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 10px;
        position: absolute;
            left: 0;
            top: 50%;
            z-index: 1;
        text-align: center;
        transform: translateY(-50%);
        width: 100%;

        @include media-breakpoint-up(md) {
            padding-bottom: 10px;
            padding-left: 64px;
            padding-right: 64px;
        }
        @include media-breakpoint-up(lg) {
            padding-left: 80px;
            padding-right: 80px;
        }
    }
    &__eyebrow,
    &__heading {
        text-transform: uppercase;
    }
    &__eyebrow {
        font-size: 13px;
        font-weight: $font-weight-semibold;
        letter-spacing: 1.5px;
        margin-bottom: 13px;

        @include media-breakpoint-up(md) {
            font-size: 14px;
            font-weight: $font-weight-bold;
            margin-bottom: 17px;
        }
    }
    &__heading {
        font-size: 30px;
        font-weight: $font-weight-bold;
        line-height: 1.11;
        margin-bottom: 13px;

        @include media-breakpoint-up(sm) {
            font-size: em(30px, $font-size);
        }
        @include media-breakpoint-up(md) {
            font-size: em(48px, $font-size); // Use a bigger base size so the fluid text doesn't get shrunk too much
        }
        @include media-breakpoint-up(lg) {
            font-size: 36px;
        }
    }
    &__text {
        font-weight: 500;
        line-height: 1.35;
        margin-bottom: 25px;

        @include media-breakpoint-up(md) {
            line-height: 1.5;
        }
    }
    &__button {
        background-color: $secondary;
        border: none;
        color: $white;
        display: block;
        font-size: 14px;
        font-weight: $font-weight-semibold;
        letter-spacing: 1.5px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 25px;
        max-width: 100%;
        padding: 15px 32px 12px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        width: 100%;

        @include media-breakpoint-up(md) {
            width: 280px;
        }

        &:hover {
            background-color: $bright-red;
        }
    }

    &__2-up-wrap {
        display: flex;
            flex-wrap: wrap;
        margin-right: -10px;
        margin-left: -10px;
        padding: 0;

        @include media-breakpoint-up(md) {
            margin-right: -20px;
            margin-left: -20px;
        }
    }

    &__2-up {
        flex: 0 0 100%;
        padding: 0 10px;
        margin-bottom: map-get($spacers, 5);

        @include media-breakpoint-up(md) {
            flex-basis: 50%;
            padding: 0 20px;
        }

    }

}
