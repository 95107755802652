/* ============================================================================
   UGC Dialog
   ============================================================================ */

$ns: s-ugc-dialog;

.#{$ns} {
    height: auto !important;
    max-width: 840px !important;

    .ui-dialog-titlebar-close {
        @include background-svg($svg-modal-close);
    }

    .ui-dialog-content {
        height: 100% !important;
        padding: 0 !important;
    }
}
