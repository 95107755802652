// landing page scopes
.s-landing {
    &__section {
        &--full-width {
            margin: 0 -#{map-get($grid-gutter-widths, xs)};

            @include media-breakpoint-up(lg) {
                margin: 0 -#{map-get($grid-gutter-widths, lg)};
            }

            @include media-breakpoint-up(xl) {
                margin: 0;
            }

        }

    }

}
