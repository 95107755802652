/* ============================================================================
   Daily Deal
   ============================================================================ */

$ns: c-daily-deal;
$svg: svg-mcdailydeal;

.#{$ns} {
    margin-top: 20px;
    margin-bottom: 60px;
    position: relative;

    @media (min-width: 1150px) {
        margin-top: 32px;
        margin-bottom: 60px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &--text-dark {
        .#{$ns} {
            &__banner-heading,
            &__banner-sub-heading,
            &__banner-location {
                color: black;
            }
            &__banner-location {
                &::after {
                    background-color: black;
                }
            }
        }
    }

    &__banner {
        &-image {
            height: 304px;
            object-fit: cover;
            width: 100%;

            @media (min-width: 769px) {
                height: 500px;
            }

            &-wrapper {
                height: 304px;

                @media (min-width: 769px) {
                    height: 500px;
                }
            }
        }

        &-content-wrapper {
            padding-right: 42px;
            position: absolute;
                z-index: 10;
                top: 42px;
                left: 42px;
            text-align: center;
            width: calc(100% - 42px);

            @media (min-width: 769px) {
                padding-right: 42px;
                top: 91px;
                left: 84px;
                text-align: left;
                width: calc(100% - 84px);
            }
        }

        &-heading {
            color: white;
            font-size: 39px;
            font-weight: 700;
            letter-spacing: -1px;
            line-height: 1;
            margin-bottom: 5px;
            text-transform: uppercase;

            @media (min-width: 769px) {
                font-size: 63px;
            }

            @media (min-width: 1150px) {
                width: 50%;
            }
        }

        &-sub-heading {
            color: white;
            font-size: 24px;
            font-weight: 600;
            line-height: 1.13;
            margin-right: auto;
            margin-bottom: 33px;
            margin-left: auto;
            width: 80%;

            @media (min-width: 769px) {
                line-height: 1;
                font-size: 36px;
                margin-right: 0;
                margin-bottom: 123px;
                margin-left: 0;
                width: 100%;
            }

            @media (min-width: 1150px) {
                width: 50%;
            }
        }

        &-location {
            color: white;
            font-size: 16px;
            font-weight: 600;

            @media (min-width: 769px) {
                font-size: 21px;
            }

            @media (min-width: 1150px) {
                width: 50%;
            }

            &::after {
                @media (min-width: 769px) {
                    background-color: white;
                    content: "";
                    display: block;
                    height: 3px;
                    margin-top: 10px;
                    width: 46px;
                }
            }
        }

        &-button {
            background-color: black;
            color: white;
            display: inline-flex;
                align-items: center;
                justify-content: center;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 1.5px;
            height: 48px;
            text-transform: uppercase;
            width: 280px;

            &:hover {
                color: white;
                text-decoration: none;
            }

            &-row {
                margin-top: 18px;

                @media (min-width: 769px) {
                    margin-top: 27px;
                }
            }
        }
    }

    &__deal {

        &-content-wrapper {
            background-color: #f1f0ef;
            box-shadow: 0 20px 60px -20px rgba(0, 0, 0, 0.4);
            height: 100%;
            padding: 42px;

            @media (min-width: 1150px) {
                position: absolute;
                    z-index: 10;
                    top: 0;
                    right: 40px;
                width: 427px;
            }
        }

        &-date {
            color: #be1e24;
            font-size: 12px;
            font-weight: 700;
            letter-spacing: 1px;
            text-transform: uppercase;

            @media (min-width: 769px) {
                font-size: 13px;
            }

            &::after {
                background-color: #be1e24;
                content: "";
                display: block;
                height: 3px;
                margin-top: 5px;
                width: 100%;
            }
        }

        &-heading {
            color: #be1e24;
            font-size: 39px;
            font-weight: 700;
            margin-top: 6px;

            @media (min-width: 769px) {
                font-size: 63px;

                :lang(fr) & {
                    font-size: 45px;
                }

            }
        }

        &-product {
            display: flex;
                align-items: center;

            &:hover {
                text-decoration: none;
            }

            &-image-wrapper {
                background-color: white;
                box-shadow: 0 15px 30px -15px rgba(0, 0, 0, 0.4);;
                display: flex;
                    align-items: center;
                    justify-content: center;
                height: 138px;
                flex-shrink: 0;
                padding: 10px;
                width: 138px;

                @media (min-width: 769px) {
                    height: 173px;
                    width: 173px;
                }
            }

            &-image {
                max-width: 100%;
                max-height: 100%;
            }

            &-content-wrapper {
                padding-left: 20px;
            }

        }

        &-brand {
            color: #757575;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 1px;
            text-transform: uppercase;

            @media (min-width: 769px) {
                font-size: 11px;
            }
        }

        &-name {
            color: black;
            font-size: 16px;
            font-weight: 600;
            line-height: 1.3;
            margin-top: 3px;

            @media (min-width: 769px) {
                font-size: 18px;
            }
        }

        &-price-wrapper {
            margin-top: 12px;
        }

        &-price {
            color: black;
            font-size: 16px;
            font-weight: 600;

            @media (min-width: 769px) {
                font-size: 18px;
            }

            &--strikeout {
                display: inline-block;
                position: relative;

                &::before {
                    background-color: #be1e24;
                    content: "";
                    display: block;
                    height: 2px;
                    position: absolute;
                        top: 50%;
                        left: -2px;
                    transform: translateY(-50%);
                    width: calc(100% + 4px);
                }
            }

            &--sale {
                color: #be1e24;


                :lang(fr) & {
                    white-space: nowrap;
                }

            }

        }

        &-limit {

            &-wrapper {
                display: flex;
                margin-top: 30px;
            }

            &-col {
                width: 50%;

                &--right {
                    border-left: 1px solid rgba(0, 0, 0, .2);
                    padding-left: 25px;
                }
            }

            &-label {
                color: #757575;
                font-size: 12px;
                font-weight: 600;
                letter-spacing: 1px;
                text-transform: uppercase;

                :lang(fr) & {
                    line-height: 1.2;
                    margin-bottom: 8px;
                }

            }

            &-time {
                color: black;
                font-size: 21px;
                font-weight: 700;
                text-transform: uppercase;

                :lang(fr) & {
                    line-height: 1;
                }

                @media (min-width: 769px) {
                    font-size: 23px;
                }
            }
        }

        &-button {
            background-color: #be1e24;
            color: white;
            display: flex;
                align-items: center;
                justify-content: center;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 1.5px;
            height: 48px;
            margin-top: 25px;
            text-transform: uppercase;
            width: 100%;

            &:hover {
                color: white;
                text-decoration: none;
            }
        }
    }

    &__signup {
        &.ui-dialog {
            .ui-dialog-titlebar {
                padding-top: 30px;
            }
            .ui-dialog-titlebar-close {
                @include background-svg($svg-close-red)
            }
        }

        @include media-breakpoint-up(md) {
            width: 610px !important; // Important to override importanted style from jqueryui
        }

        &__heading {
            color: $secondary;
            font-size: 33px;
            font-weight: bold;
            line-height: 1.07;
            letter-spacing: normal;
            margin-bottom: 40px;
            position: relative;
            text-align: center;
            text-transform: uppercase;

            &::after {
                content: "";
                background-color: $secondary;
                display: block;
                height: 4px;
                margin-left: -19.5px;
                position: absolute;
                    left: 50%;
                    bottom: -16px;
                width: 39px;
            }

            @include media-breakpoint-up(md) {
                line-height: 1.05;
                text-align: left;

                &::after {
                    margin-left: 0;
                    left: 0;
                }

            }

        }
        &__cta {
            font-size: 15px;
            font-weight: 300;
            line-height: 1.4;
            margin-bottom: 30px;
            text-align: center;

            @include media-breakpoint-up(md) {
                margin-bottom: 25px;
                text-align: left;
            }
        }
        &__agreement-confirmation-row {
            margin-bottom: 8px;
            padding-top: 3px;

            .d-flex {
                display: block !important; // This class is automatically generated by the isinputfield.  In order to match the comps though, we need to enable floating, so overwrite the display
            }
            > .c-form-row__label {
                display: none;
            }
            .c-form-row {
                &__input {
                    float: left;
                }
                &__label {
                    line-height: 1.62;
                }
            }
        }
        &__button {
            @include button-variant($secondary, $secondary, $bright-red, $bright-red);

            font-size: 14px;
            padding-bottom: 13px;
        }
        &__panel-a {

            &__content {
                height: 100%;
                padding-top: 48px;
                padding-right: map-get($grid-gutter-widths, xs);
                padding-bottom: map-get($spacers, 5);
                padding-left: map-get($grid-gutter-widths, xs);

                @include media-breakpoint-up(md) {
                    background-image: var(--c-leaderboard-bgimage);
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    display: block;

                    @media
                        (-webkit-min-device-pixel-ratio: 2),
                        (min-resolution: 192dpi) {
                            background-image: var(--c-leaderboard-bgimage-lg);
                    }

                }

                @include media-breakpoint-up(lg) {
                    padding-top: 45px;
                    padding-left: map-get($grid-gutter-widths, lg);
                }

            }

        }

        &__panel-b {
            &__content {
                padding-top: map-get($spacers, 3);
                padding-right: map-get($grid-gutter-widths, xs);
                padding-bottom: map-get($spacers, 5);
                padding-left: map-get($grid-gutter-widths, xs);

                @include media-breakpoint-up(md) {
                    padding-top: 84px;
                }

                @include media-breakpoint-up(lg) {
                    padding-left: 0;
                    padding-right: map-get($grid-gutter-widths, lg);
                }

            }

        }
    }
}

.#{$svg} {
    height: 30px;
    width: 30px;

    &__success {
        color: $primary;
    }
    &__error {
        color: $secondary;
    }
}
