.pt_wish-list {
    .list-table-header {
        overflow: hidden;
        padding: 2em;
        p {
            color: $gray-500;
            float: right;
            margin: 0;
            overflow: hidden;
            width: 40%;
        }
    }
    .primary-content .list-table-header h2 {
        float: left;
    }

    h1,
    .h1 {
        color: $body-color;
        font-weight: $headings-font-weight;
        letter-spacing: -0.3px;
        line-height:  43px;
    }
    h6,
    .h6 {
        color: $body-color;
        font-size: $font-size-base;
        font-family: $font-family-sans-serif;
        font-weight: $font-weight-normal;
    }
    p {
        font-size: 0.813rem;
        margin: 0.63rem 0;
    }

    .button {
        display: inline-block;
        font-family: "Nunito Sans", sans-serif;
        line-height: 44px;
        height: 46px;
        min-width: 174px;
        padding: 0px 25px;
    }

    input[type=text], input[type="password"] {
        height: 46px;
        max-width: 100%;
        padding: 0px 10px 0px 12px;
        @include media-breakpoint-up(lg) {
            max-width: 427px;
        }
    }
    .table-responsive {
        .item-image {
            min-width: 100px;
        }
    }
}

.list-share {
    @include clearfix();
    border-top: 1px solid darken($gray-100, 10%);
    margin-top: 2rem;
    padding: 2rem 0rem 2.5rem;
    .share-option {
        @include media-breakpoint-up(md) {
            padding: 0px 25px;
        }
        padding: 0px 10px;
    }

    .share-box {
        display: flex;

    }
    .share-options {
        margin-bottom: .5rem;
        font-size: 1.7em;
        margin-left: 1rem;
        position: relative;

        @include media-breakpoint-up(md) {
            margin-left: 2rem;
        }
        .share-title {
            display: inline;
        }
        .share-icon {
            color:#9f9f9f;
            display: inline-block;
            font-size: 0.813rem;
            margin-right: 3px;
            @include media-breakpoint-up(md) {
                margin-right: 5px;
            }
            svg {
                @include media-breakpoint-up(md) {
                    height: 23px;
                    width: 23px;
                }
                height: 23px;
                width: 23px;
            }

            .svg-share {
                height: 25px;
                width: 25px;
            }
        }
        .share-link {
            cursor: pointer;
            font-size: 1.4rem;
            @include media-breakpoint-up(md) {
                font-size: 1.7rem;
            }
        }
    }
    .list-title {
        float: left;
    }

    .share-link-content {
        display: none;
        font-size: 0.813rem;
        padding-top: 1rem;
        &.active {
            display: block;
        }
    }
}

.event-info {
    margin: 1em;
}

.wishlist-search,
.registry-search {
    margin-bottom: 1rem;
    .form-row {
        clear: none;
        margin: 0 0 1.7rem 0;
        float: left;
        width: 32.666666667%;
        &:nth-of-type(3n + 1) {
            margin-left: 0;
        }
    }
    .form-row-button {
        margin-left: 0;
    }
    .c-form-row + .c-form-row {
        margin-top: 0;
    }

}

.no-items-message {
    padding-bottom: 2rem;
    a {
        font-size: 0.813rem;
    }
}
