.pt_gift-registry {
    .create-new-registry {
        margin: 1em 0;
        button {
            float: left;
            margin-right: 2em;
        }
        p {
            font-size: 1.3em;
        }
    }
    .add-gift-cert {
        margin-bottom: 40px;
        overflow: auto;
        padding: 0;
        p {
            color: $body-color;
            line-height: 1.5em;
        }
    }
    .hdr-options {
        width: 9%;
    }

    h1,
    .h1 {
        color: $body-color;
        font-weight: $headings-font-weight;
        letter-spacing: -0.3px;
        line-height:  43px;
    }

    h4, .h4 {
        color: $body-color;
        font-family: $font-family-sans-serif;
        font-size: 1.35rem;
        font-weight: $headings-font-weight;
    }
    h6,
    .h6 {
        color: $body-color;
        font-size: $font-size-base;
        font-family: $font-family-sans-serif;
        font-weight: $font-weight-normal;
    }
    p {
        font-size: 0.813rem;
        margin: 0.63rem 0;
    }

    .button {
        display: inline-block;
        font-family: "Nunito Sans", sans-serif;
        line-height: 44px;
        height: 46px;
        min-width: 174px;
        padding: 0px 25px;
    }

    input[type=text], input[type="password"], .input-select {
        height: 46px;
        max-width: 100%;
        padding: 0px 10px 0px 12px;
        @include media-breakpoint-up(lg) {
            max-width: 427px;
        }
    }
    .content-header {
        margin-bottom: 40px !important;
    }
    .form-inline label {
        padding: 0;
    }
    .dialog-required {
        line-height: 12px;
    }
    .c-form-row__caption {
        font-size: 0.75rem;
        text-align: right;
        width: 427px;
    }
    .select-address {
        label {
            font-size: 0.813rem;
        }
    }

    .addresses-group {
        .c-form-row__caption-group {
            position: relative;
            .form-field-tooltip {
                left: 0;
                position: absolute;
            }
        }
    }
    .primary-content dl {
        dt {
            margin-top: 10px;
        }
        dd {
            font-size: 0.813rem;
            margin-top: 10px;
        }
    }

    .gift-registries {
        th.section-header {
            background-color: $gray-100;
            border-right: 1px solid $body-bg;
            font-size: 0.813rem !important;
            padding: 0.7em 0;
            text-align: center;
            width: 16%;
        }
        td {
            font-size: 0.813rem;
            padding: 0.5rem 0.5rem 0.5rem 1rem;
        }
    }

    .login-search-gift {
        margin: 2.5rem 0;
    }

    .event-info {
        margin: 0;
        padding: 1rem 0;
    }

}

.page-content-tab-navigaton button {
    background-color: $gray-100;
    border: 1px solid $gray-100;
    border-top: 3px solid $gray-100;
    color: $body-color;
    font-size: 0.75rem;
    font-weight: 600;
    height: 50px;
    letter-spacing: 1.5px;
    line-height: 47px;
    margin: 0 0 -1px;
    padding: 0px 30px;
    text-align: center;
    text-transform: uppercase;
    &.selected {
        border-bottom: 1px solid $body-bg;
        border-top: 3px solid #007ac3;
    }
    &:focus {
        outline: none;
    }
}

.page-content-tab-wrapper {

}

.gift-reg-purchases {
    .item-purchased-by {
        dd {
            float: left;
            font-weight: bold;
            margin: 0 1em 0 0;
            position: relative;
            width: auto;
        }
        dt {
            clear: both;
            float: left;
            font-size: 0.813rem;
            margin: 0 1em 0 0;
            position: relative;
            width: 125px;
        }
    }
    .quantity-purchased {
        color: $promo;
    }

    .label {
        display: inline-block;
        width: 60px;
    }

    .product-availability-list {
        margin: 0;
        padding: 0;
        li {
            list-style-type: none;
            padding: 0px;
        }
    }
}

.gift-registry-advanced {
    margin-bottom: 10px;
    &:before {
        display: block;
        clear: both;
        content: "";
    }
    @include clearfix();
    .toggle-content {
        display: none;
    }
    a {
        font-size: 0.813rem;
    }
}

#registry-results {
    font-size: 0.813rem;
    th {
        background-color: #f4f2f1;
        border-right: 1px solid #fff;
        padding: 0.7em 0;
        text-align: center;
        text-transform: uppercase;
        width: 33%;
    }
    td {
        border-bottom: 1px solid #e9ecef;
        padding: 0.5rem 0.5rem 0.5rem 1rem;
    }
    .button-text {
        border: none 0px;
    }
}
