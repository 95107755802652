$ns: "c-colors";

.#{$ns} {
    margin-bottom: map-get($spacers, 4);

    &__label {
        font-size: $font-size-xs;
        font-weight: 500;
        letter-spacing: .5px;
        margin: .5em 0 .33em;
        text-transform: uppercase;
    }

    &__group {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -2.5px;
    }

    &__item {
        @include size(40px);

        cursor: pointer;
        margin: 0 2.5px 3px;
        overflow: hidden;
        position: relative;

        &__input {
            position: absolute;
                left: -9999rem;
        }

        &__label {
            border: 2px solid transparent;
            padding: 3px;

            @at-root :checked + & {
                border-color: $primary;
            }

            &,
            &__inner {
                border-radius: 50%;
                display: block;
                height: 100%;
                width: 100%;
            }

            &__inner {
                background-color: var(--item-color);
                text-indent: -9999em;
            }

        }

    }

}
