.search-result-content {
    padding: 0 10px;

    @include media-breakpoint-up(lg) {
        padding: 0;
    }
}

.product-tile {
    color: $gray-500;
    display: flex;
        flex-direction: column;
        justify-content: space-between;
    height: 100%;
    position: relative;
    text-align: left;

    &-bottom {
        display: flex;
            flex-direction: column;
            justify-content: flex-end;
            flex-grow: 1;
    }

    img {
        width: 100%;
    }

    .product-image {
        height: auto;
        margin-bottom: 7px;
        padding: 20px 10px 0;
        position: relative;

        @include media-breakpoint-up(lg) {
            margin-bottom: 20px;
        }

        .quickview {
            background-color: #{inspect($primary)};
            color: #fff;
            position: absolute;
            text-align: center;
            width: 94%;
            font-size:24px;
            padding-top: 1px;
            margin-top: -15%;
            z-index: 2;
            text-decoration: none;
            i {
                margin-left: 0.5em;
            }
            display:none;

            @include media-breakpoint-down(md) {
                font-size: 16px;
                opacity: 0.8;
                left: 50%;
                bottom: 5%;
                padding: 0.2em;
                transform: translate(-50%, -50%);
            }
        }

        &:hover {
            .quickview {
                display: block;
            }
        }
    }

    &__badges {
        position: absolute;
            left: 0;
            top: 0;
        z-index: 3;
    }

    &__badge {
        border-radius: 10px;
        display: inline-block;
        font-size: 10px;
        font-weight: $font-weight-semibold;
        height: 19px;
        letter-spacing: 1px;
        line-height: 20px;
        padding: 0 12px;
        text-transform: uppercase;
        vertical-align: top;

        &--new {
            color: #fff;
            color: var(--badge-new-color, #fff);
            background-color: $fun-green;
            background-color: var(--badge-new-bg, $fun-green);
        }

        &--sale {
            color: #fff;
            color: var(--badge-sale-color, #fff);
            background-color: $promo;
            background-color: var(--badge-sale-bg, $promo);
        }

        &--clearance,
        &--exclusive,
        &--custom {
            color: $black;
            color: var(--badge-exclusive-color, $black);
            background-color: $dawn;
            background-color: var(--badge-exclusive-bg, $dawn);
        }

        &--custom {
            @include media-breakpoint-down(xs) {
                font-size: 8px;
                padding: 0 8px;
            }
        }
    }

    .thumb-link {
        display: block;
        position: relative;
        width: 100%;

        &.thumb-link-active {
            .thumb-link__img {
                opacity: 0;
                transition: opacity .3s;

                &--back {
                    opacity: 1;
                }

            }
        }

        &__img {
            display: block;
            width: 100%;
            min-height: 0;
            min-width: 0;
            opacity: 1;
            position: relative;
            z-index: 2;

            &--back {
                height: auto;
                left: 0;
                min-height: 100%;
                min-width: 100%;
                opacity: 0;
                position: absolute;
                top: 0;
                z-index: 1;
            }

        }

    }

    .name-link-wrap {
        color: $black;
        margin-bottom: 24px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 6px;
        }

        .product-brand {
            font-size: 10px;
            font-weight: $font-weight-semibold;
            letter-spacing: 0.9px;
            margin-bottom: 4px;
            text-transform: uppercase;

            @include media-breakpoint-up(lg) {
                font-size: 11px;
                letter-spacing: 1px;
                margin-bottom: 3px;
            }
        }

        .product-name {
            font-size: 15px;
            font-weight: $font-weight-light;
            line-height: 1.2;

            @include media-breakpoint-up(lg) {
                font-size: 16px;
                line-height: 1.31;
            }
        }
    }

    .product-pricing {
        font-size: 14px;
        font-weight: $font-weight-normal;
        margin: 0;

        @include media-breakpoint-up(lg) {
            font-size: 16px;
        }

        .product-standard-price {
            color: $flint;
            margin-left: 6px;
            opacity: 0.8;
            text-decoration: line-through;
        }

        .product-sales-price {
            color: $promo;

            &:not(.has-standard-price) {
                color: $black;
            }
        }
    }

    .active-promo-names {
        color: $promo;
        font-size: 13px;
        font-weight: $font-weight-light;
        line-height: normal;

        @include media-breakpoint-up(lg) {
            font-size: 14px;
        }

        p {
            margin-bottom: 0;
        }
    }

	.tooltip {
		position: relative;
		display: inline-block;
		border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
	}

	.tooltip .tooltiptext {
		left: 50%;
		width: 200px;
		bottom: 100%;
		margin-left:-50%;
		color: black;
		text-align: center;
	  	padding: 10px 10px;
	  	border-radius: 10px;
	  	border: 1px solid #DDD;
	  	position: absolute;
	  	z-index: 1000;
	  	background-color: white;
	  	visibility: hidden;
	  	box-shadow: 12px 18px 21px -20px black;
	}

	.tooltip:hover .tooltiptext {
		visibility: visible;
		text-align: left;
	}

    .reviews-compare-wrap {
        align-items: center;
        color: $silver-chalice;
        display: flex;
        justify-content: space-between;
        font-size: 13px;
        font-weight: $font-weight-normal;
        margin: 7px 0 38px 0;

        @include media-breakpoint-up(lg) {
            font-size: 14px;
        }

        .product-compare {
            display: none;
            flex: 0 0 40%;
            line-height: 11px;
            text-align: right;

            @include media-breakpoint-up(lg) {
                display: block;
            }

            label {
                margin-bottom: 0;

                &::after {
                    content: '';
                    background-color: $white;
                    border: 1px solid $gray-300;
                    display: inline-block;
                    height: 16px;
                    margin-left: 5px;
                    vertical-align: bottom;
                    width: 16px;
                }
            }

            input {
                display: none;

                &:checked + label {
                    color: $black;

                    &::after {
                        @include background-svg($svg-checkbox-filled);
                        background-size: contain;
                        border-color: $primary;
                    }
                }
            }
        }

        .product-review {
            flex: 0 0 60%;
            min-height: 18px;

            .rating-title {
                display: inline-block;
                margin-right: 1em;
            }

            .rating {
                display: inline-block;
            }
        }
    }

    .product-swatches {
        display: none;
        flex-grow: 1;
        margin-top: -30px;

        @include media-breakpoint-up(md) {
            display: block;
        }

        ul {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            list-style: none outside none;
            margin: 0;
            margin-left: -4px;
            overflow: hidden;
            padding: 0;

            &.swatch-toggle {
                background: none repeat scroll 0 0 $body-bg;
                display: none;
                position: absolute;
            }
        }

        &:hover ul.swatch-toggle {
            display: block;
        }

        li {
            &.product-swatches-all {
                cursor: pointer;
                font-size: 0.9em;
                margin-left: 3px;

                a {
                    border-color: $green-haze;
                    display: inline-block;
                    height: 26px;
                    width: 26px;
                }

                .product-swatches-all__icon {
                    height: 100%;
                    width: 100%;

                    &:last-child {
                        display: none;
                    }
                }

                &.is-expanded {
                    .product-swatches-all__icon {
                        &:first-child {
                            display: none;
                        }

                        &:last-child {
                            display: block;
                        }
                    }
                }
            }

            img {
                height: 24px;
                width: 24px;
            }
        }

        .swatch {
            height: 28px;
            margin-right: 3px;
            width: 28px;
        }
    }

    .product-promo {
        font-size: 0.8em;
        margin: 0;
        text-transform: uppercase;
    }

    .color-count {
        display: none;
        font-size: 10px;
        margin-top: 10px;
        text-transform: uppercase;
        vertical-align: top;
    }

    .wide-tiles & {
        height: auto !important; // overwrite synced heights set on element
        .product-image {
            float: left;
            padding-right: 2em;
        }
        .name-link-wrap,
        .product-pricing,
        .product-promo,
        .product-swatches,
        .product-review {
            float: left;
            text-align: left;
            width: 65%;
        }
        .product-name {
            font-size: 1.5em;
            height: auto;
            margin-top: 0;
        }
        .product-pricing {
            font-size: 1.3em;
        }
        .product-swatches {
            margin-bottom: 1em;
        }
    }
}

.capture-product-id {
    left: -999em;
    position: absolute;
}

.color-swatch,
.product-swatches-all a {
    border: 2px solid transparent;
    border-radius: 100%;
    display: inline-block;

    &.selected,
    &:hover {
        border-color: $green-haze;
    }

    a,
    img {
        border: 2px solid transparent;
        border-radius: 100%;
        color: $gray-500;
        font-size: 0;
    }
}

.color-swatch a,
.product-swatches-all a img {
    border: 2px solid transparent;
    border-radius: 100%;
    color: $gray-500;
    font-size: 0;
}
