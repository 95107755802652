/* ============================================================================
   Amplience Video Modal
   ============================================================================ */

$ns: 's-video-modal';

.#{$ns} {
    height: auto !important; // Important because built-in jQueryui styles are importanted
    max-height: calc(100vh - 5rem);
    overflow-y: auto;

    .ui-dialog-titlebar {
        padding: 0;

        &::after {
            display: none;
        }
    }
    .ui-dialog-title {
        display: none;
    }
    .ui-dialog-content {
        height: auto !important; // Important because built-in jQueryui styles are importanted
        padding: 0;
    }
}
