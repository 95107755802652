@mixin bb-link($bb-link-border-color: $primary-alt) {
    appearance: none;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid $bb-link-border-color;
    display: inline-block;
    line-height: .9;
    padding: 0;
    text-decoration: none;
    transition: border-color .2s;

    &:hover {
        border-bottom-color: darken($bb-link-border-color, 10%);
        text-decoration: none;
    }

}
