$ns: "c-product-tile-carousel";

.#{$ns} {
    margin: 0 -10px;
    width: calc(100% + 20px);

    @include media-breakpoint-up(lg) {
        margin: 0 -#{map-get($grid-gutter-widths, xs)};
        width: calc(100% + #{2 * map-get($grid-gutter-widths, xs)});
    }

    &__header {
        margin-top: map-get($spacers, 5);
        margin-bottom: map-get($spacers, 5);

        h2, h3, h4 {
            font-family: $headings-font-family;
            font-size: 30px;
            font-weight: 600;
            letter-spacing: -.2px;
            text-align: center;
        }
    }

    .product-tile {
        padding: 0 22px;
        margin-bottom: 4px;

        @include media-breakpoint-up(md) {
            margin-bottom: 20px;
        }
        img {
            height: auto;
        }
    }

    &__rule {
        display: none;
        border-top: 5px solid $border-color;
        margin: 0;
    }

    .s-pdp-recommended-products &,
    .s-complete-the-look & {
        margin: 0 auto map-get($spacers, 3);
        overflow: hidden;
        padding: map-get($spacers, 5) 10px 0 10px;
        width: 100%;

        @include media-breakpoint-up(lg) {
            @include make-container();
            @include make-container-max-widths();
        }

        h2 {
            font-size: 27px;
            letter-spacing: normal;
            margin: 0 auto 30px;
            text-align: center;
        }

        .product-tile {
            padding: 0 20px;

            .name-link-wrap {
                overflow: initial;
            }
        }

        .slick-dotted {
            margin-bottom: 67px;
        }

        .slick-dots {
            li {
                background-color: $alto;

                &.slick-active {
                    background-color: $secondary;
                }
            }
        }

        &__rule {
            display: block;
        }

    }
    &--3up,
    &--4up,
    &--5up {
      width: 100%;
      margin: 0 auto;
      button:focus {
        outline: none !important;
      }
      .slick-arrow {
        width: 52px;
        height: 52px;
        background-image: url("/on/demandware.static/-/Library-Sites-GolfTownSharedLibrary/default/images/blog-icons/gt_recom_carousel_arrow_left.svg");
        background-size: cover;
      }
      @media only screen and (max-width: 575.98px) {
        .slick-list {
          width: 80%;
        }
      }
    }
    &--3up .slick-list {
      width: 85%;
      margin: 0 auto;
    }
    &--4up {
        .slick-list {
            width: 90%;
            margin: 0 auto;
        }
        @media only screen and (max-width: 575.98px) {
            .slick-arrow {
              top: 22% !important;
            }
        }
    }
    &--5up {
        .slick-list {
            width: 95%;
            margin: 0 auto;
        }
        @media only screen and (max-width: 575.98px) {
            .slick-arrow {
              top: 22% !important;
            }
        }
    }
    .slick-prev {
      left: 0 !important;
    }
    .slick-next {
      right: 0 !important;
    }
}
