$ns: 'c-video-banner';

.#{$ns} {
    $font-size: 16px;

    font-family: $font-family-sans-serif;
    margin-bottom: map-get($spacers, 5);
    position: relative;

    @include media-breakpoint-up(lg) {
        height: 500px;
        margin-bottom: 0;
    }
    @include media-breakpoint-up(xxl) {
        height: 750px;
    }

    * {
        font-family: inherit;
    }
    &__background-pic,
    &__background-pic__img {
        display: block;
        height: auto;
        min-height: 100%;
        min-width: 100%;
        width: auto;

        @include media-breakpoint-up(lg) {
            min-height: 500px;
            max-width: none;
        }
        @include media-breakpoint-up(xxl) {
            min-height: 750px;
        }
    }
    &__background-pic {
        & &__img {
            width: auto;
        }
    }
    &__video-wrap {
        display: block;
        position: relative;
        width: 100%;
    }
    &__video {
        vertical-align: top;
        width: 100%;
    }
    &__content {
        max-height: 100%;
        max-width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 15px;
        position: absolute;
            left: 50%;
            top: 50%;
            z-index: 1;
        text-align: center;
        transform: translate(-50%, -50%);
        width: 640px;
    }
    &__eyebrow,
    &__heading {
        text-transform: uppercase;
    }
    &__eyebrow {
        font-size: 13px;
        font-weight: $font-weight-semibold;
        letter-spacing: 1.5px;
        line-height: normal;
        margin-bottom: 9px;

        @include media-breakpoint-up(md) {
            font-size: 15px;
            font-weight: $font-weight-bold;
            line-height: 1.4;
            margin-bottom: 13px;
        }
    }
    &__heading {
        font-size: em(33px, $font-size);
        font-weight: bold;
        line-height: 1;
        margin-bottom: 10px;

        @include media-breakpoint-up(md) {
            font-size: em(68px, $font-size);
            letter-spacing: em(-1px, 60px);
            line-height: 1.05;
            margin-bottom: 16px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 60px;
        }
    }
    &__text {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.31;
        margin-bottom: 28px;

        @include media-breakpoint-up(md) {
            font-size: 18px;
            line-height: 1.5;
            margin-bottom: 25px;
        }
    }
    &__button {
        background-color: $white;
        border: none;
        border-radius: 100%;
        box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.3);
        display: flex;
            align-items: center;
            justify-content: center;
        height: 54px;
        margin-left: auto;
        margin-right: auto;
        width: 54px;

        &::after {
            border-bottom: 9px solid transparent;
            border-left: 15px solid $green-haze;
            border-radius: 2px;
            border-top: 9px solid transparent;
            content: '';
            display: inline-block;
            height: 0;
            margin-left: 5px;
            width: 0;
        }
    }
}
