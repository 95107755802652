$ns: "c-qty";

.#{$ns} {
    align-items: center;
    display: flex;
    margin: 0;

    &__input {
        appearance: none;
        -moz-appearance: textfield;
        border-color: $gray-200;
        font-size: 14px;
        letter-spacing: 0.3px;
        line-height: normal;
        min-width: 2.8em;
        margin: 0;
        max-width: 45px;
        padding: 11px 12px 9px 12px;
        width: auto;
        text-align: center;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            display: none;
        }

    }

    &__btn {
        background-color: $dark-gray;
        border: 1px solid $gray-200;
        color: $body-color;
        height: 45px;
        padding: 0;
        width: 45px;

        &__icon {
            display: block;
            height: 24px;
            margin: auto;
            pointer-events: none;
            width: 24px;
        }

    }

}
