.pt_product-compare {
    .full-width {
        padding: 0 1.25em;
        .back {
            display: inline-block;
        }
    }
    .back--top {
        float: left;
        margin-right: map-get($grid-gutter-widths, xs);
    }
}

.compare-items {
    clear: both;
    overflow: hidden;
    padding: map-get($grid-gutter-widths, xs) 0;
    h2 {
        font-family: inherit;
        font-size: 0.9em;
        margin: 0.5em 0;
        text-transform: uppercase;
    }
    button {
        padding: .5em 1em;
    }
}
.compare-error {
    clear: both;
    margin: map-get($grid-gutter-widths, xs) 0;
    text-align: center;
    width: 100%;
}
#compare-items-button {
    margin-right: 16px;
}

.compare-items-panel {
    @include clearfix();
    float: left;
}

.compare-item {
    background-color: $gray-200;
    float: left;
    height: 55px;
    margin-bottom: 10px;
    margin-right: 1.08em;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: 55px;

    .compare-item-remove {
        @include background-svg($svg-modal-close);

        background-color: #fff;
        background-size: cover;
        cursor: pointer;
        display: none;
        height: 12px;
        padding: 0 .16em;
        position: absolute;
        right: 0;
        top: 0;
        width: 12px;
        z-index: 1;
    }
    &.active {
        .compare-item-remove {
            display: block;
        }
    }
    .compare-item-number {
        color: $gray-400;
        font-size: 3em;
    }
}

.compare-item-image {
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.compare-buttons {
    float: left;
    padding: 0.7em 0 0;
}

.category-switch {
    align-items: center;
    display: flex;
    margin-bottom: 2em;

    label {
        float: left;
        margin: 0;
        width: 45%;
        @media (min-width: 410px) {
            width: 11em;
        }
    }
    select {
        float: left;
        margin: 0;
        width: 55%;
        @media (min-width: 410px) {
            margin-left: 1em;
            width: 15em;
        }
    }
}

#compare-table {
    border: 1px solid $gray-300;
    margin-bottom: map-get($grid-gutter-widths, xs);
    width: auto;

    tr {
        vertical-align: top;
    }
    th,
    td {
        padding: 12px;
    }
    th {
        overflow: hidden;
        &:first-of-type {
            min-width: 112px;
        }
    }
    td {
        overflow: hidden;
    }
    a {
        &:hover {
            text-decoration: underline;
        }
    }
    .attribute-row {
        &:first-of-type {
            border-top: 1px solid $gray-400;
        }
        &:last-of-type {
            border-bottom: none;
        }
        &:nth-child(2n) {
            background-color: $gray-100;
        }
    }
    .product-attribute {
        word-break: break-word;
    }
    .action {
        display: block;
        padding: 5px 0;
    }
    .remove-link {
        @include background-svg($svg-modal-close);

        background-color: #fff;
        background-size: cover;
        color: $gray-500;
        height: 24px;
        padding: .3rem;
        position: absolute;
        right: 0;
        top: 0;
        transition: color .5s ease;
        width: 24px;
        z-index: 4;

        &:hover {
            color: $body-color;
            text-decoration: none;
        }
    }
    .value,
    .product {
        border-left: 1px solid $gray-300;
    }
    .product {
        font-weight: normal;
        position: relative;
        text-align: center;
    }
    .value {
        vertical-align: middle;

        .value-item {
            &::after {
                content: ', ';
            }
            &:last-child {
                &::after {
                    content: '';
                }
            }
        }
    }
    .product-tile {
        .product-name {
            height: auto;
            margin: .2em 0 1em 0;
        }
        .swatch-list {
            display: flex;
        }
    }
    .product-image {
        height: auto;
        img {
            height: auto;
            width: 100%;
        }
    }

    .section-header {
        font-size: 0.9em;
        padding: 1em;
        text-transform: none;
    }
    .selectcategory {
        label {
            display: block;
            font-size: 0.9em;
            margin: 1em 0;
            text-transform: none;
        }
        select {
            margin: 0;
            width: 100px;
        }
    }
    .comparelists {
        padding: 0;
    }
    .compare-lists {
        label,
        select {
            float: none;
            text-align: left;
            width: 100%;
        }
    }
    .reviews-compare-wrap {
        flex-wrap: wrap;
    }
    .product-swatches {
        flex: 0 0 100%;
    }
    .swatch-wrap {
        flex: 0 0 auto;
    }
    .product-actions {
        padding-top: 12px;
    }
}
