/* ============================================================================
Bonus Products
============================================================================ */

$ns: c-bonus-product;

.#{$ns} {

    &-item {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-width: 100%;

        align-self: stretch;

        &__container {
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            flex-wrap: wrap;
            @include media-breakpoint-up(md) {
                flex-direction: row;
            }
        }

        &__content {
            border: 1px solid rgba(0, 0, 0, 0.1);
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            // overflow-y: scroll;
            // overflow-x: hidden;
            // max-height: 360px;
            // @include media-breakpoint-up(md) {
            //     max-height: 400px;
            // }

            .spacer {
                margin-top: auto;
                height: 1px;
            }
        }

        &__image {
            text-align: center;
            img {
                max-width: 100%;
            }
        }

        &__detail {
            margin: 15px;
        }

        .product-name {
            font-size: 15px !important;
        }

        .price-standard,
        .price-sales {
            font-size: 15px !important;
        }

        .price-standard {
            text-decoration: line-through;
        }

        .product-variations.locked {
            pointer-events: none;
            opacity: 0.5;
        }

        .select-bonus-item.button-fancy-large,
        .remove-bonus-item.button-fancy-large {
            width: 100%;
            border-radius: 10px;
        }
        .remove-bonus-item.button-fancy-large {
            border-color: #be1e24;
            background-color: #fff;
            color: #be1e24;
        }
    }

    .product-add-to-cart {
        padding-bottom: 0;
        .inventory {
            width: auto;
        }
    }
    .product-name {
        float: none;
        margin: 1em 0;
    }
    .quantity-error {
        color: red;
        text-transform: inherit;
    }
    .bonus-product-list-footer {
        @include clearfix();
        padding-top: 1em;
        padding-bottom: 1em;
        .add-to-cart-bonus {
            float: right;
        }
    }
    .selected-bonus-item {
        margin: 1em 0 1em 1em;
        position: relative;
        .item-name {
            font-weight: bold;
        }
    }
}

.ui-dialog {
    .loader {
        background-attachment: inherit;
    }
    #bonus-product-list {
        padding-top: 0;
    }
    .bonus-product-list-footer {
        display: none;
    }
}


.bonus-discount-container {
    .promo-details {
        display: none;
        &.visible {
            display: block;
        }
    }
}

.cart-row.bonusItem {
    border-bottom: none;
    background-color: #EAF8ED;
    padding-left: 25px;
    padding-right: 10px;
    padding-bottom: 0px;
    .item-details {
        flex-basis: 30%;
    }
    @include media-breakpoint-down(sm) {
        .item-image {
            flex-basis: 40%;
            max-width: unset;
        }
        .item-details {
            flex-basis: 58%;
        }
        .item-quantity {
            margin-bottom: 0;
            padding-bottom: 0;
            flex-basis: 40%;
            margin-right: 58%;
        }
        .item-delivery-options {
            min-width: unset;
            flex-basis: 44%;
            text-align: left;
            order: 5;
        }
        .item-quantity-details {
            flex-basis: 54%;
            order: 6;
            margin-top: -1.8em;
        }
        .item-price {
            flex-basis: 40%;
            text-align: right;
        }
        .item-total {
            flex-basis: 58%;
            text-align: right;
        }
    }
}
.item-list {
    .bonusItemHeader {
        background-color: #EAF8ED;
        color: #34871b;
        border-bottom: none;
        td {
            margin-bottom: -23px;
            margin-left: 15px;
            margin-top: 10px;
            font-weight: 700;
            font-size: 18px;
        }
    }
}
.bonus-button-update {
    background-color: #EAF8ED;
    padding-right: 25px;
    .item-total {
        padding-right: 25px;
        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }
}

.select-bonus {
    border-width: 1px;
    display: inline-block;
    @include media-breakpoint-down(md) {
        width: 65%;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}
#more-bonus-products {
    display: block;
    margin: map-get($spacers, 4) auto;
    min-width: 125px;
}

