.pt_categorylanding {
    #main {
        .main-row {
            @include media-breakpoint-down(md) {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
    .category-refinement {
        &.c-accordion__item {
            border: none;
        }
        .c-accordion__header {
            background: transparent !important;
            cursor: auto;
            padding-bottom: 0;
        }
        .c-accordion__body {
            padding-bottom: 26px !important;
            padding-top: 10px !important;
        }
    }
}
.category-tile {
    h4 {
        @include media-breakpoint-down(md) {
            font-size: 18px;
            margin-bottom: 0;
        }
    }
}
.category-tile__shop-now {
    color: $body-color;
    font-size: 12px;
    text-decoration: underline;
}
.bottom-banner-container {
    overflow: hidden;
    .bottom-banner-cell {
        float: left;
        overflow: hidden;
        text-align: center;
    }
    .banner-cell-1 {
        width: 33%;
    }
    .banner-cell-2 {
        width: 34%;
    }
    .banner-cell-3 {
        width: 33%;
    }
}

.cat-featured-bar {
    margin-top: 4px;
}
